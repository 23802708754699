import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Admin() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    // Check if the entered username and password are correct
    if (
      username === `${process.env.REACT_APP_ADMIN_USERNAME}` &&
      password === `${process.env.REACT_APP_ADMIN_PASSWORD}`
    ) {
      // Redirect to /admin-tick page
      localStorage.setItem("admin__tijarah", "true");
      navigate("/admin-64d07beb1731b48d296a7fd0");
      window.location.reload();
    } else {
      // Handle incorrect credentials (you can display an error message, etc.)
      alert("Incorrect username or password");
    }
  };

  return (
    <div className="bg_grays h-100vh">
      <div className="d-f a-i-c j-c-c h-90vh">
        <div className="">
          <div className="b-1  pa-2 b-r-5 b-c-w w-27">
            <div className="f-s-1_5 f-w-600 t-a-c m-b-2">Admin</div>
            <div className="f-w-600 m-b-0_5">Username :</div>
            <div className="m-b-1">
              <input
                type="text"
                className="w-100 b-r b-r-5 b-1 pa-0_5 b-c-t"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="f-w-600 m-b-0_5">Password :</div>
            <div className="m-b-2">
              <input
                type="password"
                className="w-100 b-r b-r-5 b-1 pa-0_5 b-c-t"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="t-a-c">
              <button
                className="bg_yellow_clr f-w-600 b-n b-r-10 p-x-2 p-y-0_5"
                onClick={handleLogin}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
