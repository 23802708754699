import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import propertiesimage from "../../Images/properties-icon.png";
import levelimage from "../../Images/level-icon.png";
import statsimage from "../../Images/stats-icon.png";
import Navbarnft from "../Navbars/Navbarnft_global";
import { isURL } from "validator";
import toast, { Toaster } from "react-hot-toast";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { IoIosSwitch } from "react-icons/io";
import { AiFillStar, AiOutlineUnorderedList } from "react-icons/ai";
import { pink } from "@mui/material/colors";

import Switch from "@mui/material/Switch";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BiStats } from "react-icons/bi";

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          backgroundColor: "yellow",
        },
        track: {
          backgroundColor: "rgba(255, 255, 0, 0.5)",
        },
      },
    },
  },
});

const notify = (msg) => toast.success(msg);
const warning = (msg) => toast.error(msg);

const JWT = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJkOGVlZmJjNC05Y2Q4LTQ1ZGMtOTZhNC00NDhhZjE5ZWY5MzciLCJlbWFpbCI6InJzeWFkYXYuYWJzczFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImMwMTg3ZDRkNmVhN2Y0NmU0NWEwIiwic2NvcGVkS2V5U2VjcmV0IjoiNDYzNmUyZWM4OThkZDg3Y2YwMjJkODNhNjMzM2FkZjAwOGY2NTVlMWYyNWY0YjNlZDA4NTY4YmViOGM5NGY2MCIsImlhdCI6MTY3NDcxNjcyMH0.jpZ0kof2NdWhg_JQnfaSuUTM5M_LJonbTXviWKWeAQk`;

function CreateNft({ URL, UserWallet, IPFS_image_baseURL }) {
  const [open50, setOpen50] = React.useState(false);
  const handleOpen50 = () => setOpen50(true);
  const handleClose50 = () => setOpen50(false);

  const [selectedFile, setSelectedFile] = useState();

  const [description, setDescription] = useState();

  const [show1, setShow1] = useState(false);
  const [nft, setNft] = useState([]);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showproperties, setShowproperties] = useState(false);

  const handleCloseproperties = () => setShowproperties(false);
  const handleShowproperties = () => setShowproperties(true);
  const [showlevel, setShowlevel] = useState(false);

  const handleCloselevel = () => setShowlevel(false);
  const handleShowlevel = () => setShowlevel(true);

  const [showstats, setShowstats] = useState(false);

  const handleClosestats = () => setShowstats(false);
  const handleShowstats = () => setShowstats(true);

  const [imagehash, setImageHash] = useState();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleSubmission = async () => {
    console.log(selectedFile);
    const formData = new FormData();
    formData.append("file", selectedFile);
    const metadata = JSON.stringify({
      name: "File name",
    });
    formData.append("pinataMetadata", metadata);
    const options = JSON.stringify({
      cidVersion: 0,
    });
    formData.append("pinataOptions", options);

    try {
      const res = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          maxBodyLength: "Infinity",
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            Authorization: JWT,
          },
        }
      );
      console.log(res.data.IpfsHash);
      setImageHash(res.data.IpfsHash);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, []);

  const [imagelink, setImageLink] = useState();
  const [externalLink, setExternalLink] = useState("www.google.com");
  const [discreption, setDiscription] = useState();
  const [collection, setCollection] = useState();
  const [attributes, setAttributes] = useState([]);
  const [supply, setSupply] = useState(1);
  const [chain, setChain] = useState(5);
  const [nftType, setNetType] = useState(1155);

  const [properties, setProperties] = useState([0]);
  const [propertieObject, setPropertieObject] = useState([{}]);

  const [level, setLevel] = useState([0]);
  const [levelObject, setLevelObject] = useState([{}]);

  const [state, setState] = useState([0]);
  const [stateObject, setStateObject] = useState([{}]);
  const [ownedNFTS, setOwndNFTs] = useState([]);

  const [name, setName] = useState();
  const [feturnArtistName, setFeturArtistName] = useState();
  const [producerInfo, setProducerInfo] = useState();
  const [ReleaseTital, setReleaseTital] = useState();
  const [titalOfAlbum, setTitalOfAlbum] = useState();
  const [CopyRightHolder, setCopyRightHolder] = useState();
  const [copyRightYear, setCopyRightYear] = useState();
  const [ProductionHolder, setProductionholder] = useState();
  const [Recordlable, setRecordlable] = useState();
  const [MusicalData, setMucialData] = useState([]);

  useEffect(() => {
    const init = async () => {
      // console.log(UserWallet)
      axios
        .post(`${URL}/collectionbycreateor`, {
          wallet: UserWallet,
        })
        .then((res) => {
          setOwndNFTs(res.data);
          console.log(res.data);
        });
    };
    init();
  }, [UserWallet]);

  const Created_nfts = async () => {
    if (!imagehash) {
      warning("Image is not uploaded, please upload the image");
      return;
    }
    if (!isURL(externalLink)) {
      warning("Invalid URL");
      return;
    }
    if (!ownedNFTS && ownedNFTS.length > 0) {
      warning("Please create Collection first");
      return;
    }
    if (!collection || collection === "null") {
      warning("invalid collection");
      return;
    }

    const arrtribute = [];
    console.log(propertieObject);
    for (let i = 0; i < propertieObject.length; i++) {
      if (propertieObject[i].type && propertieObject[i].value) {
        setAttributes((attributes) => [...attributes, propertieObject[i]]);
        arrtribute.push(propertieObject[i]);
      }
    }

    for (let i = 0; i < levelObject.length; i++) {
      if (levelObject[i].type && levelObject[i].value) {
        setAttributes((attributes) => [...attributes, levelObject[i]]);
        arrtribute.push(levelObject[i]);
      }
    }

    for (let i = 0; i < stateObject.length; i++) {
      if (stateObject[i].type && stateObject[i].value) {
        setAttributes((attributes) => [...attributes, stateObject[i]]);
        arrtribute.push(stateObject[i]);
      }
    }

    await axios
      .post(`${URL}/makenfts`, {
        Creator: UserWallet.toLowerCase(),
        Owner: UserWallet.toLowerCase(),
        name: name,
        isMusicalNFT: true,
        MusicalNFT_metadata: [MusicalData],
        imageURL: `${IPFS_image_baseURL}/${imagehash}`,
        collectionID: collection,
        attributes: arrtribute,
        supply: supply,
        chain: chain,
        description: discreption,
        externalLink: externalLink,
        nftType: nftType,
      })
      .then((res) => {
        console.log(res.data);
        notify("NFT created successfully");
        setInterval(() => {}, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSupply = (tab) => {
    if (Number(tab) === 721) {
      setNetType(Number(tab));
    } else if (Number(tab) === 1155) {
      setNetType(Number(tab));
    }
  };

  const updateFieldChangedType = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...propertieObject];
    newArr[index].type = e.target.value;
    setPropertieObject(newArr);
  };

  const updateFieldChangedValue = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...propertieObject];
    newArr[index].value = e.target.value;
    setPropertieObject(newArr);
  };

  const updateFieldChangedTypeforLevel = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...levelObject];
    newArr[index].type = e.target.value;
    setLevelObject(newArr);
  };

  const updateFieldChangedValueforLevel = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...levelObject];
    newArr[index].value = e.target.value;
    setLevelObject(newArr);
  };

  const updateFieldChangedTypeforState = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...stateObject];
    newArr[index].type = e.target.value;
    setStateObject(newArr);
  };

  const updateFieldChangedValueforState = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...stateObject];
    newArr[index].value = e.target.value;
    setStateObject(newArr);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMucialData((prevData) => ({ ...prevData, [name]: value }));
  };

  console.log(MusicalData);
  return (
    <>
      <Navbarnft />
      <div className=" m-t-5">
        <div>
          <div className="row ">
            <div className="col-lg-6 col-md-8 col-sm-11 col-11 m-a">
              <div className="nft-switch d-f j-c-s-b a-i-c">
                <Typography
                  id="modal-modal-title"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="f-s-2 f-w-900"
                >
                  Create an Musical NFT
                </Typography>
                <div className=" m-q-b-m-y-2">
                  <span className="g-c">NFTs</span> &nbsp;
                  <Link to="/createnft">
                    {" "}
                    <Switch
                      defaultChecked
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#FEC200",
                          color: "#FEC200",
                        },
                        "& .MuiSwitch": {
                          bgcolor: "#FEC200",
                          color: "#FEC200",
                        },
                      }}
                    />{" "}
                  </Link>{" "}
                  Musical NFTs
                </div>
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong style={{ color: "red" }}>*</strong>Required Fields
              </Typography>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Audio or Video
                  </Typography>

                  <Typography className="content-form f-s-0_8">
                    File types supported: MP4, MP3, OGG, WAV, GLB, GLTF, GIF,
                    WEBM Max size: 100 MB
                  </Typography>
                </div>
                <div>
                  <div className="featured-img-box2 my-3">
                    <div className="fe-bo">
                      {/*       <img src={Dragimg} alt="" /> */}
                      <Typography className="tag-image-name-drop">
                        <input type="file" onChange={changeHandler} />
                      </Typography>
                    </div>
                  </div>
                  {imagehash ? <p>Image hash: {imagehash}</p> : ""}
                  <button
                    className="save-bu22 t-a-r m-l-0"
                    onClick={handleSubmission}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">Name</Typography>
                  <input
                    type="text"
                    placeholder="Artist Name "
                    className="recordion-in"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Artist Name
                  </Typography>
                  <input
                    type="text"
                    placeholder="Artist Name "
                    className="recordion-in"
                    name="Artist Name"
                    // value={MusicalData.ArtistName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Any featured artist name
                  </Typography>
                  <input
                    type="text"
                    placeholder="Any featured artist name"
                    className="recordion-in"
                    name="Any featured artist name"
                    // value={MusicalData.featurnArtistName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Producer info
                  </Typography>
                  <input
                    type="text"
                    placeholder="producer info"
                    className="recordion-in"
                    name="Producer info"
                    // value={MusicalData.producerInfo}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Release Title <strong style={{ color: "red" }}>*</strong>
                  </Typography>
                  <input
                    type="text"
                    placeholder="This can be your album or single title"
                    className="recordion-in"
                    name="Release Title"
                    // value={MusicalData.releaseTitle}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Description
                  </Typography>

                  <textarea
                    className="text-area-mycollections "
                    placeholder="Maximum of 150 characters"
                    value={discreption}
                    onChange={(e) => setDiscription(e.target.value)}
                    maxLength={150}
                  ></textarea>
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Title of Album, EP or single
                  </Typography>
                  <input
                    type="text"
                    placeholder="e.g. The Recordian Music"
                    className="recordion-in"
                    name="Title of Album, EP or single"
                    // value={MusicalData.titleOfAlbum}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Copyright Holder
                  </Typography>
                  <input
                    type="text"
                    placeholder="e.g. The Recordian Music"
                    className="recordion-in"
                    name="Copyright Holder"
                    // value={MusicalData.copyRightHolder}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Copyright Year
                  </Typography>
                  <input
                    type="text"
                    placeholder="e.g.  2023"
                    className="recordion-in"
                    name="Copyright Year"
                    // value={MusicalData.copyRightYear}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Production Holder
                  </Typography>
                  <input
                    type="text"
                    placeholder="e.g. The Recordian Music"
                    className="recordion-in"
                    name="Production Holder"
                    // value={MusicalData.productionHolder}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Record label
                  </Typography>
                  <input
                    type="text"
                    placeholder="e.g.  Independent"
                    className="recordion-in"
                    name="Record label"
                    // value={MusicalData.recordLable}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Release Genres
                  </Typography>

                  <input
                    type="text"
                    placeholder="e.g. RnB"
                    className="recordion-in"
                    name="Release Genres"
                    // value={MusicalData.release}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="section-collection">
                <Typography className="highligh-section">Supply</Typography>
                <Typography className="content-form f-s-0_8">
                  The number of items that can be minted. No gas cost to you!
                </Typography>
                {/* <Form.Select
                      aria-label="Default select example"
                      className="mt-3"
                      onChange={(e)=>handleSupply(e.target.value)}
                
                    >
                      <option value="721">Single</option>
                      <option value="1155">Multiple</option>
                    </Form.Select> */}
                <div>
                  {true ? (
                    <input
                      placeholder="0"
                      className="recordion-in mt-3"
                      value={supply}
                      onChange={(e) => setSupply(e.target.value)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="section-collection">
                <Typography className="highligh-section">Collection</Typography>

                <div>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setCollection(e.target.value)}
                  >
                    <option value="null">SELECT</option>
                    {ownedNFTS &&
                      ownedNFTS.map((res) => {
                        return (
                          <option value={`${res.name}`}>{res.name}</option>
                        );
                      })}
                  </Form.Select>
                </div>
              </div>
              <div className="section-collection">
                <div className="d-f j-c-s-b">
                  <div className="d-f ">
                    <div className="">
                      <AiOutlineUnorderedList className="m-t-1 m-r-1 f-s-1_5" />
                    </div>
                    <div className="">
                      <Typography className="highligh-section">
                        Properties
                      </Typography>

                      <Typography className="content-form f-s-0_8">
                        Textual traits that show up as rectangles
                      </Typography>
                    </div>
                  </div>
                  <div className="">
                    <Button
                      variant="primary"
                      onClick={handleShowproperties}
                      className="cate-bu my-3 p-x-1 p-y-0_5 b-1"
                    >
                      +
                    </Button>

                    <Modal
                      show={showproperties}
                      onHide={handleCloseproperties}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header>
                        <Modal.Title>Add Properties</Modal.Title>
                        <RxCross1
                          className="c-p"
                          onClick={handleCloseproperties}
                        />
                      </Modal.Header>
                      <Modal.Body>
                        <div className="m-y-1">
                          Properties show up underneath your item, are
                          clickable, and can be filtered in your collection's
                          sidebar.
                        </div>
                        <div className="row m-y-1">
                          <div className="col">
                            <div className="f-w-600">Type</div>
                          </div>
                          <div className="col">
                            {}
                            <div className="f-w-600">Value</div>
                          </div>
                        </div>

                        {properties.map((res) => {
                          return (
                            <div className="row m-y-1">
                              <div className="col">
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Character"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={
                                      propertieObject[properties.indexOf(res)]
                                        .type
                                    }
                                    onChange={(e) => {
                                      updateFieldChangedType(
                                        properties.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                {" "}
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Male"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={
                                      propertieObject[properties.indexOf(res)]
                                        .value
                                    }
                                    onChange={(e) => {
                                      updateFieldChangedValue(
                                        properties.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="m-y-1">
                          <button
                            className="save-bu22 m-l-0 pa-0_5 p-x-2"
                            onClick={() => {
                              setPropertieObject((propertieObject) => [
                                ...propertieObject,
                                {},
                              ]);
                              setProperties((properties) => [
                                ...properties,
                                properties.length,
                              ]);
                            }}
                          >
                            Add More
                          </button>
                        </div>
                        <div className="m-y-1">
                          <span>
                            <button
                              className="save-bu22 w-100 m-l-0"
                              onClick={handleCloseproperties}
                            >
                              Save
                            </button>
                          </span>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="section-collection">
                <div className="d-f j-c-s-b">
                  <div className="d-f ">
                    <div className="">
                      <AiFillStar className="m-t-1 m-r-1 f-w-1_5" />
                    </div>
                    <div className="">
                      <Typography className="highligh-section">
                        Levels
                      </Typography>

                      <Typography className="content-form f-s-0_8">
                        Numerical traits that shows up as a progress bar
                      </Typography>
                    </div>
                  </div>
                  <div className="">
                    <Button
                      variant="primary"
                      onClick={handleShowlevel}
                      className="cate-bu my-3 p-x-1 p-y-0_5 b-1"
                    >
                      +
                    </Button>

                    <Modal
                      show={showlevel}
                      onHide={handleCloselevel}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header>
                        <Modal.Title>Add Level</Modal.Title>
                        <RxCross1 className="c-p" onClick={handleCloselevel} />
                      </Modal.Header>
                      <Modal.Body>
                        <div className="m-y-1">
                          level show up underneath your item, are clickable, and
                          can be filtered in your collection's sidebar.
                        </div>
                        <div className="row m-y-1">
                          <div className="col">
                            <div className="f-w-600">Type</div>
                          </div>
                          <div className="col">
                            {" "}
                            <div className="f-w-600">Value</div>
                          </div>
                        </div>

                        {level.map((res) => {
                          return (
                            <div className="row m-y-1">
                              <div className="col">
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Character"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={levelObject[level.indexOf(res)].type}
                                    onChange={(e) => {
                                      updateFieldChangedTypeforLevel(
                                        level.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                {" "}
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Male"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={
                                      levelObject[level.indexOf(res)].value
                                    }
                                    onChange={(e) => {
                                      updateFieldChangedValueforLevel(
                                        level.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <div className="m-y-1">
                          <button
                            className="save-bu22 m-l-0 pa-0_5 p-x-2"
                            onClick={() => {
                              setLevelObject((levelObject) => [
                                ...levelObject,
                                {},
                              ]);
                              setLevel((level) => [...level, level.length]);
                            }}
                          >
                            Add More
                          </button>
                        </div>
                        <div className="m-y-1">
                          <button
                            className="save-bu22 w-100 m-l-0"
                            onClick={handleCloselevel}
                          >
                            Save
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>

              <div className="section-collection">
                <div className="d-f j-c-s-b">
                  <div className="d-f ">
                    <div className="">
                      <BiStats className="m-t-1 m-r-1 f-s-1_5" />
                    </div>
                    <div className="">
                      <Typography className="highligh-section">
                        Stats
                      </Typography>

                      <Typography className="content-form f-s-0_8">
                        Numerical traits that shows up as a progress bar
                      </Typography>
                    </div>
                  </div>
                  <div className="">
                    <Button
                      variant="primary"
                      onClick={handleShowstats}
                      className="cate-bu my-3 p-x-1 p-y-0_5 b-1"
                    >
                      +
                    </Button>

                    <Modal
                      show={showstats}
                      onHide={handleClosestats}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header>
                        <Modal.Title>Add Stats</Modal.Title>
                        <RxCross1 className="c-p" onClick={handleClosestats} />
                      </Modal.Header>

                      <Modal.Body>
                        <div className="m-y-1">
                          Properties show up underneath your item, are
                          clickable, and can be filtered in your collection's
                          sidebar.
                        </div>
                        <div className="row m-y-1">
                          <div className="col">
                            <div className="f-w-600">Type</div>
                          </div>
                          <div className="col">
                            {" "}
                            <div className="f-w-600">Value</div>
                          </div>
                        </div>

                        {state.map((res) => {
                          return (
                            <div className="row m-y-1">
                              <div className="col">
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Character"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={stateObject[state.indexOf(res)].type}
                                    onChange={(e) => {
                                      updateFieldChangedTypeforState(
                                        state.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                {" "}
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Male"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={
                                      stateObject[state.indexOf(res)].value
                                    }
                                    onChange={(e) => {
                                      updateFieldChangedValueforState(
                                        state.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <div className="m-y-1">
                          <button
                            className="save-bu22 m-l-0 pa-0_5 p-x-2"
                            onClick={() => {
                              setStateObject((stateObject) => [
                                ...stateObject,
                                {},
                              ]);
                              setState((state) => [...state, state.length]);
                            }}
                          >
                            Add More
                          </button>
                        </div>
                        <div className="m-y-1">
                          <button
                            className="save-bu22 w-100 m-l-0"
                            onClick={handleClosestats}
                          >
                            Save
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>

              {/*  <div className="section-collection">
                <div className="row">
                  <div className="col-8">
                    <Typography className="highligh-section">
                      Explicit & sensitive content
                    </Typography>
                    <Typography className="content-form f-s-0_8">
                      Set this collections as explicit & sensitive content
                    </Typography>
                  </div>
                  <div className="col-4">
                    <span className="ios-check-button">
                      <FormControlLabel
                        control={<IoIosSwitch sx={{ m: 1 }} defaultChecked />}
                      />
                    </span>
                  </div>
                </div>
              </div> */}

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Track isrc
                  </Typography>
                  <input
                    type="text"
                    placeholder="e.g. USS1Z1001234"
                    className="recordion-in"
                    name="Track isrc"
                    // value={MusicalData.isrc}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Track iswc
                  </Typography>
                  <input
                    type="text"
                    placeholder="e.g. T 34546781 1"
                    className="recordion-in"
                    name="Track iswc"
                    // value={MusicalData.iswc}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Sale Royalties
                  </Typography>
                  <input
                    type="text"
                    placeholder="$"
                    className="recordion-in"
                    name="Sale Royalties"
                    // value={MusicalData.royalties}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section g-c">
                    Streaming royalties: (coming soon)
                  </Typography>
                  <input
                    type="text"
                    placeholder="$"
                    className="recordion-in"
                    name="Streaming royalties"
                    // value={MusicalData.streaming}
                    onChange={handleInputChange}
                    disabled
                  />
                </div>
              </div>

              <div className="section-collection ">
                <div className="d-f pa-0_5 b-g-c b-r-10 p-l-0">
                  <div className="pa-0">
                    {" "}
                    <Checkbox
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                      }}
                      className="pa-0"
                    />
                  </div>{" "}
                  &nbsp; &nbsp;
                  <div className="p-l-1 d-i-b">
                    I understand my release may be rejected from stores if I
                    don't correctly label tracks as explicit that contain swear
                    words or obscenities.
                  </div>
                </div>

                <div className="d-f pa-0_5 b-g-c b-r-10 p-l-0">
                  <div className="pa-0">
                    {" "}
                    <Checkbox
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                      }}
                      className="pa-0"
                    />
                  </div>{" "}
                  &nbsp; &nbsp;
                  <div className="p-l-1 d-i-b">
                    I am authorised to distribute this music.
                  </div>
                </div>

                <div className="d-f pa-0_5 b-g-c b-r-10 p-l-0">
                  <div className="pa-0">
                    {" "}
                    <Checkbox
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                      }}
                      className="pa-0"
                    />
                  </div>{" "}
                  &nbsp; &nbsp;
                  <div className="p-l-1 d-i-b">
                    I'm not using any other artist's name in my name, song
                    titles, or album title, without their approval.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12">
              <div className="button-section-nft">
                <button className="save-bu">Save as draft</button>
                <button className="save-bu22" onClick={() => Created_nfts()}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
}

export default CreateNft;
