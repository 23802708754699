import React from "react";
import "./Home.css";

import Navbarnft from "../Components/Navbars/Navbarnft";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import Hometabs from "./Hometabs";
import HeaderSlider from "./HeaderSlider";
import Tab from "./Tab";
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";

export default function Home({ UserWallet, signMessage, logout, URL }) {
  return (
    <>
      <div className="bg_slider">
        <Navbarnft
          UserWallet={UserWallet}
          signMessage={signMessage}
          logout={logout}
          URL={URL}
        />
      </div>
      <div className="">
        <div className="">
          <div className="">
            <div className="bg-clr">
              <div className="p-x-2">
                <div className="p-t-9">
                  <HeaderSlider UserWallet={UserWallet} URL={URL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Tab UserWallet={UserWallet} URL={URL} />
      </div>
    </>
  );
}
