import React from "react";
import { BsDiscord } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import logo from "../Images/pansea_white.png";
import { BsYoutube } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import "./Footer.css";
import { Link } from "react-router-dom";
import { logo_light } from "../Web3/web3_api";
import { AiFillInstagram } from "react-icons/ai";

function Footer(props) {
  return (
    <>
      <div className="Footer-left-spacing m-t-5">
        <div className="p-x-2 p-y-5">
          <div className="row m-b-5">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 footer-pansea footer-section">
              <img src={logo_light()} className="w-10 m-b-2" />
              <p className="origins-para max-w-30">
                The world’s first and largest digital marketplace for crypto
                collectibles and non-fungible tokens (NFTs). Buy, sell, and
                discover exclusive digital items.
              </p>
            </div>
            <div className="col-lg col-md col-sm-6 col-6 footer-section">
              {" "}
              <h5 className="list-heading">Marketplace</h5>
              <ul className="footer2-list">
                <a href="/categories/Art">
                  {" "}
                  <li>Art</li>
                </a>
                <a href="/categories/Gaming">
                  {" "}
                  <li>Gaming</li>{" "}
                </a>
                <a href="/categories/Membership">
                  {" "}
                  <li>Membership</li>{" "}
                </a>
                <a href="/categories/Metaverse">
                  {" "}
                  <li>Metaverse</li>{" "}
                </a>
                <a href="/categories/Utility">
                  {" "}
                  <li>Utility</li>{" "}
                </a>
              </ul>
            </div>
            <div className="col-lg col-md col-sm-6 col-5 profile-fo footer-section">
              {" "}
              <h5 className="list-heading">My Profile</h5>
              <ul className="footer2-list">
                <a href="/createnft">
                  {" "}
                  <li>Create</li>
                </a>

                <a href="/mycollections">
                  {" "}
                  <li>My Collection</li>
                </a>
                <a href="/profilesetting">
                  {" "}
                  <li>Settings</li>
                </a>
              </ul>
            </div>
            <div className="col-lg col-md col-sm-12 col-12 footer-section">
              {" "}
              <h5 className="list-heading">Resources</h5>
              <ul className="footer2-list">
                <a href="/help">
                  {" "}
                  <li>Help</li>
                </a>
              </ul>
            </div>
          </div>

          <h5 className="f-s-1_5">Join the community</h5>
          <div>
            <ul className="icon">
              <a href="https://www.facebook.com/pansea.io" target="_blank">
                <li className="twitter-icons">
                  <BsFacebook className="b-c-t f-w" />
                </li>
              </a>
              <a href="https://x.com/panseaio" target="_blank">
                <li className="twitter-icons">
                  <BsTwitter className="b-c-t f-w" />
                </li>
              </a>
              <a href="https://www.instagram.com/panseaio/" target="_blank">
                <li className="twitter-icons">
                  <AiFillInstagram className="b-c-t f-w" />
                </li>
              </a>
              <a href="https://www.youtube.com/@panseaio" target="_blank">
                <li className="twitter-icons">
                  <BsYoutube className="b-c-t f-w" />
                </li>
              </a>
            </ul>
          </div>
        </div>

        <hr className="para_clr m-b-0 p-b-0 " />

        <div className="m-q-b-d-b a-i-c j-c-s-b t-a-c f-s-0_8 p-x-2 p-y-2">
          <div className="p-y-0_5">© 2023 PanSea Inc</div>
          <div className="p-y-0_5">
            {" "}
            <Link to="/privacy">Privacy Policy</Link> &nbsp; &nbsp;{" "}
            <Link to="/terms">Terms of Service</Link>{" "}
          </div>
          <div className="d-n">
            Designed by{" "}
            <a href="https://kesaviwebsolutions.com" target="_blank">
              KESAVI WEB SOLUTIONS
            </a>
          </div>{" "}
        </div>
      </div>
    </>
  );
}

export default Footer;
