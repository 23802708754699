import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbars/Navbarnft";
import "./Collectioninsidepage.css";
import ProfileImage from "./Collection-images/Rectangle730.png";
import Checkvector from "./Collection-images/check.png";
import Footer from "../Footer";

//ICONS IMPORT
import Navbarnft_global from "../Navbars/Navbarnft_global";

import logo_nft from "../../Images/logo_nft.jpg";

// IMPORT CHECKBOX
import Checkbox from "@mui/material/Checkbox";
import down from "../../Images/down_arrow.png";

// ACCORDION IMPORT FILE
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collection_modal from "./Collection_modal";
// IMPORT
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";

import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import moment from "moment";

// IMPORT
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import {
  AccordionDetails,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";

import Refresh from "../../Images/Reload-vector.png";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { FallingLines } from "react-loader-spinner";
import {
  get_Airdrop_NFT_contract,
  get_Marketplace_contract,
  get_Validator_NFT_contract,
  get_all_nft_contract_,
} from "../../Web3/Contract";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { convertToLowercase, utils_towei } from "../../Web3/web3_api";
import { SelectTier } from "./TierSelect";
import { AirDropNFT_Address, NFT_Validator_Address } from "../../Web3/ABI";
import Skeleton from "react-loading-skeleton";
import { BsTelegram } from "react-icons/bs";
import { AiFillMediumCircle, AiOutlineGlobal } from "react-icons/ai";
import { CgSortAz } from "react-icons/cg";
import { TfiReload } from "react-icons/tfi";
import gold from "../../Images/tick_gold.png";
import blue from "../../Images/tick_blue.png";
import twitters from "../../Images/twitter.png";
import { IoIosArrowDown } from "react-icons/io";
import { Tooltip } from "antd";

const notify = (msg) => toast.success(msg);
const warning = (msg) => toast.error(msg);

// CHECK BOX IMPORT
const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Collectioninsidepage({
  URL,
  UserWallet,
  signMessage,
  logout,
  IPFS_image_baseURL,
}) {
  const { id } = useParams();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [name, setName] = useState("");
  const [banner, setBanner] = useState("");
  const [logo, setLogo] = useState("");
  const [createdAt, setCreateAt] = useState();
  const [Chain, setChain] = useState("MAAL");
  const [description, setDescription] = useState();
  const [creatorfee, setCreatorfee] = useState();
  const [cratorName, setCreatorName] = useState();
  const [collectionNFT, setCollectionNFT] = useState();
  const [collection_name, setCollection_name] = useState();
  const [creatorAddress, setCreatorAddress] = useState();
  const [totalvolume, setTotalVolume] = useState();
  const [telegramlink, setTelegramLink] = useState("");
  const [mediumlink, setMediumLink] = useState("");
  const [website, setWebsite] = useState("");
  const [bestoffer, setBestOffer] = useState(0);
  const [totalNFTs, steTotalNETs] = useState([]);
  const [totalNFTListed, setTotalNFTsListed] = useState();
  const [filterhidden, setfilterhiddens] = useState(true);
  const [isValidator, setIsValidator] = useState(false);
  const [wallettosend, setWalletToSend] = useState();
  const [Maaltoken, setMaalToken] = useState();
  const [tokenID, setTokenID] = useState();
  const [tier, setTier] = useState();
  const [collectionID, setCollectID] = useState();
  const [validatornftowner, setValidatorNftOwner] = useState("");
  const [airdropnftowner, setAirdropNFTOwner] = useState();
  const [nftstart, setNFTstart] = useState([]);
  const [nftend, setNFTend] = useState();
  const [page, setPage] = useState(1);
  const [florepice, setFlorePrice] = useState(0);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isascending, setisascendings] = useState(1);
  const [twitter, settwitter] = useState("");
  const [tick, settick] = useState("");

  const [recentHightest, setRecentHighest] = useState(0);

  useEffect(() => {
    const init = async () => {
      const collectionId = await axios
        .get(`${URL}/collectionbyid/${id}`)
        .then((res) => {
          setBanner(res.data.bannerImage);
          setLogo(res.data.logoImage);
          setName(res.data.name);
          settick(res.data.tick);
          settwitter(res.data.twitter);
          setCreatorfee(res.data.fee);
          setCreateAt(res.data.CreatedAt);
          setDescription(res.data.description);
          setCreatorAddress(res.data.Creator);
          setTotalVolume(
            res.data.sold_price.reduce((a, c) => Number(a) + Number(c), 0)
          );
          setFlorePrice(Math.min(...res.data.sold_price));
          setBestOffer(Math.max(...res.data.sold_price));
          setIsValidator(res.data.IsValidator);
          setCollectID(res.data.Collection_id);
          setCollection_name(res.data.name);
          setWebsite(res.data.website);
          setTelegramLink(res.data.telegram);
          setMediumLink(res.data.medium);
          return res.data.Collection_id;
        })
        .catch(console.error);

      axios
        .post(
          `${URL}/${
            isascending == 1 || isascending == 2
              ? "sort/nftbyonlycollection"
              : "nftbyonlycollection"
          }`,
          {
            collection: collectionId,
            isAccending: isascending,
            page: page,
          }
        )
        .then(async (response) => {
          steTotalNETs(response.data);

          const nfts = [];
          const offer = [];
          let lised = 0;
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].isNFTMinted && response.data[i].isCustom) {
              const contractValidator = await get_all_nft_contract_(
                response.data[i].NFTContract
              );

              const Validator = await get_Validator_NFT_contract();

              const owner = await Validator.methods._owner().call();
              const symbol = await Validator.methods.symbol().call();
              setValidatorNftOwner(convertToLowercase(owner));
              const uri = await contractValidator.methods.baseURI().call();
              const nftname = await contractValidator.methods.name().call();

              const Datafromserver = await axios
                .post(`${URL}/callcorsapi`, {
                  uri: `${uri}${response.data[i].tokenID}.json`,
                })
                .then((res) => {
                  return res.data;
                });

              response.data[i].name = nftname;
              response.data[i].data = Datafromserver;
              response.data[i].contractanimation = Datafromserver.animation_url;
              response.data[i].contractimage = Datafromserver.image;
              response.data[i].symbol = symbol;

              nfts.push(response.data[i]);
            } else if (
              response.data[i].isNFTMinted &&
              response.data[i].airDropNFT
            ) {
              const contractValidator = await get_all_nft_contract_(
                response.data[i].NFTContract
              );

              const Validator = await get_Airdrop_NFT_contract();
              const symbol = await Validator.methods.symbol().call();
              // const owner = await Validator.methods._owner().call();

              // setAirdropNFTOwner(convertToLowercase(owner));
              let uri = await contractValidator.methods.baseURI().call();

              if (uri === "" || uri === undefined) {
                uri = await contractValidator.methods.initialURI().call();
              }
              if (typeof uri === "string") {
                if (uri[uri.length - 1] != "/") {
                  uri = uri + "/";
                }
              }
              const nftname = await contractValidator.methods.name().call();

              const Datafromserver = await axios
                .post(`${URL}/callcorsapi`, {
                  uri: `${uri}${response.data[i].tokenID}.json`,
                })
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  console.log(err);
                });
              response.data[i].name = nftname;
              response.data[i].data = Datafromserver;
              response.data[i].contractanimation = Datafromserver.animation_url;
              response.data[i].contractimage = Datafromserver.image;
              response.data[i].symbol = symbol;
              nfts.push(response.data[i]);
            } else {
              const contractValidator = await get_all_nft_contract_(
                response.data[i].NFTContract
              );
              const symbol = await contractValidator.methods.symbol().call();
              response.data[i].symbol = symbol;
              nfts.push(response.data[i]);
            }
          }
          setCollectionNFT(
            typeof collectionNFT == "object"
              ? [...collectionNFT, ...nfts]
              : [...nfts]
          );
          // toggleSortingAsc();
          setTotalNFTsListed(lised);
          setButtonLoader(false);
        });

      axios
        .post(`${URL}/recenthigh`, {
          id: collectionId,
        })
        .then((res) => {
          setRecentHighest(res.data[0].last_sold_price);
        });
    };
    init();
  }, [UserWallet, open, page]);

  const Time_ago = (time) => {
    const date = moment(time).format("LLL");
    return date;
  };

  useEffect(() => {
    axios
      .post(`${URL}/getuser`, {
        wallet: creatorAddress,
      })
      .then((res) => {
        setCreatorName(res.data[0].name);
      });
  }, [creatorAddress, open]);

  console.log("NFTs", collectionNFT);

  const searchNFTS = async (name) => {
    console.log(name);
    axios
      .post(`${URL}/nftbyonlyname`, {
        name: name,
        collection: collection_name,
      })
      .then(async (response) => {
        const marketplace = await get_Marketplace_contract();
        const nfts = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isNFTMinted) {
            const listting = await marketplace.methods
              .getListing(
                response.data[i].NFTContract,
                response.data[i].tokenID
              )
              .call();
            response.data[i].pricing = listting.price;
            nfts.push(response.data[i]);
          }
        }
        setCollectionNFT(nfts);
      });
  };

  const reload_nft = async () => {
    return;
    axios
      .post(`${URL}/nftbyonlycollection`, {
        collection: collection_name,
      })
      .then(async (response) => {
        const marketplace = await get_Marketplace_contract();
        const nfts = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isNFTMinted) {
            const listting = await marketplace.methods
              .getListing(
                response.data[i].NFTContract,
                response.data[i].tokenID
              )
              .call();
            response.data[i].pricing = listting.price;
            nfts.push(response.data[i]);
          }
        }
        setCollectionNFT(nfts);
      });
  };

  const mintByValidator = async () => {
    try {
      setOpen(true);
      const contractValidator = await get_Validator_NFT_contract();
      const a = await utils_towei(Maaltoken);
      const data = await contractValidator.methods
        .mintByApproved(wallettosend, a, tier, tokenID)
        .send({ from: UserWallet });
      if (data.status) {
        const supply = await contractValidator.methods.totalSupply().call();
        await axios
          .post(`${URL}/makenfts`, {
            Creator: UserWallet.toLowerCase(),
            Owner: wallettosend.toLowerCase(),
            // collectionID: collectionID,
            last_update: new Date().getTime(),
            collectionID: collectionID,
            supply: supply,
            tokenID: tokenID,
            isNFTMinted: true,
            isCustom: true,
            chain: 786,
            NFTContract: NFT_Validator_Address,
            nftType: 721,
          })
          .then((res) => {
            console.log(res.data);
            setOpen(false);
            notify("NFT created successfully");
          })
          .catch((error) => {
            setOpen(false);
            console.log(error);
          });
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const mintAirDropNFTs = async () => {
    try {
      setOpen(true);
      const contractValidator = await get_Airdrop_NFT_contract();
      const data = await contractValidator.methods
        .batchmintByOwner(wallettosend, nftstart)
        .send({ from: UserWallet });
      if (data.status) {
        const supply = await contractValidator.methods.totalSupply().call();
        await axios
          .post(`${URL}/makeingmultiplenfts`, {
            Creator: UserWallet.toLowerCase(),
            Owner: wallettosend.toLowerCase(),
            last_update: new Date().getTime(),
            collectionID: collectionID,
            starting: nftstart,
            supply: supply,
            isNFTMinted: true,
            airDropNFT: true,
            chain: 786,
            NFTContract: AirDropNFT_Address,
            nftType: 721,
          })
          .then((res) => {
            console.log(res.data);
            setOpen(false);
            notify("NFT created successfully");
          })
          .catch((error) => {
            setOpen(false);
            console.log(error);
          });
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  return (
    <>
      <Navbarnft_global
        UserWallet={UserWallet}
        signMessage={signMessage}
        logout={logout}
        URL={URL}
      />
      <div className="background-images-collections h-30vh o-h">
        <img src={banner} className="w-100 h-100 o-f-c" />
      </div>
      <div className="m-t-_7 container-fluid  collection-container m-b-2">
        {" "}
        <img
          src={logo}
          alt=""
          className="   b-5px b-c-w b-r-20 w-10 h-10 m-q-b-d-n"
        />
      </div>
      <div className=" container-fluid  collection-container m-b-2">
        {" "}
        <img
          src={logo}
          alt=""
          className=" b-3 b-c-w  b-r-10 w-5 h-5  m-q-a-d-n"
        />
      </div>
      <div className="container-fluid background-images-collections"> </div>
      <div className="container-fluid collection-container ">
        <div className="row g-0 " id="collection-inside-margin">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <h6 className="profile-name-inside mx-2 ">
              <div className="d-f a-i-c">
                <div className="m-r-1">
                  <span className="f-w-600">{name}</span>{" "}
                </div>
                <div className="">
                  {tick ? (
                    tick == "gold" ? (
                      <Tooltip title="Sharia Compliant">
                        <img src={gold} className="w-2" />
                      </Tooltip>
                    ) : tick == "blue" ? (
                      <Tooltip title="Verified">
                        {" "}
                        <img src={blue} className="w-2" />
                      </Tooltip>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </h6>
            <h6 className="sub-title-profile mx-2">
              By <span className="f-w-600">{cratorName}</span> &nbsp;
            </h6>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <span className="icons-collections-inside c-p">
              {/* <LanguageOutlinedIcon style={{ color: "#6A6A6A" }} /> */}

              {telegramlink != "" ? (
                <a href={telegramlink} target="_blank">
                  <BsTelegram className="icons-setting-width f-s-2" />
                </a>
              ) : (
                ""
              )}
              {mediumlink != "" ? (
                <a href={mediumlink}>
                  <AiFillMediumCircle className="icons-setting-width f-s-2" />
                </a>
              ) : (
                ""
              )}
              {website != "" ? (
                <a href={website}>
                  <AiOutlineGlobal className="icons-setting-width m-r-1 f-s-2" />
                </a>
              ) : (
                ""
              )}
              {twitter ? (
                <a href={twitter}>
                  <img
                    src={twitters}
                    className="icons-setting-width w-2 f-s-2"
                  />
                </a>
              ) : (
                ""
              )}

              {/* <ShareIcon className="icons-setting-width" /> */}
              {creatorAddress == UserWallet ? (
                <Collection_modal
                  UserWallet={UserWallet}
                  URL={URL}
                  IPFS_image_baseURL={IPFS_image_baseURL}
                />
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
        <div className="container-fluid ">
          <div className="row g-0">
            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
              <span>
                Items{" "}
                <span className="f-w-600">
                  {" "}
                  {collectionNFT ? totalNFTs.length : 0}
                </span>
                &nbsp; -
              </span>
              <span style={{ marginLeft: "10px" }}>
                Created <span className="f-w-600">{Time_ago(createdAt)}</span>
              </span>
              &nbsp; -
              <span style={{ marginLeft: "10px" }}>
                Creator Fee <span className="f-w-600">{creatorfee}</span> %
              </span>
              &nbsp; -
              <span style={{ marginLeft: "10px" }}>
                Chain <span className="f-w-600">{Chain}</span>{" "}
              </span>
              <p className="collection-inside-bio">{description}</p>
            </div>

            {UserWallet && validatornftowner == UserWallet ? (
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 t-a-r_mq">
                <button
                  className="bg_yellow_clr  card_bg b-n p-x-2 p-y-0_5 b-r-15 m-r-0"
                  onClick={handleShow}
                >
                  Mint
                </button>
              </div>
            ) : (
              ""
            )}

            {false ? (
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 t-a-r_mq">
                <button
                  className="bg_yellow_clr  card_bg b-n p-x-2 p-y-0_5 b-r-15 m-r-0"
                  onClick={handleShow2}
                >
                  Mint
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-sm-12 col-12">
              <div className="row content-collection-data">
                <div className="col-lg-2 col-md-2 col-sm-6 col-6 py-3">
                  <span className="eth-section">
                    <span className="f-w-600">{totalvolume} MAAL</span>
                  </span>
                  <br />
                  <span className="co-volume-section">Total volume</span>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-6 py-3">
                  <span className="eth-section">
                    <span className="f-w-600">
                      {florepice == "Infinity" || florepice == "-Infinity"
                        ? 0
                        : florepice}{" "}
                      MAAL
                    </span>
                  </span>
                  <br />
                  <span className="co-volume-section">Floor price</span>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-6 py-3">
                  <span className="eth-section">
                    <span className="f-w-600">
                      {bestoffer == "-Infinity" || bestoffer == "Infinity"
                        ? 0
                        : bestoffer}{" "}
                      MAAL
                    </span>
                  </span>
                  <br />
                  <span className="co-volume-section">Best price</span>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-6 py-3">
                  <span className="eth-section">
                    <span className="f-w-600">{recentHightest} MAAL</span>
                  </span>
                  <br />
                  <span className="co-volume-section">Recent highest</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-12"></div>
          </div>
          <Grid container>
            <Grid item xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>
              <div className=" m-q-a-p-r-1_1200 ">
                <div className="d-f a-i-c">
                  <Button
                    className="refresh-button collection-filter b-c-t b-1 m-r-1"
                    onClick={() => setfilterhiddens(!filterhidden)}
                  >
                    <CgSortAz className="clrs f-s-2" />
                  </Button>
                  <Button
                    className="refresh-button collection-filter b-c-t b-1"
                    onClick={() => reload_nft()}
                  >
                    <TfiReload className="clrs f-w-600" />
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}>
              <div className="search-nav search-text-field m-y-1 b-c-t b-1">
                <div className="input-nav ">
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                    }}
                    className="collection-filter"
                    id="form-search-input-collection"
                  >
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon id="search-icon" />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search by NFTs"
                      id="search-icon-text"
                      onChange={(e) => searchNFTS(e.target.value)}
                    />
                  </Paper>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="container-fluid ">
          <div className="row side-bar-collection">
            {filterhidden ? (
              <>
                {" "}
                <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row g-0">
                    <div className="status-tag m-t-2">
                      <span>Status</span>
                    </div>
                    {/* <div className="bu-toggle">
                      <div className="bu-nw-tag">Buy Now</div>
                      <div className="check-box-bu">
                        {" "}
                        <Checkbox {...label} style={{ color: "#c5c5c5" }} />
                      </div>
                    </div>
                    <div className="bu-toggle">
                      <div className="bu-nw-tag"> On Auction</div>{" "}
                      <div className="check-box-bu">
                        {" "}
                        <Checkbox {...label} style={{ color: "#c5c5c5" }} />
                      </div>
                    </div> */}

                    <div className="coll-accordion-section">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="b-c-t c-i b-n"
                        >
                          <div className="d-f j-c-s-b a-i-c w-7">
                            <div className="">Price</div>

                            <IoIosArrowDown />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="pa-1">
                          <Dropdown.Item href="#/action-1" className="m-y-1">
                            Low to High
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2" className="m-y-1">
                            High to Low
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="b-c-t c-i b-n"
                        >
                          <div className="d-f j-c-s-b a-i-c w-7">
                            <div className="">Token Id</div>

                            <IoIosArrowDown />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="pa-1">
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() => {
                              setCollectionNFT([]);
                              setisascendings(1);
                              setPage(1);
                            }}
                            className="m-y-1"
                          >
                            Ascending
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-2"
                            onClick={() => {
                              setCollectionNFT([]);
                              setisascendings(2);
                              setPage(1);
                            }}
                            className="m-y-1"
                          >
                            Descending
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              " "
            )}

            {/*-------------------------------------------------------------------------CARDS-IMPORT-----------------------------------------------------------  */}
            <div
              className={`col-xl-${
                filterhidden ? 10 : 12
              } col-lg-12 col-md-12 col-sm-12 col-12`}
            >
              <div className="fYtAai m-t-2">
                {!collectionNFT ? (
                  <>
                    <div className="  ">
                      {" "}
                      <div className="p-x-0_5 p-y-1">
                        <Link to="" className="">
                          <div className="">
                            <div className="">
                              {" "}
                              <Skeleton
                                height={200}
                                width="100%"
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                className="border_rad bt_br"
                              />
                            </div>
                            <div className="notable_card p-x-1 ">
                              <div className=" p-y-1 f-w-600">
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={1}
                                />
                                {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                              </div>
                              <div className="row p-b-1">
                                <div className="col">
                                  <div className="f-s-0_8">
                                    {" "}
                                    <Skeleton
                                      baseColor="#E4E9EB"
                                      highlightColor="#fff"
                                      count={2}
                                    />
                                  </div>
                                  {/* <div className=" f-w-600">88 PRIME</div> */}
                                </div>
                                <div className="col">
                                  <div className="f-s-0_8">
                                    <Skeleton
                                      baseColor="#E4E9EB"
                                      highlightColor="#fff"
                                      count={2}
                                    />
                                  </div>
                                  {/* <div className=" f-w-600">455 MAAL</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      {" "}
                      <div className="p-x-0_5 p-y-1">
                        <Link to="" className="">
                          <div className="">
                            {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                            <div className="">
                              <Skeleton
                                height={200}
                                width="100%"
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                className="border_rad bt_br"
                              />
                            </div>
                            <div className="notable_card p-x-1 ">
                              <div className=" p-y-1 f-w-600">
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={1}
                                />
                                {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                              </div>
                              <div className="row p-b-1">
                                <div className="col">
                                  <div className="f-s-0_8">
                                    {" "}
                                    <Skeleton
                                      baseColor="#E4E9EB"
                                      highlightColor="#fff"
                                      count={2}
                                    />
                                  </div>
                                  {/* <div className=" f-w-600">88 PRIME</div> */}
                                </div>
                                <div className="col">
                                  <div className="f-s-0_8">
                                    <Skeleton
                                      baseColor="#E4E9EB"
                                      highlightColor="#fff"
                                      count={2}
                                    />
                                  </div>
                                  {/* <div className=" f-w-600">455 MAAL</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <div className="p-x-0_5 p-y-1">
                        <Link to="" className="">
                          <div className="">
                            {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                            <div className="">
                              <Skeleton
                                height={200}
                                width="100%"
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                className="border_rad bt_br"
                              />
                            </div>
                            <div className="notable_card p-x-1 ">
                              <div className=" p-y-1 f-w-600">
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={1}
                                />
                                {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                              </div>
                              <div className="row p-b-1">
                                <div className="col">
                                  <div className="f-s-0_8">
                                    {" "}
                                    <Skeleton
                                      baseColor="#E4E9EB"
                                      highlightColor="#fff"
                                      count={2}
                                    />
                                  </div>
                                  {/* <div className=" f-w-600">88 PRIME</div> */}
                                </div>
                                <div className="col">
                                  <div className="f-s-0_8">
                                    <Skeleton
                                      baseColor="#E4E9EB"
                                      highlightColor="#fff"
                                      count={2}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      {" "}
                      <div className="p-x-0_5 p-y-1">
                        <Link to="" className="">
                          <div className="">
                            {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                            <div className="">
                              {" "}
                              <Skeleton
                                height={200}
                                width="100%"
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                className="border_rad bt_br"
                              />
                            </div>
                            <div className="notable_card p-x-1 ">
                              <div className=" p-y-1 f-w-600">
                                <Skeleton
                                  baseColor="#E4E9EB"
                                  highlightColor="#fff"
                                  count={1}
                                />
                                {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                              </div>
                              <div className="row p-b-1">
                                <div className="col">
                                  <div className="f-s-0_8">
                                    {" "}
                                    <Skeleton
                                      baseColor="#E4E9EB"
                                      highlightColor="#fff"
                                      count={2}
                                    />
                                  </div>
                                  {/* <div className=" f-w-600">88 PRIME</div> */}
                                </div>
                                <div className="col">
                                  <div className="f-s-0_8">
                                    <Skeleton
                                      baseColor="#E4E9EB"
                                      highlightColor="#fff"
                                      count={2}
                                    />
                                  </div>
                                  {/* <div className=" f-w-600">455 MAAL</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </>
                ) : collectionNFT.length > 0 ? (
                  collectionNFT.map((res) => {
                    return (
                      <div>
                        <Link to={`/assets/${res._id}`}>
                          {res.contractanimation ? (
                            <div class="sc-57ceaf99-0  sc-a3e5d0bb-1 kHrhvD ilsduG Asset--loaded">
                              <article
                                class="sc-a3e5d0bb-7 kmazGs Asset--loaded AssetSearchList--asset"
                                data-relay-id="QXNzZXRUeXBlOjEzMDI1MTQ4Mjg="
                                style={{ width: "100%" }}
                              >
                                <a
                                  class="sc-1f719d57-0 eiItIQ Asset--anchor"
                                  href="/assets/matic/0x1e75431d44038afd8b1b9c282644dad312c40b62/56589471313415865648799857692285113761643090818851508876647998683280255746049"
                                >
                                  <div class="sc-57ceaf99-0 sc-b6f6fcd7-2 cQBoPP iwDNLZ">
                                    <div class="sc-57ceaf99-0 inwolf">
                                      <div class="sc-fabf517e-0 hObjOE">
                                        <div
                                          height="100%"
                                          width="100%"
                                          class="sc-57ceaf99-0 sc-630fc9ab-0 sc-99655001-0 sc-4422a702-0 sc-dada3b88-0 jLlAQq bNkKFC dsowZP jZNcco bhxycR"
                                        >
                                          <div class="AssetMedia--animation">
                                            <div class="sc-57ceaf99-0 sc-630fc9ab-0 sc-99655001-0 sc-4422a702-0 sc-dada3b88-0 kHrhvD bNkKFC dsowZP jZNcco bhxycR AssetMedia--playback-wrapper">
                                              <video
                                                autoPlay
                                                className="AssetMedia--video"
                                                controls
                                                controlsList="nodownload"
                                                loop
                                                playsInline
                                                // poster="https://i.seadn.io/gcs/files/cd3480224f20f93f996bc1c63c07371b.jpg?w=500&auto=format"
                                                preload="metadata"
                                                style={{
                                                  borderRadius: "0px",
                                                }}
                                              >
                                                <source
                                                  data-testid="AssetMedia--video"
                                                  src={res.contractanimation}
                                                  type="video/mp4"
                                                />
                                              </video>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="sc-57ceaf99-0 sc-a3e5d0bb-6 kHrhvD cYkZoQ"></div>
                                  <div class="sc-57ceaf99-0 sc-adfb3df2-3 loLajA kfpksQ  bg_clr">
                                    <div class="sc-57ceaf99-0 kHrhvD">
                                      <div class="sc-57ceaf99-0 sc-630fc9ab-0 fIYxGL bNkKFC">
                                        <div
                                          class="sc-8a1b6610-0 irKuNm sc-adfb3df2-6 hvtgmi pa-1"
                                          tabindex="-1"
                                        >
                                          <span
                                            color="charcoal"
                                            data-testid="ItemCardFooter-name"
                                            class="sc-57ceaf99-0 sc-cfddf86-2 ekBhDm fDpMic"
                                          >
                                            <span className="f-w-600">
                                              {" "}
                                              {res.symbol}&nbsp;&nbsp;#
                                              {res.tokenID}
                                            </span>
                                            <br />
                                          </span>
                                        </div>
                                      </div>
                                      {/* <div className="">0.002 ETH</div> */}
                                    </div>
                                    <div
                                      width="100%"
                                      class="sc-57ceaf99-0 sc-630fc9ab-0 sc-99655001-0 fwZkkd bNkKFC dsowZP"
                                    >
                                      <div
                                        height="20px"
                                        width="100%"
                                        class="sc-57ceaf99-0 sc-630fc9ab-0 jVGyBF bNkKFC"
                                      ></div>
                                      <div
                                        height="20px"
                                        class="sc-57ceaf99-0 sc-630fc9ab-0 kqnFrL bNkKFC"
                                      >
                                        <div
                                          width="100%"
                                          class="sc-57ceaf99-0 jLggKs"
                                        >
                                          <div
                                            width="100%"
                                            class="sc-57ceaf99-0 sc-630fc9ab-0 sc-83e9f6dd-0 jLggKs bNkKFC knimv"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </article>
                            </div>
                          ) : res.imageURL ? (
                            <>
                              <div className="b-1 b-r-5 ">
                                <div className="h-21  d-f a-i-c j-c-c  o-h">
                                  <img src={res.imageURL} alt="" className="" />{" "}
                                </div>
                                <hr className="ma-0" />

                                <div className=" f-w-600 para_clr pa-1 ">
                                  <div className="">{res.name} </div>
                                  <div className=""> (#{res.tokenID})</div>
                                </div>
                              </div>

                              {/* <Card.Img
                                  variant="top"
                                  src={res.imageURL}
                                  className="h-18_56  b-r-t"
                                />
                                <Card.Body className="c-w">
                                  <Card.Title className="cards-title-name c-w ">
                                    <span className="f-w-600 para_clr">
                                      {" "}
                                      {res.name} (#{res.tokenID})
                                    </span>
                                  </Card.Title>
                                </Card.Body> */}
                            </>
                          ) : (
                            <>
                              <Card.Img
                                variant="top"
                                src={logo_nft}
                                className="h-100  b-r-t"
                              />
                              <Card.Body className="c-w bg_clr">
                                <Card.Title className="cards-title-name c-w ">
                                  <span className="f-w-600 para_clr">
                                    {" "}
                                    {res.name} (#{res.tokenID})
                                  </span>
                                </Card.Title>
                              </Card.Body>
                            </>
                          )}
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <p className="p-y-7 t-a-c">No NFT found</p>
                )}
              </div>

              {collectionNFT && collectionNFT.length >= 4 ? (
                <div className="t-a-c">
                  <button
                    className="m-b-2 f-w-600 f-s-1_25 p-x-3 p-y-0_5 b-r-20 b-c-t b-n m-t-4"
                    onClick={() => {
                      setButtonLoader(true);
                      setPage(page + 1);
                    }}
                  >
                    {buttonLoader ? (
                      " Loading..."
                    ) : (
                      <img src={down} className="w-3" />
                    )}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal show={show} onHide={handleClose} className="">
        <Modal.Body>
          <div className="p-x-2">
            <div className="t-a-c f-s-1_5 f-w-600 m-b-3 m-t-1">
              Validator Minting
            </div>
            <div className="m-b-0_5 f-w-600">Wallet</div>
            <div className="m-b-2">
              <input
                type="text"
                onChange={(e) => {
                  setWalletToSend(convertToLowercase(e.target.value));
                }}
                className="w-100 b-1 b-r-10 pa-0_5"
              />
            </div>
            <div className="m-b-0_5 f-w-600">Maal coin</div>
            <div className="m-b-2">
              <input
                type="text"
                onChange={(e) => setMaalToken(e.target.value)}
                className="w-100 b-1 b-r-10 pa-0_5"
              />
            </div>
            <div className="m-b-0_5 f-w-600">Tier</div>
            <div className="m-b-2">
              <Form.Select
                className="b-1 b-r-10 pa-0_5"
                onChange={(e) => setTier(e.target.value)}
              >
                <option value="1">Select Tier</option>
                <option value="1">Tier 1</option>
                <option value="2">Tier 2</option>
                <option value="3">Tier 3</option>
              </Form.Select>
            </div>
            <div className="m-b-0_5 f-w-600">Tokin Id</div>
            <div className="m-b-2">
              <input
                type="number"
                onChange={(e) => {
                  const id = SelectTier(tier, Number(e.target.value));
                  console.log("ID", id);
                  if (typeof id === "string") {
                    warning(id);
                  } else {
                    setTokenID(id);
                  }
                }}
                className="w-100 b-1 b-r-10 pa-0_5"
              />
            </div>
            <div className="t-a-c">
              <button
                className="para_clr  p-x-2 p-y-0_5 b-r-10 b-c-t b-1 m-b-1"
                onClick={() => mintByValidator()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2} className="">
        <Modal.Body>
          <div className="p-x-2">
            <div className="t-a-c f-s-1_5 f-w-600 m-b-3 m-t-1">NFT Mint</div>
            <div className="m-b-0_5 f-w-600">Wallet</div>
            <div className="m-b-2">
              <input
                type="text"
                onChange={(e) => {
                  setWalletToSend(convertToLowercase(e.target.value));
                }}
                className="w-100 b-1 b-r-10 pa-0_5"
              />
            </div>
            <div className="m-b-0_5 f-w-600">Array of IDs</div>
            <div className="m-b-2">
              <input
                type="text"
                onChange={(e) => {
                  setNFTstart(e.target.value.split(","));
                }}
                className="w-100 b-1 b-r-10 pa-0_5"
              />
            </div>

            <div className="t-a-c">
              <button
                className="para_clr  p-x-2 p-y-0_5 b-r-10 b-c-t b-1 m-b-1"
                onClick={() => mintAirDropNFTs()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Toaster />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
