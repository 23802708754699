import React, { useEffect, useState } from "react";
import Navbarnft_global from "./Navbars/Navbarnft_global";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import gold from "../Images/tick_gold.png";
import blue from "../Images/tick_blue.png";
import axios from "axios";
import { Tooltip } from "antd";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdLogOut } from "react-icons/io";
import Admin_Sidebar from "./Admin_Sidebar";

function Admin_tick({ UserWallet, signMessage, logout, URL }) {
  const navigate = useNavigate();
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    const init = async () => {
      axios
        .get(`${URL}/myallcollections`)
        .then((res) => {
          console.log("res", res.data);
          setCollection(res.data);
        })
        .catch(console.error);
    };
    init();
  }, []);

  const handleUpdate = async (eventId, newTickValue) => {
    try {
      const response = await axios.put(
        `${URL}/updateTick/${eventId}/${newTickValue}`
      );

      alert(response.data.message);
      window.location.reload();
    } catch (error) {
      alert("Error updating document. Please check the console for details.");
      console.error("Error updating document:", error);
    }
  };

  const handleLogout = () => {
    // Remove admin:true from local storage
    localStorage.removeItem("admin__tijarah");
    // Redirect to the login page or any other desired page
    navigate("/");
  };

  return (
    <div>
      <div>
        <Navbarnft_global />
        <div className="">
          {" "}
          <div className="d-g g-t-c-200px   m-t-4">
            {" "}
            <div className="b-r-1">
              <Admin_Sidebar />
            </div>
            <div className="container-kws p-x-2 scrll max-h-80vh o-a">
              <div className="">
                {/* <div className="d-f m-t-4 m-b-4 j-c-s-b a-i-c ">
              <div className="w-2"></div>
              <div className="t-a-c  f-w-600 f-s-2">Dashboard</div>

              <div className="f-s-1_25 c-p" onClick={() => handleLogout()}>
                Logout &nbsp;
                <IoMdLogOut className="f-w-600 f-s-1_25" />
              </div>
            </div> */}
                <div className="">
                  <Paper
                    sx={{ width: "100%", overflow: "hidden", boxShadow:"none" }}
                    className="b-c-t b-n m-b-7"
                  >
                    <TableContainer className="b-c-t b-n">
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        className="b-c-t b-n"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="f-w-600">S. No.</TableCell>
                            <TableCell className="f-w-600">Name</TableCell>
                            <TableCell
                              className="f-w-600"
                              style={{ minWidth: "250" }}
                            >
                              Banner Image
                            </TableCell>
                            <TableCell
                              className="f-w-600"
                              style={{ minWidth: "250" }}
                            >
                              Feature Image
                            </TableCell>
                            <TableCell
                              className="f-w-600"
                              style={{ minWidth: "250" }}
                            >
                              Logo Image
                            </TableCell>

                            <TableCell className="f-w-600">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {collection.map((res, index) => {
                            return (
                              <>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                  className="a-i-c"
                                >
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    <span className="m-r-1"> {res.name}</span>
                                    {res.tick ? (
                                      res.tick == "gold" ? (
                                        <img src={gold} className="w-1_5" />
                                      ) : res.tick == "blue" ? (
                                        <img src={blue} className="w-1_5" />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip
                                      title={
                                        <div className="w-15 b-c-t  b-r-5 o-h">
                                          <img
                                            src={res.bannerImage}
                                            className="d-f a-i-c j-c-c o-f-c"
                                            alt="Banner"
                                          />
                                        </div>
                                      }
                                    >
                                      <div>
                                        <img
                                          src={res.bannerImage}
                                          className="w-7 h-5  b-r-5 "
                                          alt="Banner"
                                        />
                                      </div>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip
                                      title={
                                        <div className="w-15 b-c-t  b-r-5 o-h">
                                          <img
                                            src={res.featureImage}
                                            className="d-f a-i-c j-c-c o-f-c"
                                            alt="Banner"
                                          />
                                        </div>
                                      }
                                    >
                                      <div>
                                        <img
                                          src={res.featureImage}
                                          className="w-7 h-5  b-r-5 "
                                          alt="Banner"
                                        />
                                      </div>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip
                                      title={
                                        <div className="w-15 b-c-t  b-r-5 o-h">
                                          <img
                                            src={res.logoImage}
                                            className="d-f a-i-c j-c-c o-f-c"
                                            alt="Banner"
                                          />
                                        </div>
                                      }
                                    >
                                      <div>
                                        <img
                                          src={res.logoImage}
                                          className="w-7 h-5  b-r-5 o-h"
                                          alt="Banner"
                                        />
                                      </div>
                                    </Tooltip>
                                  </TableCell>

                                  <TableCell>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="b-c-t b-n c-b"
                                      >
                                        <BsThreeDotsVertical className="c-b" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className="pa-0 ma-0">
                                        <Dropdown.Item
                                          className="pa-0_5  hover_effect"
                                          onClick={() =>
                                            handleUpdate(res._id, "gold")
                                          }
                                        >
                                          Gold Tick
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="pa-0_5  hover_effect"
                                          onClick={() =>
                                            handleUpdate(res._id, "blue")
                                          }
                                        >
                                          Blue Tick
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="pa-0_5  hover_effect"
                                          onClick={() =>
                                            handleUpdate(res._id, "cancel")
                                          }
                                        >
                                          Cancel
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {collection.length > 0 ? (
                        ""
                      ) : (
                        <>
                          <div className="p-y-5 t-a-c">No data to show...</div>
                        </>
                      )}
                    </TableContainer>
                  </Paper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin_tick;
