import React from "react";
import Help_sidebar from "./Help_sidebar";
import Navbarnft_global from "../Navbars/Navbarnft_global";

function Buy() {
  return (
    <div>
      <Navbarnft_global />
      <div className="">
        {" "}
        <div className="d-f j-c-s-b g-2 m-t-4">
          {" "}
          <div className="m-q-b-d-n b-r-1">
            <Help_sidebar />
          </div>
          <div className="max-w-50 p-x-2 scrll max-h-80vh o-a">
            <div className="f-s-1_5 f-w-600 m-b-1">
              Can I resell an NFT I purchased?
            </div>
            <div className="m-b-2">
              Yes, you can resell an NFT that you have purchased. The process
              typically involves the following steps:
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">1. Ownership Verification:</span>{" "}
              Ensure that you have full ownership of the NFT in your digital
              wallet. Only the current owner of an NFT can list it for sale.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">2. Connect Your Wallet:</span>{" "}
              Log in to the marketplace and connect your digital wallet that
              contains the NFT
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">3. List the NFT for Sale:</span>{" "}
              Find the option to sell or list an NFT on the marketplace. You
              will then be able to select the specific NFT from your wallet that
              you wish to sell.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">4. Set the Price:</span>
              Decide on the selling price for your NFT. You can set a fixed
              price or opt for an auction format, depending on the options
              provided by the marketplace.{" "}
            </div>

            <div className="m-b-2">
              <span className="f-w-600  m-r-1">5. Pay Listing Fees:</span> Some
              marketplaces might charge a fee to list an NFT for sale. Be
              prepared to pay this fee, which could be in the form of a flat
              listing fee or a percentage of the sale price.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                6. Transaction and Gas Fees:{" "}
              </span>{" "}
              Be aware that selling an NFT will also involve transaction fees,
              including gas fees if you're using a blockchain like MAAL. These
              fees will vary based on the network's congestion at the time of
              sale.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">7. Promote Your Sale: </span> To
              increase the chances of your NFT being sold, you might want to
              promote it on social media, forums, or other platforms where
              potential buyers are likely to be.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">8. Complete the Sale: </span>{" "}
              Once a buyer is found and the transaction is complete, the
              marketplace will handle the transfer of the NFT from your wallet
              to the buyer's wallet. The funds from the sale (minus any
              marketplace fees and commissions) will be transferred to your
              wallet.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">9. Royalties: </span> If the NFT
              was originally minted with a royalty agreement (which is a
              percentage of future sales paid to the original creator), the
              marketplace will automatically pay out royalties to the creator
              from each resale.
            </div>

            <div className="m-b-2">
              Remember, the value of NFTs can fluctuate widely, and there's no
              guarantee that you'll be able to sell an NFT for more than you
              paid for it. It's important to understand the market and the
              specific attributes of your NFT that could influence its resale
              value.
            </div>
          </div>
          <div className="v-h">.</div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
