import React, { useState } from "react";
import { S3 } from "aws-sdk";

//   const [selectedFile, setSelectedFile] = useState(null);
// const [images, setimages] = useState();

const config = {
  accessKeyId: process.env.REACT_APP_API_KEY,
  secretAccessKey: process.env.REACT_APP_API_SECRET_KEY,
  region: "ap-southeast-1",
};

const s3 = new S3(config);

//   const handleFileInput = (e) => {
//     setSelectedFile(e.target.files[0]);
//   };

export default async function uploadFile(selectedFile) {
  const params = {
    Bucket: "pansea",
    Key: selectedFile.name,
    Body: selectedFile,
    ContentType: selectedFile.type, // Set the ContentType based on the file type
  };

  try {
    const data = await s3.upload(params).promise();
    // setimages(data.Location);
    console.log(data.Location);
    return data.Location;
  } catch (error) {
    console.error(error);
  }
}
