import React from "react";
import Help_sidebar from "./Help_sidebar";
import Navbarnft_global from "../Navbars/Navbarnft_global";

function Buy() {
  return (
    <div>
      <Navbarnft_global />
      <div className="">
        {" "}
        <div className="d-f j-c-s-b g-2 m-t-4">
          {" "}
          <div className="m-q-b-d-n b-r-1">
            <Help_sidebar />
          </div>
          <div className="max-w-50 p-x-2 scrll max-h-80vh o-a">
            <div className="f-s-1_5 f-w-600 m-b-1">
              How do I create and list an NFT for sale on PANSEA?
            </div>
            <div className="m-b-2">
              create and list an NFT (Non-Fungible Token) for sale on an NFT
              marketplace, follow these steps:
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">1. Choose Your Content:</span>{" "}
              Decide what digital asset you want to turn into an NFT. This could
              be artwork, music, a video, or any digital creation that you own
              and have the rights to.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                2. Set Up a Digital Wallet:
              </span>{" "}
              If you don't already have one, set up a digital wallet that
              supports the MAAL This wallet will store your NFTs and
              cryptocurrencies. Popular choices include MetaMask, Trust Wallet,
              and MAAL Wallet.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">3. Fund Your Wallet: </span>{" "}
              Ensure your wallet has enough MAAL coins to cover the creation and
              listing fees, also known as "gas fees."
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                4. Connect Your Wallet to the PANSEA:{" "}
              </span>
              Connect your digital wallet to PANSEA. This typically involves
              authenticating your wallet's address and granting permission to
              the marketplace to access your wallet.{" "}
            </div>

            <div className="m-b-2">
              <span className="f-w-600  m-r-1">5. Create the NFT: </span> On the
              marketplace, look for an option to "create" or "mint" an NFT.
              Upload your digital file and fill in the details like name,
              description, and any additional properties or attributes.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">6. Set Sale Details:</span>{" "}
              Decide how you want to sell your NFT. You can set a fixed price,
              or opt for an auction-style sale. PANSEA also allows you to set
              royalties, enabling you to earn a percentage of sales whenever the
              NFT is sold to a new owner in the future.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">7. List the NFT for Sale:</span>{" "}
              Once you've set all the details, list your NFT for sale on the
              marketplace. This will likely involve a final confirmation and
              paying a gas fee for the minting process.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">8. Promote Your NFT:</span>{" "}
              Sharing your NFT on social media and other platforms can help
              attract potential buyers.
            </div>

            <div className="m-b-2">
              Creating and listing an NFT for sale is a relatively
              straightforward process, but it's important to consider the costs
              involved, such as gas fees, and to be aware of the rights and
              responsibilities that come with selling digital art on the
              blockchain.
            </div>
          </div>
          <div className="v-h">.</div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
