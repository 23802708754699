import { Backdrop, CircularProgress, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import propertiesimage from "../../Images/properties-icon.png";
import levelimage from "../../Images/level-icon.png";
import statsimage from "../../Images/stats-icon.png";
import Navbarnft_global from "../Navbars/Navbarnft_global";
import { isURL } from "validator";
import toast, { Toaster } from "react-hot-toast";
import { RxCross1 } from "react-icons/rx";
import uploadFile from "../../Apkss";

import { BiStats } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import {
  AiFillStar,
  AiOutlineCopy,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { useStoreState } from "easy-peasy";
import {
  GetChainId,
  convertToLowercase,
  rpcURL,
  utils_towei,
} from "../../Web3/web3_api";
import {
  get_Factory_contract,
  get_Marketplace_contract,
} from "../../Web3/Contract";
import { factory_Address, market_Place_Address } from "../../Web3/ABI";

const notify = (msg) => toast.success(msg);
const warning = (msg) => toast.error(msg);

function CreateNft({
  URL,
  UserWallet,
  IPFS_image_baseURL,
  signMessage,
  logout,
}) {
  const authorization = useStoreState((state) => state.authorization);
  const ipfs_Store_url = useStoreState((state) => state.ipfs_Store_url);

  const [selectedFile, setSelectedFile] = useState();

  const [open, setOpen] = React.useState(false);

  const [imagehash, setImageHash] = useState();
  const [imagehash1, setImageHash1] = useState();
  const [imagehash2, setImageHash2] = useState();
  const [imagehash3, setImageHash3] = useState();
  const [imagehash4, setImageHash4] = useState();
  const [imagehash_nft, setImageHash_nft] = useState();
  const [imagehash1_nft, setImageHash1_nft] = useState();
  const [imagehash2_nft, setImageHash2_nft] = useState();
  const [imagehash3_nft, setImageHash3_nft] = useState();
  const [imagehash4_nft, setImageHash4_nft] = useState();
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [imagehashBanner, setImageHashBanner] = useState();
  const [imagehashBanner_nft, setImageHashBanner_nft] = useState();
  const [bannerImage, setBannerImage] = useState("");

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const changeHandler1 = (event) => {
    setImage1(event.target.files[0]);
  };
  const changeHandler2 = (event) => {
    setImage2(event.target.files[0]);
  };
  const changeHandler3 = (event) => {
    setImage3(event.target.files[0]);
  };
  const changeHandler4 = (event) => {
    setImage4(event.target.files[0]);
  };

  const changeHandlerBanner = (event) => {
    setBannerImage(event.target.files[0]);
  };

  const handleSubmission = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setImageHash_nft(image);
      console.log("Upload response:", image);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmission_aws = async () => {
    const result = await uploadFile(selectedFile);
    setImageHash(result);
    console.log("result", result);
  };

  const handleSubmission1 = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image1);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setImageHash1_nft(image);
      console.log("Upload response:", image);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmission1_aws = async () => {
    const result = await uploadFile(image1);
    setImageHash1(result);
    console.log("result", result);
  };
  const handleSubmission2 = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image2);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setImageHash2_nft(image);
      console.log("Upload response:", image);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmission2_aws = async () => {
    const result = await uploadFile(image2);
    setImageHash2(result);
    console.log("result", result);
  };

  const handleSubmission3 = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image3);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setImageHash3_nft(image);
      console.log("Upload response:", image);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmission3_aws = async () => {
    const result = await uploadFile(image3);
    setImageHash3(result);
    console.log("result", result);
  };

  const handleSubmission4 = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image4);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setImageHash4_nft(image);
      console.log("Upload response:", image);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmission4_aws = async () => {
    const result = await uploadFile(image4);
    setImageHash4(result);
    console.log("result", result);
  };

  const handleSubmissionBanner = async () => {
    try {
      const formData = new FormData();
      formData.append("file", bannerImage);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setImageHashBanner_nft(image);
      console.log("Upload response:", image);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmissionBanner_aws = async () => {
    const result = await uploadFile(bannerImage);
    setImageHashBanner(result);
    console.log("result", result);
  };

  const [name, setName] = useState(" ");
  const [symbol, setSymbol] = useState();
  const [imagelink, setImageLink] = useState("");
  const [externalLink, setExternalLink] = useState("");
  const [discreption, setDiscription] = useState("");
  const [collection, setCollection] = useState();
  const [supply, setSupply] = useState(1);
  const [maxPerWallet, setMaxPerWallet] = useState(0);
  const [chain, setChain] = useState(5);
  const [mintingPrice, setMintingPrice] = useState(0);
  const [whitelisted, setWhitelisted] = useState(true);
  const [revealedMint, setRevealedMint] = useState(true);
  const [fee, setFee] = useState(0);
  const [royaltywallet, setRoyaltyWallet] = useState();
  const [userId, setUserID] = useState();
  const [categry, setCategry] = useState("Art");
  const [ownedNFTS, setOwndNFTs] = useState([]);
  const [factory, setFactory] = useState();

  useEffect(() => {
    const init = async () => {
      axios.get(`${URL}/airdropcollection`).then((res) => {
        setOwndNFTs(res.data);
      });
      const factory = await get_Factory_contract();
      setFactory(factory);
      const id = await GetChainId();
      setChain(id);
    };
    init();
  }, [UserWallet]);

  useEffect(() => {
    const init = async () => {
      axios
        .post(`${URL}/getuser`, {
          wallet: UserWallet,
        })
        .then((res) => {
          console.log("User Id ", res.data[0]._id);
          setUserID(res.data[0]._id);
        });
    };
    init();
  }, [UserWallet, open]);

  const Created_nfts = async () => {
    try {
      if (!UserWallet) {
        warning("Somthing went wrong");
        return;
      }
      if (externalLink == "" || !externalLink) {
        warning("BaseURI is not set");
        return;
      }
      if (
        !imagehashBanner ||
        !imagehash ||
        !imagehash1 ||
        !imagehash2 ||
        !imagehash3 ||
        !imagehash4
      ) {
        warning(
          "Some images was not uploaded, please upload the all images first"
        );
        return;
      }
      setOpen(true);
      const a = await utils_towei(mintingPrice);
      const marketplace = await get_Marketplace_contract();
      const rpc = rpcURL();
      const nftdata = await factory.methods
        .createNFT721(
          name,
          symbol,
          externalLink,
          supply,
          a,
          maxPerWallet,
          UserWallet,
          whitelisted,
          revealedMint
        )
        .send({ from: UserWallet });

      if (nftdata.status) {
        const collection_tx = await marketplace.methods
          .addCollection(fee, royaltywallet)
          .send({ from: UserWallet })
          .on("transactionHash", async function (Hash) {
            {
              // axios.post(`${URL}/makecollection`, {
              //   Creator: UserWallet,
              //   Creator_id: userId,
              //   fee: fee,
              //   bannerImage: `${imagehashBanner}`,
              //   logoImage: `${imagehash1}`,
              //   featureImage: `${imagehash}`,
              //   name: name,
              //   royalty_address: royaltywallet,
              //   description: discreption,
              //   catogry: categry,
              //   website: "",
              //   telegram: "",
              //   medium: "",
              //   chain: chain,
              //   hash:Hash,
              //   rpcUrl:rpc,
              //   marketplace:market_Place_Address
              // })
              // .then((res) => {
              //   notify("Collection Created");
              //   setOpen(false);
              // })
              // .catch((error) => {
              //   console.log(error);
              // });
            }

            await axios
              .post(`${URL}/makeairdrop`, {
                Creator: UserWallet.toLowerCase(),
                Collector: UserWallet.toLowerCase(),
                name: name,
                symbol: symbol,
                BannerImage: `${imagehashBanner ? imagehashBanner : ""}`,
                FeturedImage: `${imagehash ? imagehash : ""}`,
                imageOne: `${imagehash1 ? imagehash1 : ""}`,
                imageTwo: `${imagehash2 ? imagehash2 : ""}`,
                imageThree: `${imagehash3 ? imagehash3 : ""}`,
                imageFour: `${imagehash4 ? imagehash4 : ""}`,
                BannerImage_nft: `${
                  imagehashBanner_nft ? imagehashBanner_nft : ""
                }`,
                FeturedImage_nft: `${imagehash_nft ? imagehash_nft : ""}`,
                imageOne_nft: `${imagehash1_nft ? imagehash1_nft : ""}`,
                imageTwo_nft: `${imagehash2_nft ? imagehash2_nft : ""}`,
                imageThree_nft: `${imagehash3_nft ? imagehash3_nft : ""}`,
                imageFour_nft: `${imagehash4_nft ? imagehash4_nft : ""}`,
                baseURI: externalLink,
                maxPerWallet: maxPerWallet,
                supply: supply,
                mintPrice: mintingPrice,
                description: discreption,
                revealedMint: revealedMint,
                whitelisted: whitelisted,
                hash: Hash,
                rpcUrl: rpc,
                factory: factory_Address,
                marketplace: market_Place_Address,
                collectionsCreationDate: {
                  Creator: UserWallet,
                  Creator_id: userId,
                  fee: fee,
                  bannerImage: `${imagehashBanner}`,
                  logoImage: `${imagehash1}`,
                  featureImage: `${imagehash}`,
                  name: name,
                  royalty_address: royaltywallet,
                  description: discreption,
                  catogry: categry,
                  website: "",
                  telegram: "",
                  medium: "",
                  chain: chain,
                  hash: Hash,
                  rpcUrl: rpc,
                  marketplace: market_Place_Address,
                },
              })
              .then((res) => {
                console.log(res.data);
                setOpen(false);
                notify("NFT created successfully");
              })
              .catch((error) => {
                console.log(error);
                setOpen(false);
              });
          });
      }
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };

  const handleCopyClick = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        notify("copied");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <>
      <Navbarnft_global
        signMessage={signMessage}
        logout={logout}
        UserWallet={UserWallet}
        URL={URL}
      />
      <div className=" m-t-3">
        <div>
          <div className="row p-x-1 ">
            <div className="col-lg-6 col-md-8 col-sm-11 col-11 m-a">
              <div className="nft-switch d-f j-c-s-b a-i-c">
                <Typography
                  id="modal-modal-title"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="f-s-2 f-w-900"
                >
                  Create Drop NFT
                </Typography>
                <div className=" m-q-b-m-y-2">
                  <span className="g-c">NFTs</span> &nbsp;
                  <Link to="/createnft">
                    {" "}
                    <Switch
                      defaultChecked
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#FEC200",
                          color: "#FEC200",
                        },
                        "& .MuiSwitch": {
                          bgcolor: "#FEC200",
                          color: "#FEC200",
                        },
                      }}
                    />{" "}
                  </Link>{" "}
                  Create Drop NFTs
                </div>
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong style={{ color: "red" }}>*</strong>Required Fields
              </Typography>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Banner Image
                  </Typography>
                </div>
                <div>
                  <div className="row a-i-c">
                    <div
                      className={`col-12 ${
                        imagehashBanner ? "col-sm-6" : "col-sm-12"
                      }`}
                    >
                      {" "}
                      <div className="featured-img-box2 my-3">
                        <div className="fe-bo">
                          <Typography className="tag-image-name-drop">
                            <input type="file" onChange={changeHandlerBanner} />
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {imagehashBanner ? (
                      <div className="col-12 col-sm-6 m-y-2">
                        {" "}
                        <div>
                          <img
                            src={imagehashBanner}
                            className="w-7 h-7 b-r-20  "
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <button
                    className="save-bu22 t-a-r m-l-0"
                    onClick={() => {
                      handleSubmissionBanner();
                      handleSubmissionBanner_aws();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Fetaure Image
                  </Typography>
                </div>
                <div>
                  <div className="row a-i-c">
                    <div
                      className={`col-12 ${
                        imagehash ? "col-sm-6" : "col-sm-12"
                      }`}
                    >
                      {" "}
                      <div className="featured-img-box2 my-3">
                        <div className="fe-bo">
                          <Typography className="tag-image-name-drop">
                            <input type="file" onChange={changeHandler} />
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {imagehash ? (
                      <div className="col-12 col-sm-6 m-y-2">
                        {" "}
                        <div>
                          <img src={imagehash} className="w-7 h-7 b-r-20  " />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <button
                    className="save-bu22 t-a-r m-l-0"
                    onClick={() => {
                      handleSubmission();
                      handleSubmission_aws();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Image One
                  </Typography>
                </div>
                <div>
                  <div className="row a-i-c">
                    <div
                      className={`col-12 ${
                        imagehash1 ? "col-sm-6" : "col-sm-12"
                      }`}
                    >
                      {" "}
                      <div className="featured-img-box2 my-3">
                        <div className="fe-bo">
                          <Typography className="tag-image-name-drop">
                            <input type="file" onChange={changeHandler1} />
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {imagehash1 ? (
                      <div className="col-12 col-sm-6 m-y-2">
                        {" "}
                        <div>
                          <img src={imagehash1} className="w-7 h-7 b-r-20  " />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <button
                    className="save-bu22 t-a-r m-l-0"
                    onClick={() => {
                      handleSubmission1();
                      handleSubmission1_aws();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Image Two
                  </Typography>
                </div>
                <div>
                  <div className="row a-i-c">
                    <div
                      className={`col-12 ${
                        imagehash2 ? "col-sm-6" : "col-sm-12"
                      }`}
                    >
                      {" "}
                      <div className="featured-img-box2 my-3">
                        <div className="fe-bo">
                          <Typography className="tag-image-name-drop">
                            <input type="file" onChange={changeHandler2} />
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {imagehash2 ? (
                      <div className="col-12 col-sm-6 m-y-2">
                        {" "}
                        <div>
                          <img src={imagehash2} className="w-7 h-7 b-r-20  " />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <button
                    className="save-bu22 t-a-r m-l-0"
                    onClick={() => {
                      handleSubmission2();
                      handleSubmission2_aws();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Fetaure Three
                  </Typography>
                </div>
                <div>
                  <div className="row a-i-c">
                    <div
                      className={`col-12 ${
                        imagehash3 ? "col-sm-6" : "col-sm-12"
                      }`}
                    >
                      {" "}
                      <div className="featured-img-box2 my-3">
                        <div className="fe-bo">
                          <Typography className="tag-image-name-drop">
                            <input type="file" onChange={changeHandler3} />
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {imagehash3 ? (
                      <div className="col-12 col-sm-6 m-y-2">
                        {" "}
                        <div>
                          <img src={imagehash3} className="w-7 h-7 b-r-20  " />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <button
                    className="save-bu22 t-a-r m-l-0"
                    onClick={() => {
                      handleSubmission3();
                      handleSubmission3_aws();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Image Four
                  </Typography>
                </div>
                <div>
                  <div className="row a-i-c">
                    <div
                      className={`col-12 ${
                        imagehash4 ? "col-sm-6" : "col-sm-12"
                      }`}
                    >
                      {" "}
                      <div className="featured-img-box2 my-3">
                        <div className="fe-bo">
                          <Typography className="tag-image-name-drop">
                            <input type="file" onChange={changeHandler4} />
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {imagehash4 ? (
                      <div className="col-12 col-sm-6 m-y-2">
                        {" "}
                        <div>
                          <img src={imagehash4} className="w-7 h-7 b-r-20  " />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <button
                    className="save-bu22 t-a-r m-l-0"
                    onClick={() => {
                      handleSubmission4();
                      handleSubmission4_aws();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Name <strong style={{ color: "red" }}>*</strong>
                  </Typography>
                  <input
                    type="text"
                    placeholder="Item name"
                    className="recordion-in"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Symbol <strong style={{ color: "red" }}>*</strong>
                  </Typography>
                  <input
                    type="text"
                    placeholder="Item name"
                    className="recordion-in"
                    value={symbol}
                    onChange={(e) => setSymbol(e.target.value)}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">BaseURI</Typography>
                  {/* <Typography className="content-form f-s-0_8">
                    This URL will be included on this item’s detail page, so
                    that users can click to learn more about it. You are welcome
                    to link your own webpage with more details
                  </Typography> */}
                  <input
                    type="url"
                    placeholder="https://website.io/item/123"
                    className="recordion-in my-3"
                    value={externalLink}
                    onChange={(e) => setExternalLink(e.target.value)}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Description
                  </Typography>
                  {/* <Typography className="content-form f-s-0_8">
                    Provide a detailed description about your item and we will
                    show it underneath its image/gif.&nbsp;
                    <strong style={{ color: "#FEC200" }}>Markdown </strong>{" "}
                    syntax is supported.
                  </Typography> */}
                  <textarea
                    className="text-area-mycollections my-4"
                    placeholder="Tell us something about of your item."
                    value={discreption}
                    onChange={(e) => setDiscription(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Royalty Fee
                  </Typography>

                  <input
                    placeholder="fee"
                    value={fee}
                    className="recordion-in my-3"
                    onChange={(e) => setFee(e.target.value)}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Royalty Receiver Wallet
                  </Typography>

                  <input
                    placeholder="wallet address"
                    value={royaltywallet}
                    className="recordion-in my-3"
                    onChange={(e) => setRoyaltyWallet(e.target.value)}
                  />
                </div>
              </div>

              {/* <div className="section-collection">
                <Typography className="highligh-section">Collection</Typography>
                <Typography className="content-form mb-3 f-s-0_8">
                  Choose a collection where you want your item to appear.
                </Typography>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setCollection(e.target.value)}
                  >
                    <option value="null">SELECT</option>
                    {ownedNFTS &&
                      ownedNFTS.map((res) => {
                        return (
                          <option value={`${res.Collection_id}`}>
                            {res.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>
              </div> */}

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">Supply</Typography>
                  <Typography className="content-form f-s-0_8">
                    The number of items that can be minted. No gas cost to you!
                  </Typography>

                  <input
                    placeholder="0"
                    className="recordion-in mt-3"
                    value={supply}
                    onChange={(e) => setSupply(e.target.value)}
                  />
                </div>
                <br />
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Max per wallet
                  </Typography>
                  <Typography className="content-form f-s-0_8">
                    0 for upto Max Supply
                  </Typography>
                  <input
                    placeholder="0"
                    className="recordion-in mt-3"
                    value={maxPerWallet}
                    onChange={(e) => setMaxPerWallet(e.target.value)}
                  />
                </div>
                <br />
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Minting Price
                  </Typography>
                  {/* <Typography className="content-form f-s-0_8">
                    The number of items that can be minted. No gas cost to you!
                  </Typography> */}

                  <input
                    placeholder="0"
                    className="recordion-in mt-3"
                    value={mintingPrice}
                    onChange={(e) => setMintingPrice(e.target.value)}
                  />
                </div>

                <br />
              </div>
              <div className="section-collection m-t-1">
                <div className=" m-q-b-m-y-2">
                  <span className="g-c">Whitelisted</span>{" "}
                  <Switch
                    onClick={() => setWhitelisted(!whitelisted)}
                    defaultChecked
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: "#FEC200",
                        color: "#FEC200",
                      },
                      "& .MuiSwitch": {
                        bgcolor: "#FEC200",
                        color: "#FEC200",
                      },
                    }}
                  />
                </div>

                <div className=" m-q-b-m-y-2">
                  <span className="g-c">Revealed on minting</span>{" "}
                  <Switch
                    onClick={() => setRevealedMint(!revealedMint)}
                    defaultChecked
                    sx={{
                      "& .MuiSwitch-thumb": {
                        bgcolor: "#FEC200",
                        color: "#FEC200",
                      },
                      "& .MuiSwitch": {
                        bgcolor: "#FEC200",
                        color: "#FEC200",
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 col-sm-12">
              <div className="button-section-nft">
                <button className="save-bu">Save as draft</button>
                <button
                  className="save-bu22 m-b-7"
                  onClick={() => Created_nfts()}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default CreateNft;
