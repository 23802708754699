import React from "react";
import "./Profilesettingslide.css";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import Featuredvector from "../../Images/Vector.png";
import { Link } from "react-router-dom";
import Navbarnft from "../Navbars/Navbarnft";
import Footer from "../Footer";
import "./Offers.css";

// IMPORT
import Imgside1 from "./Imagesprofile/Rectangle 7.png";
import Navbarnft_global from "../Navbars/Navbarnft_global";
import Imgside2 from "./Imagesprofile/Rectangle 721.png";
import Imgside3 from "./Imagesprofile/Rectangle 723.png";
import no_data from "../../Images/no_data.jpg";

// INPUT IMPORT FILE
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Eth from "./Imagesprofile/Ellipse 124.png";

export default function Profilesettingslide() {
  return (
    <>
      <Navbarnft_global URL={URL} />
      <div className="container-fluid "></div>
      <div>
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-2 col-md-12 col-sm-12 col-12 b-r-1 b-r-0 ">
              <div>
                <ul className="listed-section m-t-3">
                  <span className="setting-sections p-t-3 f-w-600">
                    Setting
                  </span>
                  <Link to="/profilesetting">
                    <li className="section-list">
                      <span>
                        <PermIdentityOutlinedIcon className="icons-tabs" />
                      </span>{" "}
                      <span className="content-style">Profile</span>
                    </li>
                  </Link>

                  <Link to="/offers">
                    {" "}
                    <li className="section-list  profile-setting b-r-10">
                      <span>
                        <LocalOfferOutlinedIcon className="icons-tabs" />
                      </span>
                      <span className="content-style">Offers</span>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>

            <div className="col-lg-10  col-md-12 col-sm-12 col-12   h-90vh o-a notification-padding notification-padding">
              <div className="section-offers ">
                <h1 className="profile-setting-heading">Offer settings</h1>
                <p className="set-para">
                  Set a minimum after for collective to ignore low offers.
                </p>
              </div>

              <div className=" ">
                <img src={no_data} className="t-a-c w-15" />
              </div>
              {/* <button className="offer-button m-t-1 m-b-2">
                View my offers
              </button> */}
              {/* <p className="offer11021">View my offers</p> */}
              {/* <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                  <div className="row sser">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="section-offer">
                          <img src={Imgside1} alt="" />
                          <span className="p-l-0_5 p-t-0_5">
                            <p className="ppo">3 items</p>
                            <p className="ppo10 m-y-1">Cloud Ball</p>
                            <p className="ppo">Floor price: 0 ETH</p>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <InputGroup className="mb-3 box-create">
                          <InputGroup.Text id="basic-addon1" className="hhus">
                            <img src={Eth} alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="jjjdd"
                          />
                        </InputGroup>
                        <div>
                          <p className="usd">$ 0 USD</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="section-offer">
                          <img src={Imgside2} alt="" />
                          <span className="p-l-0_5 p-t-0_5">
                            <p className="ppo">134 items</p>
                            <p className="ppo10 m-y-1">Loop_1k</p>
                            <p className="ppo">Floor price: 0.12 ETH</p>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div>
                          <InputGroup className="mb-3 box-create">
                            <InputGroup.Text id="basic-addon1" className="hhus">
                              <img src={Eth} alt="" />
                            </InputGroup.Text>
                            <Form.Control
                              placeholder="0"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              className="jjjdd"
                            />
                          </InputGroup>
                          <div>
                            <p className="usd">$ 0 USD</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="section-offer">
                          <img src={Imgside3} alt="" />
                          <span className="p-l-0_5 p-t-0_5">
                            <p className="ppo">3 items</p>
                            <p className="ppo10 m-y-1">hindsight</p>
                            <p className="ppo">Floor price: 0.12 ETH</p>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <InputGroup className="mb-3 box-create">
                          <InputGroup.Text id="basic-addon1" className="hhus">
                            <img src={Eth} alt="" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="jjjdd"
                          />
                        </InputGroup>
                        <div>
                          <p className="usd">$ 0 USD</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offer-bu m-b-3">
                    <button className="offer-save">Save</button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
