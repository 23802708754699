import { Backdrop, CircularProgress, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import propertiesimage from "../../Images/properties-icon.png";
import levelimage from "../../Images/level-icon.png";
import statsimage from "../../Images/stats-icon.png";
import Navbarnft_global from "../Navbars/Navbarnft_global";
import { isURL } from "validator";
import toast, { Toaster } from "react-hot-toast";
import { RxCross1 } from "react-icons/rx";
import { BiStats } from "react-icons/bi";
import uploadFile from "../../Apkss";
import { Link, useParams } from "react-router-dom";
import {
  AiFillStar,
  AiOutlineCopy,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { useStoreState } from "easy-peasy";
import { GetChainId, convertToLowercase, rpcURL } from "../../Web3/web3_api";
import { get_Factory_contract } from "../../Web3/Contract";
import { factory_Address } from "../../Web3/ABI";

const notify = (msg) => toast.success(msg);
const warning = (msg) => toast.error(msg);

function CreateNft({
  URL,
  UserWallet,
  IPFS_image_baseURL,
  signMessage,
  logout,
}) {
  const [open50, setOpen50] = React.useState(false);
  const handleOpen50 = () => setOpen50(true);
  const handleClose50 = () => setOpen50(false);

  const authorization = useStoreState((state) => state.authorization);
  const ipfs_Store_url = useStoreState((state) => state.ipfs_Store_url);

  const [selectedFile, setSelectedFile] = useState();

  const [description, setDescription] = useState(" ");

  const [show1, setShow1] = useState(false);
  const [nft, setNft] = useState([]);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [open, setOpen] = React.useState(false);
  const { isvalidator } = useParams();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showproperties, setShowproperties] = useState(false);

  const handleCloseproperties = () => setShowproperties(false);
  const handleShowproperties = () => setShowproperties(true);
  const [showlevel, setShowlevel] = useState(false);

  const handleCloselevel = () => setShowlevel(false);
  const handleShowlevel = () => setShowlevel(true);

  const [showstats, setShowstats] = useState(false);

  const handleClosestats = () => setShowstats(false);
  const handleShowstats = () => setShowstats(true);

  const [imagehash, setImageHash] = useState();
  const [imagehash_nft, setImageHash_nft] = useState();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload_aws = async () => {
    const result = await uploadFile(file);
    setImageHash(result);
    console.log("result", result);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`, // Replace with your actual API key
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      console.log("Upload response:", image);
      setImageHash_nft(image);
      // Use the result directly here
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // const handleSubmission = async () => {
  //   const formData = new FormData();
  //   formData.append("file", selectedFile);
  //   try {
  //     const ipfs = ipfsHttpClient({
  //       url: ipfs_Store_url,
  //       headers: {
  //         authorization,
  //       },
  //     });
  //     const response = await ipfs.add(selectedFile);
  //     console.log(response.path);
  //     setImageHash(response.path);
  //     return true;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState();
  const [imagelink, setImageLink] = useState("");
  const [externalLink, setExternalLink] = useState("");
  const [discreption, setDiscription] = useState("");
  const [collection, setCollection] = useState();
  const [collectionName, setCollectionName] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [supply, setSupply] = useState(1);
  const [chain, setChain] = useState(5);
  const [ids1155, setIDs] = useState([new Date().getTime()]);
  const [amounts1155, setAmounts] = useState();
  const [to721, setToaddress] = useState();
  const [id721, setTokenID] = useState();
  const [nftType, setNetType] = useState(1155);

  const [properties, setProperties] = useState([0]);
  const [propertieObject, setPropertieObject] = useState([{}]);

  const [level, setLevel] = useState([0]);
  const [levelObject, setLevelObject] = useState([{}]);

  const [state, setState] = useState([0]);
  const [stateObject, setStateObject] = useState([{}]);
  const [ownedNFTS, setOwndNFTs] = useState([]);
  const [factory, setFactory] = useState();

  useEffect(() => {
    const init = async () => {
      // console.log(UserWallet)
      axios
        .post(`${URL}/collectionbycreateor`, {
          wallet: UserWallet,
        })
        .then((res) => {
          setOwndNFTs(res.data);
          console.log(res.data);
        });

      const factory = await get_Factory_contract();
      setFactory(factory);
      const id = await GetChainId();
      setChain(id);
    };
    init();
  }, [UserWallet]);

  const Created_nfts = async () => {
    try {
      if (!UserWallet) {
        warning("Somthing went wrong");
        return;
      }
      if (!imagehash) {
        warning("Image is not uploaded, please upload the image");
        return;
      }
      if (externalLink != "" && !isURL(externalLink)) {
        warning("Invalid URL");
        return;
      }
      if (!ownedNFTS && ownedNFTS.length > 0) {
        warning("Please create Collection first");
        return;
      }
      if (!collection || collection === "null") {
        warning("invalid collection");
        return;
      }

      const arrtribute = [];
      for (let i = 0; i < propertieObject.length; i++) {
        if (propertieObject[i].type && propertieObject[i].value) {
          setAttributes((attributes) => [...attributes, propertieObject[i]]);
          arrtribute.push(propertieObject[i]);
        }
      }

      for (let i = 0; i < levelObject.length; i++) {
        if (levelObject[i].type && levelObject[i].value) {
          setAttributes((attributes) => [...attributes, levelObject[i]]);
          arrtribute.push(levelObject[i]);
        }
      }

      for (let i = 0; i < stateObject.length; i++) {
        if (stateObject[i].type && stateObject[i].value) {
          setAttributes((attributes) => [...attributes, stateObject[i]]);
          arrtribute.push(stateObject[i]);
        }
      }
      setOpen(true);
      const metadatauri = await axios
        .post(`${URL}/metadataupload`, {
          name: name,
          imageURL: imagehash,
          imageURL_nft: imagehash_nft,
          attributes: arrtribute,
          description: discreption,
        })
        .then((res) => {
          console.log(res.data);
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          return undefined;
        });
      const id = new Date().getTime(); // setting the current time as token id
      if (metadatauri) {
        const rpc = rpcURL();
        const nftdata = await factory.methods
          .createNFT1155(name, symbol, id, supply, metadatauri)
          .send({ from: UserWallet })
          .on("transactionHash", async function (hash) {
            await axios
              .post(`${URL}/makenfts1155`, {
                Creator: UserWallet.toLowerCase(),
                Owner: UserWallet.toLowerCase(),
                name: name,
                symbol: symbol,
                imageURL: imagehash,
                collectionID: collection,
                tokenID: id,
                attributes: arrtribute,
                metadata: metadatauri,
                last_update: new Date().getTime(),
                supply: supply,
                to721: to721,
                chain: chain,
                description: discreption,
                externalLink: externalLink,
                nftType: 1155,
                isNFTMinted: true,
                hash: hash,
                rpcUrl: rpc,
                factory: factory_Address,
              })
              .then((res) => {
                setOpen(false);
                notify("NFT created successfully");
                window.location.replace(
                  `collection/${collectionName}/${collection}`
                );
              })
              .catch((error) => {
                console.log(error);
              });
          });
      } else {
        setOpen(false);
        warning("Could not upload MetaData, please try again later");
        return;
      }
    } catch (error) {
      setOpen(false);
      console.log(error);
    }
  };

  const handleSupply = (tab) => {
    if (Number(tab) === 721) {
      setNetType(Number(tab));
    } else if (Number(tab) === 1155) {
      setNetType(Number(tab));
    }
  };

  const updateFieldChangedType = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...propertieObject];
    newArr[index].type = e.target.value;
    setPropertieObject(newArr);
  };

  const updateFieldChangedValue = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...propertieObject];
    newArr[index].value = e.target.value;
    setPropertieObject(newArr);
  };

  const updateFieldChangedTypeforLevel = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...levelObject];
    newArr[index].type = e.target.value;
    setLevelObject(newArr);
  };

  const updateFieldChangedValueforLevel = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...levelObject];
    newArr[index].value = e.target.value;
    setLevelObject(newArr);
  };

  const updateFieldChangedTypeforState = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...stateObject];
    newArr[index].type = e.target.value;
    setStateObject(newArr);
  };

  const updateFieldChangedValueforState = (index, e) => {
    // console.log('index: ' + index);
    // console.log('property name: '+ e.target.name);
    let newArr = [...stateObject];
    newArr[index].value = e.target.value;
    setStateObject(newArr);
  };

  const handleCopyClick = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        notify("copied");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  console.log("Collection id and name", collectionName, collection);

  return (
    <>
      <Navbarnft_global
        signMessage={signMessage}
        logout={logout}
        UserWallet={UserWallet}
        URL={URL}
      />
      <div className=" m-t-3">
        <div>
          <div className="row p-x-1 ">
            <div className="col-lg-6 col-md-8 col-sm-11 col-11 m-a">
              <div className="nft-switch d-f j-c-s-b a-i-c">
                <Typography
                  id="modal-modal-title"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="f-s-2 f-w-900"
                >
                  Create an NFT
                </Typography>
                <div className="m-q-b-m-y-2">
                  <span className="">NFTs</span> &nbsp;
                  <Link to="/createDropNft">
                    <Switch />
                  </Link>
                  <span className="g-c"> Create Drop NFTs</span>
                </div>
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong style={{ color: "red" }}>*</strong>Required Fields
              </Typography>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Image or 3D model
                  </Typography>

                  <Typography className="content-form f-s-0_8">
                    File types supported: JPG, PNG, GIF, SVG, WEBM, WAV, GLB,
                    GLTF. Max size: 100 MB
                  </Typography>
                </div>
                <div>
                  <div className="row a-i-c">
                    <div
                      className={`col-12 ${
                        imagehash ? "col-sm-6" : "col-sm-12"
                      }`}
                    >
                      {" "}
                      <div className="featured-img-box2 my-3">
                        <div className="fe-bo">
                          <Typography className="tag-image-name-drop">
                            <input type="file" onChange={handleFileChange} />
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {imagehash ? (
                      <div className="col-12 col-sm-6 m-y-2">
                        {" "}
                        <div>
                          <img
                            src={`${imagehash}`}
                            className="w-7 h-7 b-r-20  "
                          />

                          {/*  Image hash: {imagehash.slice(-10)}{" "}
                    <AiOutlineCopy
                      onClick={() => handleCopyClick(imagehash)}
                    />  */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <button
                    className="save-bu22 t-a-r m-l-0"
                    onClick={() => {
                      handleUpload();
                      handleUpload_aws();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Name <strong style={{ color: "red" }}>*</strong>
                  </Typography>
                  <input
                    type="text"
                    placeholder="Item name"
                    className="recordion-in"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Symbol <strong style={{ color: "red" }}>*</strong>
                  </Typography>
                  <input
                    type="text"
                    placeholder="Item name"
                    className="recordion-in"
                    value={symbol}
                    onChange={(e) => setSymbol(e.target.value)}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    External link
                  </Typography>
                  <Typography className="content-form f-s-0_8">
                    This URL will be included on this item’s detail page, so
                    that users can click to learn more about it. You are welcome
                    to link your own webpage with more details
                  </Typography>
                  <input
                    type="url"
                    placeholder="https://website.io/item/123"
                    className="recordion-in my-3"
                    value={externalLink}
                    onChange={(e) => setExternalLink(e.target.value)}
                  />
                </div>
              </div>
              <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Description
                  </Typography>
                  <Typography className="content-form f-s-0_8">
                    Provide a detailed description about your item and we will
                    show it underneath its image/gif.&nbsp;
                    <strong style={{ color: "#FEC200" }}>Markdown </strong>{" "}
                    syntax is supported.
                  </Typography>
                  <textarea
                    className="text-area-mycollections my-4"
                    placeholder="Tell us something about of your item."
                    value={discreption}
                    onChange={(e) => setDiscription(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="section-collection">
                <Typography className="highligh-section">Collection</Typography>
                <Typography className="content-form mb-3 f-s-0_8">
                  Choose a collection where you want your item to appear.
                </Typography>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => {
                      const selectedValue = JSON.parse(e.target.value); // Parse the value to access properties
                      if (selectedValue) {
                        setCollection(selectedValue.id); // Set the ID from the parsed value
                        setCollectionName(selectedValue.name); // Set the name from the parsed value
                      }
                    }}
                  >
                    <option value="null">SELECT</option>
                    {ownedNFTS &&
                      ownedNFTS.map((res) => {
                        return (
                          <option
                            value={JSON.stringify({
                              name: res.name,
                              id: res.Collection_id,
                            })}
                          >
                            {res.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>
              </div>
              <div className="section-collection">
                <div className="d-f j-c-s-b">
                  <div className="d-f ">
                    <div className="">
                      <AiOutlineUnorderedList className="m-t-1 m-r-1 f-s-1_5" />
                    </div>
                    <div className="">
                      <Typography className="highligh-section">
                        Properties
                      </Typography>

                      <Typography className="content-form f-s-0_8">
                        Textual traits that show up as rectangles
                      </Typography>
                    </div>
                  </div>
                  <div className="">
                    <Button
                      variant="primary"
                      onClick={handleShowproperties}
                      className="cate-bu my-3 p-x-1 p-y-0_5 b-1"
                    >
                      +
                    </Button>

                    <Modal
                      show={showproperties}
                      onHide={handleCloseproperties}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header>
                        <Modal.Title>Add Properties</Modal.Title>
                        <RxCross1
                          className="c-p"
                          onClick={handleCloseproperties}
                        />
                      </Modal.Header>
                      <Modal.Body>
                        <div className="m-y-1">
                          Properties show up underneath your item, are
                          clickable, and can be filtered in your collection's
                          sidebar.
                        </div>
                        <div className="row m-y-1">
                          <div className="col">
                            <div className="f-w-600">Type</div>
                          </div>
                          <div className="col">
                            {" "}
                            <div className="f-w-600">Value</div>
                          </div>
                        </div>

                        {properties.map((res) => {
                          return (
                            <div className="row m-y-1">
                              <div className="col">
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Character"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={
                                      propertieObject[properties.indexOf(res)]
                                        .type
                                    }
                                    onChange={(e) => {
                                      updateFieldChangedType(
                                        properties.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                {" "}
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Male"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={
                                      propertieObject[properties.indexOf(res)]
                                        .value
                                    }
                                    onChange={(e) => {
                                      updateFieldChangedValue(
                                        properties.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="m-y-1">
                          <button
                            className="save-bu22 m-l-0 pa-0_5 p-x-2"
                            onClick={() => {
                              setPropertieObject((propertieObject) => [
                                ...propertieObject,
                                {},
                              ]);
                              setProperties((properties) => [
                                ...properties,
                                properties.length,
                              ]);
                            }}
                          >
                            Add More
                          </button>
                        </div>
                        <div className="m-y-1">
                          <span>
                            <button
                              className="save-bu22 w-100 m-l-0"
                              onClick={handleCloseproperties}
                            >
                              Save
                            </button>
                          </span>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>

              <div className="section-collection">
                <div className="d-f j-c-s-b">
                  <div className="d-f ">
                    <div className="">
                      <AiFillStar className="m-t-1 m-r-1 f-w-1_5" />
                    </div>
                    <div className="">
                      <Typography className="highligh-section">
                        Levels
                      </Typography>

                      <Typography className="content-form f-s-0_8">
                        Numerical traits that shows up as a progress bar
                      </Typography>
                    </div>
                  </div>
                  <div className="">
                    <Button
                      variant="primary"
                      onClick={handleShowlevel}
                      className="cate-bu my-3 p-x-1 p-y-0_5 b-1"
                    >
                      +
                    </Button>

                    <Modal
                      show={showlevel}
                      onHide={handleCloselevel}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header>
                        <Modal.Title>Add Level</Modal.Title>
                        <RxCross1 className="c-p" onClick={handleCloselevel} />
                      </Modal.Header>
                      <Modal.Body>
                        <div className="m-y-1">
                          level show up underneath your item, are clickable, and
                          can be filtered in your collection's sidebar.
                        </div>
                        <div className="row m-y-1">
                          <div className="col">
                            <div className="f-w-600">Type</div>
                          </div>
                          <div className="col">
                            {" "}
                            <div className="f-w-600">Value</div>
                          </div>
                        </div>

                        {level.map((res) => {
                          return (
                            <div className="row m-y-1">
                              <div className="col">
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Character"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={levelObject[level.indexOf(res)].type}
                                    onChange={(e) => {
                                      updateFieldChangedTypeforLevel(
                                        level.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                {" "}
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Male"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={
                                      levelObject[level.indexOf(res)].value
                                    }
                                    onChange={(e) => {
                                      updateFieldChangedValueforLevel(
                                        level.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <div className="m-y-1">
                          <button
                            className="save-bu22 m-l-0 pa-0_5 p-x-2"
                            onClick={() => {
                              setLevelObject((levelObject) => [
                                ...levelObject,
                                {},
                              ]);
                              setLevel((level) => [...level, level.length]);
                            }}
                          >
                            Add More
                          </button>
                        </div>
                        <div className="m-y-1">
                          <button
                            className="save-bu22 w-100 m-l-0"
                            onClick={handleCloselevel}
                          >
                            Save
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>

              <div className="section-collection">
                <div className="d-f j-c-s-b">
                  <div className="d-f ">
                    <div className="">
                      <BiStats className="m-t-1 m-r-1 f-s-1_5" />
                    </div>
                    <div className="">
                      <Typography className="highligh-section">
                        Stats
                      </Typography>

                      <Typography className="content-form f-s-0_8">
                        Numerical traits that shows up as a progress bar
                      </Typography>
                    </div>
                  </div>
                  <div className="">
                    <Button
                      variant="primary"
                      onClick={handleShowstats}
                      className="cate-bu my-3 p-x-1 p-y-0_5 b-1"
                    >
                      +
                    </Button>

                    <Modal
                      show={showstats}
                      onHide={handleClosestats}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header>
                        <Modal.Title>Add Stats</Modal.Title>
                        <RxCross1 className="c-p" onClick={handleClosestats} />
                      </Modal.Header>

                      <Modal.Body>
                        <div className="m-y-1">
                          Properties show up underneath your item, are
                          clickable, and can be filtered in your collection's
                          sidebar.
                        </div>
                        <div className="row m-y-1">
                          <div className="col">
                            <div className="f-w-600">Type</div>
                          </div>
                          <div className="col">
                            {" "}
                            <div className="f-w-600">Value</div>
                          </div>
                        </div>

                        {state.map((res) => {
                          return (
                            <div className="row m-y-1">
                              <div className="col">
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Character"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={stateObject[state.indexOf(res)].type}
                                    onChange={(e) => {
                                      updateFieldChangedTypeforState(
                                        state.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                {" "}
                                <div className="">
                                  <input
                                    type="text"
                                    placeholder="Male"
                                    className="w-100 b-r-5 pa-0_5 b-1"
                                    value={
                                      stateObject[state.indexOf(res)].value
                                    }
                                    onChange={(e) => {
                                      updateFieldChangedValueforState(
                                        state.indexOf(res),
                                        e
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <div className="m-y-1">
                          <button
                            className="save-bu22 m-l-0 pa-0_5 p-x-2"
                            onClick={() => {
                              setStateObject((stateObject) => [
                                ...stateObject,
                                {},
                              ]);
                              setState((state) => [...state, state.length]);
                            }}
                          >
                            Add More
                          </button>
                        </div>
                        <div className="m-y-1">
                          <button
                            className="save-bu22 w-100 m-l-0"
                            onClick={handleClosestats}
                          >
                            Save
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="section-collection">
                {
                  <div>
                    <Typography className="highligh-section">Supply</Typography>
                    <Typography className="content-form f-s-0_8">
                      The number of items that can be minted. No gas cost to
                      you!
                    </Typography>
                    {/* <Form.Select
                      aria-label="Default select example"
                      className="mt-3"
                      onChange={(e) => handleSupply(e.target.value)}
                      disabled
                    >
                      <option value="721">MAAL721</option>
                      <option value="1155">MAAL1155</option>
                    </Form.Select> */}
                    {true ? (
                      <input
                        placeholder="0"
                        className="recordion-in mt-3"
                        value={supply}
                        onChange={(e) => setSupply(e.target.value)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                }
                <br />
                {/* {nftType === 1155 ? (
                  <div>
                    <Typography className="highligh-section">{" "}</Typography>
                    <Typography className="content-form f-s-0_8">
                      Enter ID and Amounts seprated by comas (ex 1,2,3,4)
                    </Typography>
                    <input
                        placeholder="ids"
                        className="recordion-in mt-3"
                    
                        onChange={(e) =>{
                          const value = e.target.value
                          setIDs(value.split(','))
                        }}
                      />
                    <input
                        placeholder="amounts"
                        className="recordion-in mt-3"
                        onChange={(e) =>{
                          const value = e.target.value
                          setAmounts(value.split(','))
                        }}
                      />
                  </div>
                ) : (
                  <div>
                    <Typography className="highligh-section"> </Typography>
                    <Typography className="content-form f-s-0_8">
                      Enter the receiver address and token ID
                    </Typography>
                    <input
                      placeholder="receiver"
                      className="recordion-in mt-3"
                      onChange={(e) =>
                        setToaddress(convertToLowercase(e.target.value))
                      }
                    />
                    <input
                      placeholder="tokenID"
                      className="recordion-in mt-3"
                      onChange={(e) => setTokenID(e.target.value)}
                    />
                  </div>
                )} */}
              </div>

              {/* <div>
                <div className="section-collection">
                  <Typography className="highligh-section">
                    Blockchain
                  </Typography>

                  <div>
                    <Form.Select
                      aria-label="Default select example"
                      className="mt-3"
                      onChange={(e) => setChain(e.target.value)}
                      
                    >
                      <option value="880">MAAL TESTNET</option>
                      <option value="786">MAAL MAINNET</option>
                    </Form.Select>
                  </div>
                </div>
              </div> */}
              {/* <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Symbol <strong style={{ color: "red" }}>*</strong>
                  </Typography>
                  <input
                    type="text"
                    placeholder="Item name"
                    className="recordion-in"
                    value={symbol}
                    onChange={(e) => setSymbol(e.target.value)}
                  />
                </div>
              </div> */}
              {/* <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Symbol <strong style={{ color: "red" }}>*</strong>
                  </Typography>
                  <input
                    type="text"
                    placeholder="Item name"
                    className="recordion-in"
                    value={symbol}
                    onChange={(e) => setSymbol(e.target.value)}
                  />
                </div>
              </div> */}
              {/* <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Symbol <strong style={{ color: "red" }}>*</strong>
                  </Typography>
                  <input
                    type="text"
                    placeholder="Item name"
                    className="recordion-in"
                    value={symbol}
                    onChange={(e) => setSymbol(e.target.value)}
                  />
                </div>
              </div> */}

              {/* <div className="section-collection">
                <div>
                  <Typography className="highligh-section">
                    Freeze Metadata
                  </Typography>
                  <Typography className="content-form f-s-0_8">
                    Freezing your metadata will allow you to permanently lock
                    and store all of this item’s content in decentralized file
                    storage.
                  </Typography>
                </div>
              </div> */}
              {/*       <div className="section-collection">
         <div className="row">
           <div className="col-8">
             <Typography className="highligh-section">
               Explicit & sensitive content
             </Typography>
             <Typography className="content-form f-s-0_8">
               Set this collections as explicit & sensitive
               content
             </Typography>
           </div>
           <div className="col-4">
             <span className="ios-check-button">
               <FormControlLabel
                 control={
                   <IOSSwitch
                     sx={{ m: 1 }}
                     defaultChecked
                   />
                 }
               />
             </span>
           </div>
         </div>
       </div> */}
              {/* <div className="section-collection ">
                <div className="pa-0_5 p-l-1  b-r-10 p-l-0">
                  To freeze metadata, you must create an item first.
                </div>
              </div> */}
            </div>

            <div className="col-md-12 col-sm-12">
              <div className="button-section-nft">
                <button className="save-bu">Save as draft</button>
                <button
                  className="save-bu22 m-b-7"
                  onClick={() => Created_nfts()}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default CreateNft;
