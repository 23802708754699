export const SelectTier =(tier, id)=>{
    try {
        if(tier == 1){
           
            if(id >= 1 && id <= 10){
                return id
            }
            return "Incompatible tier for this ID"

        }
        else if(tier == 2){
            if(id >= 11 && id <= 50){
                return id
            }
            return "Incompatible tier for this ID"
        }
        else if(tier == 3){
            if(id >= 51 && id <= 150){
                return id
            }
            return "Incompatible tier for this ID"
        }
        else{
            return "Invalid ID"
        }
    } catch (error) {
        
    }
}