import React from "react";
import Help_sidebar from "./Help_sidebar";
import Navbarnft_global from "../Navbars/Navbarnft_global";

function Buy() {
  return (
    <div>
      <Navbarnft_global />
      <div className="">
        {" "}
        <div className="d-f j-c-s-b g-2 m-t-4">
          {" "}
          <div className="m-q-b-d-n b-r-1">
            <Help_sidebar />
          </div>
          <div className="max-w-50 p-x-2 scrll max-h-80vh o-a">
            <div className="f-s-1_5 f-w-600 m-b-1">
              What are gas fees and how are they calculated?
            </div>
            <div className="m-b-2">
              Gas fees are transaction fees paid to compensate for the computing
              energy required to process and validate transactions on a
              blockchain network. In the context of NFTs, for MAAL blockchain,
              gas fees are paid in MAAL’s native currency, (MAAL). Here's how
              they are calculated and function:{" "}
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">1. Definition of Gas:</span> In
              MAAL, "gas" refers to the unit that measures the amount of
              computational effort required to execute operations like
              transactions or smart contracts.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">2. Gas Limit: </span> This is the
              maximum amount of gas you're willing to spend on a transaction.
              Different operations require different amounts of gas. Simple
              transactions require less gas, while complex interactions with
              smart contracts (like minting an NFT) require more.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">3. Gas Price: </span> This is the
              amount of MAAL you are willing to pay per unit of gas, measured in
              "gwei" (a smaller denomination of MAAL, where 1 MAAL =
              1,000,000,000 gwei). Users can set a higher gas price to
              prioritize their transaction by miners.{" "}
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                4. Calculation of Gas Fees:{" "}
              </span>{" "}
              The total gas fee for a transaction is calculated as Gas Limit ×
              Gas Price. For example, if the gas limit is 50,000 units and the
              gas price is 100 gwei, the gas fee would be 0.005 MAAL.
            </div>

            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                5. Factors Affecting Gas Fees:
              </span>{" "}
              Gas fees can vary significantly based on network congestion. The
              more people trying to make transactions at the same time, the
              higher the demand for computational power, and thus the higher the
              gas fees. Time of day and network activity influence these fees.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">6. Paying Gas Fees:</span> When
              you make a transaction, such as buying an NFT, you pay the gas fee
              to the miners on the MAAL network who process and validate the
              transaction. The fee is paid in MAAL from your digital wallet.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">7. Why Gas Fees Matter: </span>{" "}
              Gas fees are crucial because they help prevent spam on the network
              and allocate resources fairly. They also incentivize miners to
              keep the network secure and operational.
            </div>
            <div className="m-b-2">
              Understanding gas fees is important for anyone interacting with
              the MAAL blockchain, particularly when minting, buying, or selling
              NFTs, as they can significantly affect the overall cost of
              transactions.
            </div>
          </div>
          <div className="">.</div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
