import React from "react";
import Navbarnft from "../Navbars/Navbarnft";
import nft from "../../Images/nft.png";
import buy from "../../Images/buy.png";
import fees from "../../Images/fees.png";
import sale from "../../Images/sale.png";
import wallet from "../../Images/wallet.png";
import verify from "../../Images/verify.png";
import copyright from "../../Images/copy.png";
import loss from "../../Images/loss.png";
import royalty from "../../Images/royalty.png";
import { Link } from "react-router-dom";
import Footer from "../Footer";

function Help({ UserWallet, signMessage, logout, URL }) {
  return (
    <>
      <div className="bg_slider">
        <Navbarnft
          UserWallet={UserWallet}
          signMessage={signMessage}
          logout={logout}
          URL={URL}
        />
      </div>

      <div className="bg_blue m-b-5">
        <div className="container-kws">
          <div className="p-x-2">
            <div className="p-t-9 p-b-7 t-a-c f-s-2 c-w f-w-600">
              Hey, how can we help?
            </div>
          </div>
        </div>
      </div>
      <div className="container-kws">
        {" "}
        <div className="d-f flex-wrap j-c-c g-2 p-b-7">
          <Link to="/help/nft">
            <div className="box_shadow_hover pa-2 b-1 d-f f-d-c a-i-c b-r-5 w-20 h-100 t-a-c c-p">
              <div className="">
                <img src={nft} alt="nft" className="w-5" />
              </div>
              <div className="m-y-2 f-w-600 f-s-1_25">What is an NFT?</div>
              <div className=" m-t-a">An NFT, or Non-Fungible Token...</div>
            </div>
          </Link>
          <Link to="/help/buy">
            <div className="box_shadow_hover pa-2 b-1 d-f f-d-c a-i-c b-r-5 w-20 h-100 t-a-c c-p">
              <div className="">
                {" "}
                <img src={buy} alt="buy-nft" className="w-5" />
              </div>
              <div className="m-y-2 f-w-600 f-s-1_25">How do I buy an NFT?</div>
              <div className=" m-t-a">To buy an NFT Non-Fun...</div>
            </div>
          </Link>
          <Link to="/help/fees">
            <div className="box_shadow_hover pa-2 b-1 d-f f-d-c a-i-c b-r-5 w-20 h-100 t-a-c c-p">
              <div className="">
                {" "}
                <img src={fees} alt="fees" className="w-5" />
              </div>
              <div className="m-y-2 f-w-600 f-s-1_25">
                What are gas fees and how are they calculated?
              </div>
              <div className=" m-t-a">Gas fees are transaction fe...</div>
            </div>
          </Link>
          <Link to="/help/sale">
            <div className="box_shadow_hover pa-2 b-1 d-f f-d-c a-i-c b-r-5 w-20 h-100 t-a-c c-p">
              <div className="">
                {" "}
                <img src={sale} alt="sale" className="w-5" />
              </div>
              <div className="m-y-2 f-w-600 f-s-1_25">
                How do I create and list an NFT for sale on PANSEA?
              </div>
              <div className=" m-t-a">To create and list an NFT N...</div>
            </div>
          </Link>
          <Link to="/help/resell">
            <div className="box_shadow_hover pa-2 b-1 d-f f-d-c a-i-c b-r-5 w-20 h-100 t-a-c c-p">
              <div className="">
                {" "}
                <img src={wallet} alt="wallet" className="w-5" />
              </div>
              <div className="m-y-2 f-w-600 f-s-1_25">
                Can I resell an NFT I purchased?
              </div>
              <div className=" m-t-a">We currently support Metamas</div>
            </div>
          </Link>
          <Link to="/help/verify">
            <div className="box_shadow_hover pa-2 b-1 d-f f-d-c a-i-c b-r-5 w-20 h-100 t-a-c c-p">
              <div className="">
                {" "}
                <img src={verify} alt="verify" className="w-5" />
              </div>
              <div className="m-y-2 f-w-600 f-s-1_25">
                Is there a verification process for creators
              </div>
              <div className=" m-t-a"> Yes, PANSEA has a verifi...</div>
            </div>
          </Link>
          <Link to="/help/copyright">
            <div className="box_shadow_hover pa-2 b-1 d-f f-d-c a-i-c b-r-5 w-20 h-100 t-a-c c-p">
              <div className="">
                {" "}
                <img src={copyright} alt="copyright" className="w-5" />
              </div>
              <div className="m-y-2 f-w-600 f-s-1_25">
                How does the marketplace handle copyright and intellectual
                property rights?
              </div>
              <div className=" m-t-a">PANSEA, along with most NFT...</div>
            </div>
          </Link>
          <Link to="/help/loss">
            <div className="box_shadow_hover pa-2 b-1 d-f f-d-c a-i-c b-r-5 w-20 h-100 t-a-c c-p">
              <div className="">
                {" "}
                <img src={loss} alt="loss" className="w-5" />
              </div>
              <div className="m-y-2 f-w-600 f-s-1_25">
                What happens if I loss access to my digital wallet?
              </div>
              <div className=" m-t-a">Losing access to your ...</div>
            </div>
          </Link>
          <Link to="/help/royalty">
            <div className="box_shadow_hover pa-2 b-1 d-f f-d-c a-i-c b-r-5 w-20 h-100 t-a-c c-p">
              <div className="">
                {" "}
                <img src={royalty} alt="royalty" className="w-5" />
              </div>
              <div className="m-y-2 f-w-600 f-s-1_25">
                How do royalties work on NFT sales?
              </div>
              <div className=" m-t-a">
                Royalties in the context of NFTs are...
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Help;
