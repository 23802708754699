import React, { useEffect, useRef, useState } from "react";
import "./Profilesettingslide.css";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import uploadFile from "../../Apkss";
import Featuredvector from "../../Images/Vector.png";
import { Link } from "react-router-dom";
import Navbarnft from "../Navbars/Navbarnft";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import Navbarnft_global from "../Navbars/Navbarnft_global";
// IMPORT
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Imagevector from "./Imagesprofile/Image-vector.png";
import Profileimg from "./Imagesprofile/profileimg.png";
import toast, { Toaster } from "react-hot-toast";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { useStoreState } from "easy-peasy";
import { BsPencil } from "react-icons/bs";
import { Backdrop, CircularProgress } from "@mui/material";

const notify = (msg) => toast.success(msg);
const warning = (msg) => toast.error(msg);

export default function Profilesettingslide({
  UserWallet,
  URL,
  IPFS_image_baseURL,
  signMessage,
  logout,
}) {
  const [selectedFile, setSelectedFilebanner] = useState();
  const [selectedFileprofile, setSelectedFileprofile] = useState();
  const authorization = useStoreState((state) => state.authorization);
  const ipfs_Store_url = useStoreState((state) => state.ipfs_Store_url);
  const [bannerlink, setBannerLink] = useState();
  const [logolink, setLogoLink] = useState("");
  const [bannerlink_nft, setBannerLink_nft] = useState();
  const [logolink_nft, setLogoLink_nft] = useState("");
  const [username, setUserName] = useState();
  const [userbio, setUserBio] = useState();
  const [useremail, setUserEmail] = useState();
  const [websitelink, setWebsitelink] = useState();
  const [wallet, setWallet] = useState();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const init = async () => {
      axios
        .post(`${URL}/getuser`, {
          wallet: UserWallet,
        })
        .then((res) => {
          console.log(res);
          // setBannerLink(res.data[0].bannerImage);
          setUserName(res.data[0].name);
          setUserBio(res.data[0].bio);
          setUserEmail(res.data[0].email);
          setWebsitelink(res.data[0].website);
          setWallet(res.data[0].Creator);
        });
    };
    init();
  }, [UserWallet]);

  const upload_banner = async (file) => {
    try {
      setOpen(true);
      const formData = new FormData();
      formData.append("file", file[0]);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setBannerLink_nft(image);
      console.log("Upload response:", image);
      setOpen(false);
    } catch (error) {
      setOpen(false);
      console.error("Error uploading image:", error);
    }
  };
  const upload_banner_aws = async (file) => {
    const result = await uploadFile(file[0]);
    setBannerLink(result);
    console.log("result", result);
  };

  const upload_profile_image = async (file) => {
    try {
      setOpen(true);
      const formData = new FormData();
      formData.append("file", file[0]);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setLogoLink_nft(image);
      console.log("Upload response:", image);
      setOpen(false);
    } catch (error) {
      setOpen(false);
      console.error("Error uploading image:", error);
    }
  };

  const upload_profile_image_aws = async (file) => {
    const result = await uploadFile(file[0]);
    setLogoLink(result);
    console.log("result", result);
  };
  const Update_user = async () => {
    if (!bannerlink) {
      warning("Banner image not selected");
      return;
    }
    // if(!logolink){
    //   return
    // }
    axios
      .post(`${URL}/updateuser`, {
        wallet: UserWallet,
        bannerImage: `${bannerlink}`,
        logoImage: `${logolink}`,
        bannerImage_nft: `${bannerlink_nft}`,
        logoImage_nft: `${logolink_nft}`,
        name: username,
        email: useremail,
        bio: userbio,
        website: websitelink,
      })
      .then((res) => {
        console.log(res);
        notify("Updated profile");
      });
  };

  const fileInputRef = useRef(null);

  const handlePencilClick = () => {
    fileInputRef.current.click();
  };

  const handleCopyClick = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        notify("copied");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <>
      <Navbarnft_global
        signMessage={signMessage}
        logout={logout}
        UserWallet={UserWallet}
        URL={URL}
      />
      <div className="container-fluid  "></div>
      <div>
        <div className="container-fluid">
          <div className="row ">
            <div className=" col-lg-2 col-md-12 col-sm-12 col-12 b-r-1 b-r-0">
              <div>
                <ul className="listed-section m-t-3">
                  <span className="setting-sections p-t-3 f-w-600">
                    Setting
                  </span>
                  <Link to="/profilesetting">
                    <li className="section-list profile-setting b-r-10">
                      <span>
                        <PermIdentityOutlinedIcon className="icons-tabs" />
                      </span>{" "}
                      <span className="content-style">Profile</span>
                    </li>
                  </Link>

                  <Link to="/offers">
                    {" "}
                    <li className="section-list">
                      <span>
                        <LocalOfferOutlinedIcon className="icons-tabs" />
                      </span>
                      <span className="content-style">Offers</span>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className=" col-lg-10 col-md-12 col-sm-12 col-12 h-90vh o-a notification-padding">
              <div className="profile-head-section">
                <span className="profile-heading">Profile details</span>
                {/* <span className="preview-profile-button">
                  <RemoveRedEyeOutlinedIcon className="icons-eye" />
                  Preview
                </span> */}
              </div>
              <div>
                <p className="profile-section-banner m-t-2 m-b-1">
                  Profile Banner
                </p>
              </div>
              <div
                className="section-profile-line ps-r"
                style={{
                  backgroundImage: `url(${bannerlink})`,
                  backgroundSize: "100% 100%",
                }}
              >
                <div className="drop-section-image ">
                  <div className="fe-bo t-a-c h-c-v-c">
                    <input
                      type="file"
                      onChange={(e) => {
                        upload_banner(e.target.files);
                        upload_banner_aws(e.target.files);
                      }}
                      className=""
                    />
                  </div>
                </div>
              </div>
              <div className="row my-5">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div>
                    <span className="user-section">Username</span>
                    <div>
                      <input
                        placeholder="Enter username"
                        className="place-input-profile"
                        value={username}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="my-3">
                    <span className="user-section">Email-Address</span>
                    <div>
                      <input
                        placeholder="Enter email"
                        className="place-input-profile"
                        value={useremail}
                        onChange={(e) => setUserEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <div>
                    <p className="profile-section-banner m-b-1">Social Connections</p>
                    <p className="profile-line">
                      Verify your account by connecting social accounts
                    </p>
                  </div> */}
                  {/* <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="section-twitter">
                        <TwitterIcon id="twitter-profile-icon" />
                        <span className="twit">Twitter</span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <button className="connect-bu">Connect</button>
                    </div>
                  </div> */}
                  {/* <div className="row my-2">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="section-twitter">
                        <InstagramIcon id="twitter-profile-icon" />
                        <span className="twit">Instagram</span>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <button className="connect-bu">Connect</button>
                    </div>
                  </div> */}
                  {/* <div>
                    <p className="profile-section-banner m-b-1">Links</p>
                    <div>
                      <input
                        placeholder="https://"
                        className="place-input-profile"
                        onChange={(e) => setWebsitelink(e.target.value)}
                        value={websitelink}
                      />
                    </div>
                  </div> */}
                  <div>
                    <p className="profile-section-banner m-t-1 m-b-1">
                      Wallet address
                    </p>
                    <div>
                      <InputGroup className="mb-3 inputgrp">
                        <Form.Control
                          aria-label="Amount (to the nearest dollar)"
                          style={{ borderRight: "none" }}
                          value={wallet}
                        />
                        <InputGroup.Text>
                          <ContentCopyIcon
                            style={{ color: "#6a6a6a" }}
                            className="c-p"
                            onClick={() => handleCopyClick(wallet)}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="profile-section-content">
                    <span className="user-section-img">Profile image</span>
                    <div
                      style={{
                        backgroundImage: `url(${logolink})`,
                        backgroundSize: "100% 100%",
                      }}
                      className="profile_img ps-r w-7 b-r-50 m-t-1 h-7 b-1_dotted "
                      onClick={handlePencilClick}
                    >
                      <BsPencil className="h-c-v-c c-b " />

                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={(e) => {
                          upload_profile_image(e.target.files);
                          upload_profile_image_aws(e.target.files);
                        }}
                      />
                    </div>
                  </div>
                  <div className="user-bio-section">
                    <span className="user-section-img">Bio</span>
                    <div>
                      <textarea
                        className="input-bio"
                        placeholder="Tell us about yourself"
                        value={userbio}
                        onChange={(e) => setUserBio(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="save-bu-pro my-4"
                    onClick={() => Update_user()}
                  >
                    Save
                  </button>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-0 col-0"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster />
    </>
  );
}
