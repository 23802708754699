import React, { useEffect, useState } from "react";
import "./Profile.css";
import Profileimg from "../../Images/profileimg.png";
import { FiCopy } from "react-icons/fi";
import { TfiWorld } from "react-icons/tfi";
import { FiUpload } from "react-icons/fi";
import { MdOutlineMoreHoriz } from "react-icons/md";
import Profiletabs from "./Profiletabs";
import Footer from "../Footer";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import Navbarnft_global from "../Navbars/Navbarnft_global";
import toast, { Toaster } from "react-hot-toast";
import { BsFillPencilFill } from "react-icons/bs";

function Profile({ UserWallet, URL, IPFS_image_baseUR, signMessage, logout }) {
  const slice = (str) => {
    const first = str.slice(0, 6);
    const second = str.slice(36);
    return first + "..." + second;
  };

  const [bannerlink, setBannerLink] = useState();
  const [logolink, setLogoLink] = useState("");
  const [username, setUserName] = useState();
  const [userbio, setUserBio] = useState();
  const [useremail, setUserEmail] = useState();
  const [websitelink, setWebsitelink] = useState();
  const [wallet, setWallet] = useState();
  const [jointime, setJoinTime] = useState();

  useEffect(() => {
    const init = async () => {
      axios
        .post(`${URL}/getuser`, {
          wallet: UserWallet,
        })
        .then((res) => {
          setBannerLink(res.data[0].bannerImage);
          setUserName(res.data[0].name);
          setLogoLink(res.data[0].logoImage);
          setUserBio(res.data[0].bio);
          setUserEmail(res.data[0].email);
          setWebsitelink(res.data[0].website);
          setWallet(res.data[0].Creator);
          setJoinTime(res.data[0].joinAt);
        });
    };
    init();
  }, [UserWallet]);

  const Time_ago = (time) => {
    const date = moment(time).format("LLL");
    return date;
  };
  const notify = (msg) => toast.success(msg);

  const handleCopyClick = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        notify("copied");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <>
      <Navbarnft_global
        signMessage={signMessage}
        logout={logout}
        UserWallet={UserWallet}
        URL={URL}
      />
      <Link to="/profilesetting">
        <div
          className="container-fluid background-profile ps-r"
          style={{
            backgroundImage: `url(${bannerlink ? bannerlink : ""})`,
            backgroundColor: `${bannerlink ? "" : "var(--borders_clr)"}`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            zIndex:-5,
          }}
        >
          {/* <div className="hover">
            <BsFillPencilFill className=" h-c-v-c z-i-99" />
          </div> */}
        </div>
      </Link>
      <div className="container-fluid">
        <div className="row">
          <div className="t-a-c m-t-_7 z-i-99">
            <img
              src={logolink ? logolink : Profileimg}
              alt=""
              className="w-10 h-10 b-r-50 m-b-2 "
            />
          </div>
          <div className="profile-detail">
            <h5 className="profile-name">{username}</h5>
            <span className="">
              {UserWallet}
              <FiCopy
                style={{ marginLeft: "10px" }}
                className="c-p"
                onClick={() => handleCopyClick(UserWallet)}
              />
            </span>
{/* 
            {websitelink === "" ? (
              ""
            ) : (
              <span className="www">
                <TfiWorld />
                &nbsp;
                {websitelink}
              </span>
            )} */}
            <br />
            <br />
            <span className="www">{Time_ago(jointime)}</span>
          </div>
        </div>
        <div className="row">
          <div className="edit">
            <button className="edit-button b-1">
              <Link to="/profilesetting">Edit Profile</Link>
            </button>
            {/* <span className="upload-bu">
              <FiUpload className="up-bu" />
            </span> */}
            {/* <span className="upload-bu">
              <MdOutlineMoreHoriz className="up-bu" />
            </span> */}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <Profiletabs
          URL={URL}
          IPFS_image_baseUR={IPFS_image_baseUR}
          UserWallet={UserWallet}
        />
      </div>
      <Footer />
      <Toaster />
    </>
  );
}

export default Profile;
