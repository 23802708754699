import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tabimage1 from "../Images/tabimage1.png";
import Tick from "../Images/tickimg.png";
import "./Hometable.css";
import { Box, Typography } from "@mui/material";
import "../App.css";

import Notable_Collection from "./Notable_Collection";
import Drop_nft_slider from "./AirDrop/Drop_nft_slider";

import Browse from "./Browse";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

import { Autoplay, Pagination, Navigation } from "swiper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function BasicTable({ UserWallet, URL }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="Hometable">
        {/* ----------------------------------------------------NEW AND NOTABLE------------------------------------------------------------- */}
        <div className="Section">
          <Box>
            <Typography variant="h3" className="f-w-600">
              Notable Collections
            </Typography>
          </Box>

          <Notable_Collection UserWallet={UserWallet} URL={URL} />
        </div>
        {/* ---------------------------------------------------STORYTELLING NFT SPOLTLIGHT----------------------------------------------- */}
        {/* <div className="Section">
          <Box>
            <Typography variant="h3" className="f-w-600">
              <div className="d-f a-i-c j-c-s-b">
                <div className="">Drop NFT's</div>
                <div className="">
                  <a href="/drop/all">
                    <button className="twitter-icons b-n f-s-1 p-x-2 p-y-0_5">
                      View all
                    </button>
                  </a>
                </div>
              </div>
            </Typography>
          </Box>
    
          <Drop_nft_slider UserWallet={UserWallet} URL={URL} />
        </div> */}
        {/* ----------------------------------------------------HOT-------------------------------------------------------------------- */}

        {/*   <div className="Section">
          <Box>
            <Typography variant="h3">Hot</Typography>
          </Box>

          <Hotslider />
        </div> */}
        {/* --------------------------Browse----------------------------------- */}

        <Browse />
      </div>
    </>
  );
}
