import React from "react";
import Help_sidebar from "./Help_sidebar";
import Navbarnft_global from "../Navbars/Navbarnft_global";

function Buy() {
  return (
    <div>
      <Navbarnft_global />
      <div className="">
        {" "}
        <div className="d-f j-c-s-b g-2 m-t-4">
          {" "}
          <div className="m-q-b-d-n b-r-1">
            <Help_sidebar />
          </div>
          <div className="max-w-50 p-x-2 scrll max-h-80vh o-a">
            <div className="f-s-1_5 f-w-600 m-b-1">
              Is there a verification process for creators
            </div>
            <div className="m-b-2">
              Yes, PANSEA has a verification process for creators. This process
              is designed to establish the authenticity of creators and their
              collections, fostering trust among buyers and the broader
              community. Here's how it typically works:
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">1. Account Setup:</span> Creators
              first need to set up an account on PANSEA and create their NFTs.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                2. Application for Verification:
              </span>{" "}
              Once a creator has an active presence on the platform, they can
              apply for verification. This usually involves filling out a form
              provided by PANSEA.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                3. Criteria for Verification:{" "}
              </span>{" "}
              PANSEA may have specific criteria that creators need to meet to be
              eligible for verification. This often includes factors like the
              volume of transactions, the uniqueness of the collection, the
              number of followers or community engagement, and the overall
              quality of the NFTs.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                4. Providing Necessary Information:
              </span>
              Creators might need to provide additional information about
              themselves and their work. This can include links to social media
              profiles, official websites, or other platforms where they
              showcase their work.
            </div>

            <div className="m-b-2">
              <span className="f-w-600  m-r-1">5. Review Process:</span> PANSEA
              reviews the application and assesses whether the creator meets its
              verification standards. This process can vary in length depending
              on the volume of applications.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">6. Verification Badge: </span>{" "}
              Once verified, creators receive a badge on their PANSEA profile,
              indicating their verified status. This badge helps to distinguish
              reputable creators and their collections from unverified ones.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">7. Ongoing Compliance:</span>{" "}
              Verified creators are expected to maintain a level of quality and
              authenticity. Any violation of PANSEA's terms or involvement in
              fraudulent activities could result in the removal of the
              verification badge.
            </div>

            <div className="m-b-2">
              The verification process on PANSEA is crucial for creators looking
              to establish credibility and for buyers seeking assurance about
              the authenticity of the NFTs they purchase. As the NFT market
              continues to evolve, these processes are subject to change, so
              creators should keep up-to-date with the latest guidelines and
              requirements from PANSEA.
            </div>
          </div>
          <div className="v-h">.</div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
