import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

import { useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { IoIosMenu } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

function Help_detail() {
  const navigate = useNavigate();
  const handleLogout = () => {
    // Remove admin:true from local storage
    localStorage.removeItem("admin__tijarah");
    // Redirect to the login page or any other desired page
    navigate("/");
  };

  const [show, setshow] = useState(false);
  return (
    <div>
      <div className="max-w-20 m-q-b-d-n">
        <NavLink className="help" to="/admin-64d07beb1731b48d296a7fd0">
          {" "}
          <div className=" m-b-1 p-x-1  ">Dashboard</div>
        </NavLink>
        <NavLink
          className="help"
          to="/collection-rank-658cf11ebadfb3e8eae8ecae"
        >
          {" "}
          <div className=" m-b-1 p-x-1">Collection Rank</div>
        </NavLink>
        <div className=" m-b-1 p-x-1 c-p" onClick={() => handleLogout()}>
          Logout
        </div>
      </div>
      <div className="m-q-a-d-n">
        <IoIosMenu
          className="f-s-2_5 m-x-2 m-b-3"
          onClick={() => setshow(true)}
        />
      </div>
      <Offcanvas show={show} onHide={() => setshow(false)} className="max-w-15">
        <div className="m-t-3">
          <NavLink className="help" to="/admin-64d07beb1731b48d296a7fd0">
            {" "}
            <div className=" m-b-1 p-x-1  ">Dashboard</div>
          </NavLink>
          <NavLink
            className="help"
            to="/collection-rank-658cf11ebadfb3e8eae8ecae"
          >
            {" "}
            <div className=" m-b-1 p-x-1">Collection Rank</div>
          </NavLink>
          <div className=" m-b-1 p-x-1 c-p" onClick={() => handleLogout()}>
            Logout
          </div>
        </div>
      </Offcanvas>
    </div>
  );
}

export default Help_detail;
