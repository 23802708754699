import React from "react";
import Help_sidebar from "./Help_sidebar";
import Navbarnft_global from "../Navbars/Navbarnft_global";

function Buy() {
  return (
    <div>
      <Navbarnft_global />
      <div className="">
        {" "}
        <div className="d-f j-c-s-b g-2 m-t-4">
          {" "}
          <div className="m-q-b-d-n b-r-1">
            <Help_sidebar />
          </div>
          <div className="max-w-50 p-x-2 scrll max-h-80vh o-a">
            <div className="f-s-1_5 f-w-600 m-b-1">
              What happens if I lose access to my digital wallet?{" "}
            </div>
            <div className="m-b-2">
              Losing access to your digital wallet can be a serious issue,
              especially when it contains valuable assets like NFTs or
              cryptocurrency. Here’s what you should know and what steps you
              might take:
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">1. Backup Your Wallet :</span>{" "}
              Preventing loss of access is key. Always keep a backup of your
              wallet's private keys or seed phrase in a secure place. This is
              crucial because your private keys or seed phrase are the only way
              to restore access to your wallet.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                2. No Centralized Recovery:{" "}
              </span>{" "}
              Unlike traditional bank accounts or online accounts, most digital
              wallets don't have a centralized authority or customer support
              that can restore access to your wallet if you lose your private
              keys or seed phrase.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                3. Contact Wallet Support:{" "}
              </span>{" "}
              If you're using a wallet service with customer support (like some
              software wallets), contact them for assistance. They might be able
              to guide you through a recovery process if one is available.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                4. Using Seed Phrase for Recovery:
              </span>{" "}
              If you have your seed phrase, you can use it to restore your
              wallet on a new device. Simply download the wallet software and
              select the option to restore a wallet, then enter your seed
              phrase.
            </div>

            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                5. No Recovery Without Keys/Seed Phrase:
              </span>{" "}
              If you've lost your private keys and don't have a seed phrase, or
              if you forget your wallet's password and don't have a recovery
              method, it's unlikely that you'll be able to regain access to your
              wallet.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">6. Securing Your Wallet: </span>{" "}
              To avoid such situations, it’s vital to securely store your
              private keys or seed phrase (preferably offline, like on a piece
              of paper in a safe place or a hardware wallet).
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">7. Educate Yourself: </span>{" "}
              Familiarize yourself with the recovery process for your specific
              wallet before a crisis occurs.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                8. Consider Professional Help:{" "}
              </span>{" "}
              If a substantial amount is at stake and you cannot access your
              wallet, consulting with a professional data recovery service might
              be an option, though success is not guaranteed.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">9. Preventative Measures: </span>{" "}
              Regularly back up your wallet, keep your software updated, and use
              all available security features (like two-factor authentication)
              to protect your wallet.
            </div>

            <div className="m-b-2">
              Remember, in the world of cryptocurrency and NFTs, the security of
              your assets is largely in your hands, and losing access to your
              wallet can mean losing your assets permanently. Therefore, taking
              proactive steps to secure and backup your wallet is of utmost
              importance.
            </div>
          </div>
          <div className="v-h">.</div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
