import React, { useEffect, useState } from "react";
import "./ProfileSelector.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";

// IMAGE IMPORT
import Filtericon from "../../Images/filter-vector.png";
import Refresh from "../../Images/Reload-vector.png";
import Viewgrid from "../../Images/view-grid.png";
import Viewgrid2 from "../../Images/Group8926.png";

//IMPORT CARDS

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Grid, IconButton, Paper } from "@mui/material";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiFillHeart, AiOutlineHeart, AiFillThunderbolt } from "react-icons/ai";
import { FallingLines } from "react-loader-spinner";
import down from "../../Images/down_arrow.png";
import { Link } from "react-router-dom";
import {
  get_Minting_contract_1155,
  get_all_nft_contract_,
} from "../../Web3/Contract";
import Skeleton from "react-loading-skeleton";

function ProfileSelector({
  URL,
  IPFS_image_baseUR,
  UserWallet,
  create_Single_Nft_Contract,
  add_Favorate,
}) {
  const [cardimg, setCardimg] = useState([]);
  const [heart, setHeart] = useState(false);
  const [ownedNFTS, setOwndNFTs] = useState();
  const [page, setPage] = useState(1);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    const init = async () => {
      OwnNFTS();
    };
    init();
  }, [UserWallet, page]);

  const OwnNFTS = async () => {
    const nfts = [];
    axios
      .post(`${URL}/nftbyowner`, {
        wallet: UserWallet,
        page: page,
      })
      .then(async (response) => {
        console.log(response.data);
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].isNFTMinted && response.data[i].isCustom) {
            const contractValidator = await get_all_nft_contract_(
              response.data[i].NFTContract
            );

            const uri = await contractValidator.methods.baseURI().call();
            const nftname = await contractValidator.methods.name().call();
            const symbol = await contractValidator.methods.symbol().call();

            const Datafromserver = await axios
              .post(`${URL}/callcorsapi`, {
                uri: `${uri}${response.data[i].tokenID}.json`,
              })
              .then((res) => {
                return res.data;
              });
            const collectionname = await axios
              .post(`${URL}/collectionbyname`, {
                name: response.data[i].collectionID,
              })
              .then((res) => {
                return res.data.name;
              });
            response.data[i].collectionname = collectionname;
            response.data[i].name = nftname;
            response.data[i].data = Datafromserver;
            response.data[i].contractanimation = Datafromserver.animation_url;
            response.data[i].contractimage = Datafromserver.image;
            response.data[i].symbol = symbol;

            nfts.push(response.data[i]);
          } else if (
            response.data[i].isNFTMinted &&
            response.data[i].airDropNFT
          ) {
            const contractValidator = await get_all_nft_contract_(
              response.data[i].NFTContract
            );
            const symbol = await contractValidator.methods.symbol().call();
            let uri = await contractValidator.methods.baseURI().call();

            if (uri === "" || uri === undefined) {
              uri = await contractValidator.methods.initialURI().call();
            }
            if (typeof uri === "string") {
              if (uri[uri.length - 1] != "/") {
                uri = uri + "/";
              }
            }
            const nftname = await contractValidator.methods.name().call();
            const Datafromserver = await axios
              .post(`${URL}/callcorsapi`, {
                uri: `${uri}${response.data[i].tokenID}.json`,
              })
              .then((res) => {
                return res.data;
              });
            const collectionname = await axios
              .post(`${URL}/collectionbyname`, {
                name: response.data[i].collectionID,
              })
              .then((res) => {
                return res.data.name;
              });
            response.data[i].collectionname = collectionname;
            response.data[i].name = nftname;
            response.data[i].data = Datafromserver;
            response.data[i].contractanimation = Datafromserver.animation_url;
            response.data[i].contractimage = Datafromserver.image;
            response.data[i].symbol = symbol;

            nfts.push(response.data[i]);
          } else {
            const contract = await get_all_nft_contract_(
              response.data[i].NFTContract
            );
            const symbol = await contract.methods.symbol().call();
            // const balance = await contract.methods.balanceOf(UserWallet, response.data[i].tokenID).call();
            const collectionname = await axios
              .post(`${URL}/collectionbyname`, {
                name: response.data[i].collectionID,
              })
              .then((res) => {
                return res.data.name;
              });
            response.data[i].collectionname = collectionname;
            response.data[i].symbol = symbol;
            nfts.push(response.data[i]);
          }
        }
        setOwndNFTs(
          typeof ownedNFTS == "object" ? [...ownedNFTS, ...nfts] : [...nfts]
        );
        setButtonLoader(false);
      });
  };

  const refreshData = () => {
    OwnNFTS();
  };

  const searchNFTS = async (name) => {
    console.log(name);
    axios
      .post(`${URL}/filternftbynameowner`, {
        name: name,
        wallet: UserWallet,
      })
      .then(async (response) => {
        setOwndNFTs(response.data);
        console.log(response.data);
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>
          <div className="m-q-a-p-r-1_1200 ">
            <Button
              className="refresh-button collection-filter b-c-t b-1"
              onClick={() => refreshData()}
            >
              <img src={Refresh} alt="" />
            </Button>
          </div>
        </Grid>
        <Grid item xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}>
          <div className="search-nav search-text-field m-y-1 b-1">
            <div className="input-nav ">
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                }}
                className="collection-filter"
                id="form-search-input-collection"
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon id="search-icon" />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by NFTs"
                  id="search-icon-text"
                  onChange={(e) => searchNFTS(e.target.value)}
                />
              </Paper>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid>
        <div className="">
          <div className="row mb-5">
            {ownedNFTS ? (
              ownedNFTS.map((res) => {
                return (
                  <>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                      <Card className="profile-card collected-profile-card">
                        <div className="h-21 d-f a-i-c j-c-c o-h" id="h-26">
                          <img
                            src={`${
                              res.contractimage
                                ? res.contractimage
                                : res.imageURL
                            }`}
                            className="w-100 h-100 o-f-c"
                          />
                        </div>
                        <Card.Body className="card-body-text">
                          <div className="d-f j-c-s-b m-y-0_5">
                            <div className="">
                              <Card.Title className="card-head m-b-2">
                                {res.symbol}&nbsp;&nbsp;#{res.tokenID}
                              </Card.Title>
                              {/* <Card.Text className="f-w-600 ">0.0183 ETH</Card.Text> */}
                            </div>
                            <div
                              className=""
                              onClick={async () => {
                                const data = await add_Favorate(
                                  res._id,
                                  res.liked.includes(UserWallet)
                                );
                                if (data) {
                                  refreshData();
                                }
                              }}
                            >
                              {" "}
                              {res.liked.includes(UserWallet) ? (
                                <AiFillHeart className="fill-heart-like f-s-1_5" />
                              ) : (
                                <AiOutlineHeart className="unfill-heart-like f-s-1_5" />
                              )}
                            </div>
                          </div>
                        </Card.Body>
                        <Link to={`/assets/${res._id}`}>
                          <div className="add-cart-section ">
                            <span className="add-sec">View</span>
                          </div>
                        </Link>
                        {/* {res.isNFTMinted ? (
                          <Link to={`/assets/${res._id}`}>
                            <div className="add-cart-section ">
                              <span className="add-sec">View</span>
                            </div>
                          </Link>
                        ) : (
                          <div
                            className="add-cart-section "
                            onClick={async() =>
                              {const data = await create_Single_Nft_Contract(
                                res.nftType,
                                res.supply,
                                res._id,
                                res.name,
                                res.metadata,
                                res.to721,
                                res.symbol,
                                res.totalMinted
                              )
                              if(data){
                                refreshData()
                              }
                            }
                            }
                          >
                            <span className="add-sec">Generate and mint</span>
                            <span className="add-sec buy">
                              <AiFillThunderbolt className="buy-thunder" />
                              Buy now
                            </span>
                          </div>
                        )} */}
                      </Card>
                    </div>
                  </>
                );
              })
            ) : ownedNFTS === undefined ? (
              <>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    {" "}
                    <div className="p-x-0_5 p-y-1">
                      <Link to="" className="">
                        <div className="">
                          {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                          <div className="">
                            {" "}
                            {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                            <Skeleton
                              height={200}
                              width="100%"
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              className="border_rad bt_br"
                            />
                          </div>
                          <div className="notable_card p-x-1 ">
                            <div className=" p-y-1 f-w-600">
                              <Skeleton
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                count={1}
                              />
                              {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                            </div>
                            <div className="row p-b-1">
                              <div className="col">
                                <div className="f-s-0_8">
                                  {" "}
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">88 PRIME</div> */}
                              </div>
                              <div className="col">
                                <div className="f-s-0_8">
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">455 MAAL</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    {" "}
                    <div className="p-x-0_5 p-y-1">
                      <Link to="" className="">
                        <div className="">
                          {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                          <div className="">
                            {" "}
                            {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                            <Skeleton
                              height={200}
                              width="100%"
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              className="border_rad bt_br"
                            />
                          </div>
                          <div className="notable_card p-x-1 ">
                            <div className=" p-y-1 f-w-600">
                              <Skeleton
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                count={1}
                              />
                              {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                            </div>
                            <div className="row p-b-1">
                              <div className="col">
                                <div className="f-s-0_8">
                                  {" "}
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">88 PRIME</div> */}
                              </div>
                              <div className="col">
                                <div className="f-s-0_8">
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">455 MAAL</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    {" "}
                    <div className="p-x-0_5 p-y-1">
                      <Link to="" className="">
                        <div className="">
                          {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                          <div className="">
                            {" "}
                            {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                            <Skeleton
                              height={200}
                              width="100%"
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              className="border_rad bt_br"
                            />
                          </div>
                          <div className="notable_card p-x-1 ">
                            <div className=" p-y-1 f-w-600">
                              <Skeleton
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                count={1}
                              />
                              {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                            </div>
                            <div className="row p-b-1">
                              <div className="col">
                                <div className="f-s-0_8">
                                  {" "}
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">88 PRIME</div> */}
                              </div>
                              <div className="col">
                                <div className="f-s-0_8">
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">455 MAAL</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    {" "}
                    <div className="p-x-0_5 p-y-1">
                      <Link to="" className="">
                        <div className="">
                          {/* upar wali classname mai notable_card className daalni hai connection ke waqt */}

                          <div className="">
                            {" "}
                            {/* <img
                    src="https://i.seadn.io/gcs/files/c9763bb78f9b1581d95551618be578fa.png?auto=format&dpr=1&h=500&fr=1"
                    alt=""
                    className="bt_br  "
                  /> */}
                            <Skeleton
                              height={200}
                              width="100%"
                              baseColor="#E4E9EB"
                              highlightColor="#fff"
                              className="border_rad bt_br"
                            />
                          </div>
                          <div className="notable_card p-x-1 ">
                            <div className=" p-y-1 f-w-600">
                              <Skeleton
                                baseColor="#E4E9EB"
                                highlightColor="#fff"
                                count={1}
                              />
                              {/* &nbsp;{" "}
                    <img src={tick} className="d-i-b w-1_25" /> */}
                            </div>
                            <div className="row p-b-1">
                              <div className="col">
                                <div className="f-s-0_8">
                                  {" "}
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">88 PRIME</div> */}
                              </div>
                              <div className="col">
                                <div className="f-s-0_8">
                                  <Skeleton
                                    baseColor="#E4E9EB"
                                    highlightColor="#fff"
                                    count={2}
                                  />
                                </div>
                                {/* <div className=" f-w-600">455 MAAL</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="p-y-7  g-c f-w-600 t-a-c">NO DATA TO SHOW</div>
            )}
            {ownedNFTS && ownedNFTS.length <= 0 ? (
              <div className="p-y-7  g-c f-w-600 t-a-c">NO DATA TO SHOW</div>
            ) : (
              ""
            )}

            {ownedNFTS && ownedNFTS.length > 0 ? (
              <div className="t-a-c">
                <button
                  className="  b-c-t b-n  m-y-2 f-w-600 f-s-1_25 p-x-3 p-y-0_5 b-r-20 b-1"
                  onClick={() => {
                    setButtonLoader(true);
                    setPage(page + 1);
                  }}
                >
                  {buttonLoader ? (
                    " Loading... "
                  ) : (
                    <img src={down} className="w-3" />
                  )}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Grid>
    </>
  );
}

export default ProfileSelector;
