import React from "react";
import Help_sidebar from "./Help_sidebar";
import Navbarnft_global from "../Navbars/Navbarnft_global";

function Buy() {
  return (
    <div>
      <Navbarnft_global />
      <div className="">
        {" "}
         <div className="d-f j-c-s-b g-2 m-t-4">
          {" "}
          <div className="m-q-b-d-n b-r-1">
            <Help_sidebar />
          </div>
          <div className="max-w-50 p-x-2 scrll max-h-80vh o-a">
            <div className="f-s-1_5 f-w-600 m-b-1">How do I buy an NFT?</div>
            <div className="m-b-2 ">
              To buy an NFT (Non-Fungible Token) on PANSEA, follow these steps:
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                1. Set Up a Digital Wallet:
              </span>{" "}
              First, you need a digital wallet that supports cryptocurrency
              transactions, as most NFTs are purchased using cryptocurrency like
              Ethereum (ETH). Popular choices include MetaMask, Trust Wallet,
              and Coinbase Wallet.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">2. Purchase MAAL: </span> If your
              wallet is not already funded, you'll need to buy cryptocurrency.
              You can purchase Ethereum or another relevant cryptocurrency on
              exchanges like Coinbase, Binance, or Kraken, and then transfer it
              to your digital wallet.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                3. Connect Your Wallet to the PANSEA:{" "}
              </span>{" "}
              This usually involves a simple verification process to ensure a
              secure connection.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">4. Browse NFT Collections:</span>{" "}
              Explore the available NFTs. You can browse different categories,
              artists, or collections based on your interests.{" "}
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                5. Select an NFT to Purchase:
              </span>{" "}
              Once you find an NFT you wish to purchase, click on it to view
              more details. Pay attention to the price, the history of the item,
              and any additional information the seller provides.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">6. Complete the Purchase: </span>{" "}
              If you decide to buy the NFT, follow the marketplace’s
              instructions to complete the purchase. This may involve a direct
              purchase for a fixed price or participating in an auction if the
              NFT is being sold that way.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                7. Confirm Transaction and Pay Gas Fees:
              </span>{" "}
              Confirm the transaction in your digital wallet. You will also need
              to pay gas fees, which are transaction fees on the blockchain.
              Note that gas fees can vary based on network congestion.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                8. NFT Transferred to Your Wallet:{" "}
              </span>{" "}
              Once the transaction is confirmed, the NFT will be transferred to
              your digital wallet
              <span className="f-w-600  m-r-1">
                You are now the owner of the NFT.{" "}
              </span>{" "}
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">10. View Your NFT:</span> You can
              view your purchased NFT in your wallet or on your profile in the
              marketplace.
            </div>
            <div className="m-b-2">
              Remember, buying NFTs involves real money and carries risks, just
              like any other investment. It's important to research and
              understand the market before making a purchase.
            </div>
          </div>
          <div className="v-h">.</div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
