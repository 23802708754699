import React, { useLayoutEffect, useState } from "react";
import { useEffect } from "react";
import Navbaroffcanvas from "./Navbaroffcanvas";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Offcanvasorigin from "./Offcanvasorigin";
import "./Navbarnft.css";
import "./Navbaroffcanvas.css";
import { Grid } from "@mui/material";
import faucet from "../../Images/faucet.png";
import {
  AiFillInstagram,
  AiFillSetting,
  AiOutlineSearch,
  AiOutlineSwap,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BsCart3, BsCollection, BsDiscord, BsPencil } from "react-icons/bs";
import { FaWallet } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";

import "../../_Global.css";
import { AiOutlineMenu } from "react-icons/ai";
import meta23 from "../../Images/meta.png";

import walletconnect from "../../Images/walletconnect.svg";
import avtaricon from "./Navimg/profile.png";
import walleticon from "./Navimg/wallet.png";
import carticon from "./Navimg/cart.png";
import logo from "../../Images/pansea.png";
import logoorigin from "./Navimg/logo.png";

// IMAGES
import Nft from "./Navimg/Ellipse 9.png";
import Recordian from "./Navimg/Ellipse 2.png";
import Art from "./Navimg/Ellipse 3.png";
import Collectibles from "./Navimg/Ellipse 4.png";
import Music from "./Navimg/Ellipse 5.png";
import Photography from "./Navimg/Ellipse 6.png";
import Videos from "./Navimg/Ellipse 7.png";
import Imgback from "./Navimg/Rectangle 684.png";
import Imgcarts from "./Navimg/cartimg.png";
import Imgcarts1 from "./Navimg/cartimg2.png";
import Checkicons from "./Navimg/che.png";
import searchicon from "./Navimg/search-icon.png";
import logo21 from "./Navimg/origin-logo-dark.png";

// ICONS
import { FaDiscord } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";

import { Link } from "react-router-dom";
import Radio from "@mui/material/Radio";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

import { AiFillYoutube } from "react-icons/ai";
import {
  MdLanguage,
  MdLogout,
  MdOutlineDarkMode,
  MdOutlineExplore,
} from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowForward, IoIosStats } from "react-icons/io";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { MdOutlineCreate, MdOutlineHome } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { GrLanguage } from "react-icons/gr";
import { BsFillMoonFill } from "react-icons/bs";
import { SiDiscord } from "react-icons/si";

// IMPORT SWITCH TAB FOR NIGHT MODE
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import { BiPowerOff, BiWalletAlt } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import { Dropdown, Modal } from "react-bootstrap";

import LanguageSelector from "../../LanguageSelector";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { logo_light, logo_dark, faucets } from "../../Web3/web3_api";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#FEC200",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Navbar({ mode, signMessage, UserWallet, logout, URL }) {
  const [isHovered, setIsHovered] = useState(false);

  const [show2, setShow2] = useState(false);
  const [searchs, setsearchs] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  var navbar = document.querySelector("nav");

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  }, []);

  const [theme, setTheme] = useState("light-theme");
  const toggleTheme = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "dark-theme"
    );
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 5) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  const [logolink, setLogoLink] = useState();
  const [name, setName] = useState();
  const [queryresult, setQueryresult] = useState();

  useEffect(() => {
    const init = async () => {
      axios
        .post(`${URL}/getuser`, {
          wallet: UserWallet,
        })
        .then((res) => {
          setLogoLink(res.data[0].logoImage);

          setName(res.data[0].name);
        });
    };
    init();
  }, [UserWallet]);

  const searchCollecion = async (query) => {
    try {
      axios.get(`${URL}/allcollections/search/?name=${query}`).then((res) => {
        setQueryresult(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(queryresult, typeof queryresult);

  return (
    <>
      <div className=" ">
        <div className=" p-y-1 p-x-2">
          <div className="d-f a-i-c j-c-s-b f-s-1_25 ">
            <div className="">
              {theme === "dark-theme" ? (
                <div className=" ">
                  <Link to="/">
                    <img
                      src={logo_light()}
                      alt=""
                      className="m-q-a-d-n  animate__animated animate__rubberBand w-27  p-r-3 "
                    />
                    <img
                      src={logo_light()}
                      alt=""
                      className="m-q-b-d-n  animate__animated animate__rubberBand w-15  p-r-3 "
                    />
                  </Link>
                </div>
              ) : (
                <div className=" ">
                  <Link to="/">
                    <img
                      src={logo_dark()}
                      alt=""
                      className="m-q-a-d-n  animate__animated animate__rubberBand w-27  p-r-3 "
                    />
                    <img
                      src={logo_dark()}
                      alt=""
                      className="m-q-b-d-n  animate__animated animate__rubberBand w-15  p-r-3 "
                    />
                  </Link>
                </div>
              )}
            </div>
            <div className="d-f a-i-c w-100">
              <div className="w-100 ps-r">
                <div
                  className="search_global m-q-b-d-n w-100  pa-0_5"
                  onClick={() => setsearchs(!searchs)}
                >
                  <div className=" d-f a-i-c  ">
                    <div className="">
                      <AiOutlineSearch className="b-c-t " />
                    </div>
                    <div className="w-100 ">
                      <input
                        type="text"
                        className=" w-100 b-n p-l-1 b-c-t"
                        placeholder={"search global collection name"}
                        onChange={(e) => searchCollecion(e.target.value)}
                      />
                    </div>
                    <div className="slash">/</div>
                  </div>
                </div>
                <div className=" search_bar  w-100 ps-a ">
                  {typeof queryresult == "object" && queryresult.length > 0 ? (
                    <div>
                      {queryresult.map((res) => {
                        return (
                          <div className=" bg_black_clrs_search pa-1">
                            <a
                              href={`collection/${res.name}/${res.Collection_id}`}
                            >
                              <div className="d-f a-i-c ">
                                <div className="m-r-1 w-3 h-3 ">
                                  <img
                                    src={res.logoImage}
                                    className="d-f a-i-c j-c-c o-f-c w-100 h-100 b-r-50"
                                  />
                                </div>
                                <div className="">
                                  {" "}
                                  <div className="">{res.name}</div>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}{" "}
                </div>
              </div>
              <div className="">
                <div className=" c-p ">
                  <div className=" d-f a-i-c ">
                    <div className=" search_global pa-0_5 m-l-1 c-p   m-q-b-d-n">
                      <div className="d-f a-i-c">
                        {UserWallet ? (
                          <>
                            <FaWallet className="b-c-t f-s-4 h-1_5" />{" "}
                            <div className="w-s-n-w p-x-1  b-c-t">
                              <Link
                                to={`/profile/${name}/${UserWallet}`}
                                className="b-c-t "
                              >
                                Profile
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <FaWallet className="b-c-t f-s-1_5" />{" "}
                            <div className="w-s-n-w p-x-1">
                              <span
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={handleShow2}
                              >
                                Connect Wallet
                              </span>
                            </div>
                          </>
                        )}
                        <div className="">
                          <div
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            {logolink ? (
                              <img src={logolink} className="w-3 h-2 b-r-50" />
                            ) : (
                              <CgProfile className="b-c-t   f-s-1_5 -600" />
                            )}
                          </div>
                          {isHovered ? (
                            <div
                              className="profile23"
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                            >
                              <div className="  l-s-t-n">
                                <a href="/createnft">
                                  <li className="hover_effect  w-s-n-w p-y-1 p-x-1">
                                    {" "}
                                    <BsPencil className="b-c-t" />
                                    &nbsp; Create{" "}
                                  </li>
                                </a>
                                <a href="/mycollections">
                                  <li className="hover_effect  w-s-n-w p-y-1 p-x-1">
                                    {" "}
                                    <BsCollection className="b-c-t" />
                                    &nbsp; My Collection
                                  </li>
                                </a>

                                <a href="/profilesetting">
                                  <li className="hover_effect  w-s-n-w p-y-1 p-x-1">
                                    {" "}
                                    <AiFillSetting className="b-c-t" />
                                    &nbsp; Settings
                                  </li>
                                </a>
                                <li
                                  className="hover_effect  w-s-n-w p-y-1 p-x-1"
                                  onClick={() => toggleTheme()}
                                >
                                  {" "}
                                  <MdOutlineDarkMode className="b-c-t" />
                                  &nbsp; Night Mode
                                </li>
                                {/* {UserWallet ? (
                                  <li
                                    className="hover_effect  w-s-n-w p-y-1 p-x-1"
                                    onClick={() => logout()}
                                  >
                                    {" "}
                                    <MdLogout className="b-c-t" />
                                    &nbsp; Logout
                                  </li>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {faucets() ? (
                      <a
                        href="https://faucet-testnet.maalscan.io/"
                        target="_blank"
                      >
                        <div className="search_global_ck b-n p-y-0_5 p-x-1 m-l-1 c-p m-q-b-d-n d-f a-i-c bg_yellow_clr c-b w-9">
                          <img src={faucet} className="w-1_5  m-r-0_5" /> Faucet
                        </div>
                      </a>
                    ) : (
                      <a href="https://bridge.maalscan.io/" target="_blank">
                        <div className="search_global_ck b-n p-y-0_5 p-x-1 m-l-1 c-p m-q-b-d-n d-f a-i-c bg_yellow_clr c-b w-9">
                          <img src={faucet} className="w-1_5  m-r-0_5" /> Bridge
                        </div>
                      </a>
                    )}
                    <div className=" c-p m-q-a-d-n">
                      {UserWallet ? (
                        <Link to={`/profile/${name}/${UserWallet}`}>
                          <img src={logolink} className="w-9 h-2 b-r-50" />
                        </Link>
                      ) : (
                        <div
                          className="d-f w-s-n-w f-s-1_5"
                          onClick={() => handleShow2()}
                        >
                          <BiWalletAlt className="b-c-t f-s-2" />
                          &nbsp; Wallet
                        </div>
                      )}
                    </div>

                    <div className="m-l-2 c-p   m-q-a-d-n ">
                      {" "}
                      <Button
                        onClick={handleShow}
                        className=" b-c-t c-i b-n pa-0"
                      >
                        <AiOutlineMenu className="f-s-2" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="pa-1 f-s-1_5 bg-box-clr-2"
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            {" "}
            <div className="image-dog1">
              {theme === "dark-theme" ? (
                <div className=" ">
                  <Link to="/">
                    <img
                      src={logo_light()}
                      alt=""
                      className="m-q-a-d-n  animate__animated animate__rubberBand w-13  p-r-3 "
                    />
                    <img
                      src={logo_light()}
                      alt=""
                      className="m-q-b-d-n  animate__animated animate__rubberBand w-13  p-r-3 "
                    />
                  </Link>
                </div>
              ) : (
                <div className=" ">
                  <Link to="/">
                    <img
                      src={logo_dark()}
                      alt=""
                      className="m-q-a-d-n  animate__animated animate__rubberBand w-13  p-r-3 "
                    />
                    <img
                      src={logo_dark()}
                      alt=""
                      className="m-q-b-d-n  animate__animated animate__rubberBand w-13  p-r-3 "
                    />
                  </Link>
                </div>
              )}
            </div>
          </Offcanvas.Title>
          <RxCross2 onClick={handleClose} className="f-w-400 c-p" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className=" p-x-1 l-s-t-n  para_clr">
            {/*    {UserWallet ? (
              <li className="w-s-n-w m-t-1 m-b-2 " onClick={handleClose}>
                {" "}
                <Link to="/profile">
                  <CgProfile className="b-c-t " />
                  &nbsp; Profile
                </Link>
              </li>
            ) : (
              ""
            )} */}
            {faucets() ? (
              <a href="https://faucet-testnet.maalscan.io/" target="_blank">
                <div className="search_global_ck b-n p-y-0_5 p-x-1  c-p m-b-2 w-100 d-f a-i-c bg_yellow_clr c-b w-9">
                  <img src={faucet} className="w-1_5  m-r-0_5" /> Faucet
                </div>
              </a>
            ) : (
              <a href="https://bridge.maalscan.io/" target="_blank">
                <div className="search_global_ck b-n p-y-0_5 p-x-1  c-p m-b-2 w-100 d-f a-i-c bg_yellow_clr c-b w-9">
                  <img src={faucet} className="w-1_5  m-r-0_5" /> Bridge
                </div>
              </a>
            )}
            <a href="/createnft">
              <li className="w-s-n-w m-b-2" onClick={handleClose}>
                {" "}
                <BsPencil className="b-c-t" />
                &nbsp; Create{" "}
              </li>
            </a>
            <a href="/mycollections">
              <li className="w-s-n-w m-y-2" onClick={handleClose}>
                {" "}
                <BsCollection className="b-c-t" />
                &nbsp; My Collection
              </li>
            </a>
            {/*    <li
              className="w-s-n-w m-y-2"
              onClick={() => {
                handleShow2();
                // handleClose();
              }}
            >
              {" "}
              <BiWalletAlt className="b-c-t" />
              &nbsp; My wallet
            </li> */}
            <li className="w-s-n-w m-y-2">
              {" "}
              <MdLanguage className="b-c-t f-para-clr f-s-1_5" />
              &nbsp; Language
              <span className="m-l-3 m-r-1">
                <LanguageSelector />
              </span>
            </li>
            <a href="/profilesetting">
              <li className="w-s-n-w m-y-2" onClick={handleClose}>
                {" "}
                <AiFillSetting className="b-c-t" />
                &nbsp; Settings
              </li>
            </a>
            <li
              className="w-s-n-w m-y-2"
              onClick={() => {
                toggleTheme();
                handleClose();
              }}
            >
              {" "}
              <MdOutlineDarkMode className="b-c-t" />
              &nbsp; Night Mode
              {/* <span className="m-l-3">
                <BiPowerOff className=" m-r-1 c-p " sx={{ m: 1 }} />
              </span>{" "} */}
            </li>
            {UserWallet ? (
              <li className="w-s-n-w m-y-1" onClick={() => logout()}>
                {" "}
                <MdLogout className="b-c-t" />
                &nbsp; Logout
              </li>
            ) : (
              ""
            )}
          </div>
          <div className="social_icons_offcanvas">
            <hr />
            <div className="d-f a-i-c j-c-s-b p-x-2 p-y-2">
              <div className="">
                <AiOutlineTwitter className="b-c-t f-s-2" />
              </div>
              <div className="">
                <AiFillInstagram className="b-c-t f-s-2" />
              </div>
              <div className="">
                <BsDiscord className="b-c-t f-s-2" />
              </div>
              <div className="">
                <AiFillYoutube className="b-c-t f-s-2" />
              </div>
              <div className="">
                <FaTiktok className="b-c-t f-s-2" />
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={show2}
        onHide={handleClose2}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="b-n ">
          {" "}
          <div className="">
            <div className="d-f j-c-s-b a-i-c m-y-1">
              <div className=""></div>
              <div className=" f-s-1_5 f-w-600 t-a-c"> Connect Wallet</div>
              <div className="">
                <RxCross2 className="f-s-1_5 c-p" onClick={handleClose2} />
              </div>
            </div>
            <div className="m-y-1 t-a-c gray-clr">
              If you don't have a wallet, you can select a provider and create
              one now.
            </div>
            <hr />
            <div
              className="hover_effect   p-y-1 b-r-10 p-x-2 c-p"
              onClick={() => {
                signMessage("Hello, please sign in with metamask");
                handleClose2();
              }}
            >
              <div className="d-f a-i-c j-c-s-b">
                <div className="d-f a-i-c">
                  <div className="">
                    <img src={meta23} alt="" className="w-2 m-r-1_5" />
                  </div>
                  <div className="f-s-1 f-w-600">Metamask</div>
                </div>
                <div className="f-s-0_8">POPULAR</div>
              </div>
            </div>
            {/* <div className="hover_effect   p-y-1 b-r-10 p-x-2 c-p">
              <div className="d-f a-i-c">
                <div className="">
                  <img src={walletconnect} alt="" className="w-2 m-r-1_5" />
                </div>
                <div className="f-s-1 f-w-600">WalletConnect</div>
              </div>
            </div> */}

            <div className=""></div>
            {/*  */}
            {/* <img src={walletconnect} alt="" className="w-5" /> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Navbar;
