import React, { useState } from "react";
import uploadFile from "./Apkss";

function Awss() {
  const [selectedFile, setSelectedFile] = useState(null);
  
  const [imagehash_aws, setImageHash_aws] = useState();
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div>
      <input type="file" onChange={handleFileInput} />
      <button
        onClick={() =>
          uploadFile(selectedFile)
            .then((result) => {
              setImageHash_aws(result);
              console.log("result", imagehash_aws);
            })
            .catch((error) => console.error(error))
        }
      >
        Submit
      </button>
    </div>
  );
}

export default Awss;
