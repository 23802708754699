import React, { useEffect, useState } from "react";
import Navbarnft_global from "./../Navbars/Navbarnft_global";
import Footer from "./../Footer";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

function Art({ URL, UserWallet }) {
  const { cat } = useParams();

  const [collection, setCollection] = useState([]);
  useEffect(() => {
    const init = async () => {
      axios.get(`${URL}/getairdrop`)
        .then((res) => {
          console.log(res.data);
          setCollection(res.data);
        })
        .catch(console.error);
    };
    init();
  }, []);

  return (
    <div>
      <Navbarnft_global URL={URL} UserWallet={UserWallet} />

      <div className=" w-100   m-b-3 f-s-2 f-w-600  ">
        <div className="p-x-2 p-t-2">Drop NFT's</div>
      </div>

      <div className="p-x-2">
        <div className="row">
          {collection && collection.map((res) => {
            return (
              <div className=" col-lg-3 col-md-4 col-sm-6 col-12 browse-card">
                <a href={`/drop/${res.name}/${res._id}`} className="">
                  <div className="">
                    <div className="">
                      <img
                        src={res.FeturedImage}
                        alt=""
                        className="bt_br h-15 w-100 "
                      />
                    </div>
                    <div className="notable_card p-x-1 ">
                      <div className=" p-y-1_5 f-w-600">{res.name}</div>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
        {collection.length > 0 ? (
          ""
        ) : (
          <div className="p-y-5 t-a-c">No data to show...</div>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Art;
