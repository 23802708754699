import axios from "axios";
import { ethers } from "ethers";
import Web3 from "web3";

//testnet https://node1.maalscan.io
//mainnet https://node2-mainnet.maalscan.io

const message = (wallet) => `Welcome to PanSea!

Click to sign in and accept the PanSea Terms of Service (https://pansea.io/terms) and Privacy Policy (https://pansea.io/privacy).

This request will not trigger a blockchain transaction or cost any gas fees.

Your authentication status will reset after 24 hours.`;

const isNetworkTestnet = false;

export const isTestNet = () => {
  return isNetworkTestnet; //
};

let provide = isNetworkTestnet
  ? "https://node1.maalscan.io"
  : "https://node2-mainnet.maalscan.io";

const RPC = isNetworkTestnet
  ? "https://node1.maalscan.io"
  : "https://node2-mainnet.maalscan.io";

export const networkId = () => {
  return isNetworkTestnet ? 7860 : 786;
};

export const networkIdHex = () => {
  return isNetworkTestnet ? "0x1EB4" : "0x312";
};

export const SignMessages = async () => {
  try {
    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    provide = window.ethereum;
    const user = await axios
      .post(`${window.apiURL}/getuser`, {
        wallet: await getAddress(),
      })
      .then((res) => {
        return res.data;
      });
    if (user.length <= 0) {
      const signature = await signer.signMessage(message(signer));
      const address = await signer.getAddress();
      const isVerify = await verifyMessage(message(signer), address, signature);
      if (isVerify) {
        await axios
          .post(`${window.apiURL}/makeuser`, {
            Creator: await getAddress(),
            signature: signature,
          })
          .then((res) => {
            return res.data[0].Creator;
          });
      }
      return isVerify;
    } else {
      const signature = user[0].signature ? user[0].signature : "00";
      const address = await signer.getAddress();
      const isVerify = await verifyMessage(message(signer), address, signature);

      if (isVerify) {
        return true;
      } else {
        const newsignature = await signer.signMessage(message(signer));
        const data = await axios
          .post(`${window.apiURL}/updateuser`, {
            wallet: await getAddress(),
            signature: newsignature,
          })
          .then((res) => {
            return res.data;
          });
        return true;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const verifyMessage = async (message, address, signature) => {
  try {
    const signerAddr = await ethers.utils.verifyMessage(message, signature);
    if (signerAddr === address) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const convertToLowercase = (str) => {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    let charCode = str.charCodeAt(i);
    if (charCode >= 65 && charCode <= 90) {
      result += String.fromCharCode(charCode + 32);
    } else {
      result += str.charAt(i);
    }
  }
  return result;
};

export const getAddress = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    provide = window.ethereum;
    const address = await provider.send("eth_requestAccounts", []);
    const lower = convertToLowercase(address[0]);
    return lower;
  } catch (error) {
    console.log(error);
  }
};

export const getProvider = async () => {
  return new Web3(provide);
};

export const rpcURL = () => {
  return RPC;
};

export const GetChainId = async () => {
  try {
    const web3 = await getProvider();
    const id = await web3.eth.getChainId();
    return id;
  } catch (error) {}
};

export const userBalanceOfChain = async (userAddress) => {
  try {
    const data = await axios
      .post("https://node1.maalscan.io", {
        jsonrpc: "2.0",
        method: "eth_getBalance",
        params: [userAddress, "latest"],
        id: 880,
      })
      .then((res) => {
        const bal = parseInt(res.data.result, 16) / 10 ** 18;
        return Number(bal);
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const utils_towei = async (value) => {
  const pro = await getProvider();
  const num = await pro.utils.toWei(Number(value).toString(), "ether");
  return num;
};

export const utils_fromowei = async (value) => {
  const pro = await getProvider();
  const num = await pro.utils.fromWei(value, "ether");
  return num;
};

export const logo_light = () => {
  //const logo = "https://bafkreideayt5ma23szhrwctijkvrze5isryzcothswyzpixmtdx2l5yogi.ipfs.nftstorage.link" // mainnet
  const logo = isNetworkTestnet
    ? "https://bafkreibzcgqfk262lvpgnu7q7xl6wezf5zg2ysvgnqlamh7tyq3pkrhp4u.ipfs.nftstorage.link"
    : "https://bafkreideayt5ma23szhrwctijkvrze5isryzcothswyzpixmtdx2l5yogi.ipfs.nftstorage.link"; // tesnet
  return logo;
};

export const logo_dark = () => {
  //const logo = "https://bafkreiaqmyyk4gpy7v3unmli7phytdat6xzdtu7aji7mgzieuwlatmosli.ipfs.nftstorage.link" // mainnet
  const logo = isNetworkTestnet
    ? "https://bafkreidmmdkk7znpxuu7s6o6ndjdrivum2vz5kocsqb3zt5pjuvpol2pyi.ipfs.nftstorage.link"
    : "https://bafkreiaqmyyk4gpy7v3unmli7phytdat6xzdtu7aji7mgzieuwlatmosli.ipfs.nftstorage.link"; // tesnet
  return logo;
};

export const faucets = () => {
  //const logo = "https://bafkreiaqmyyk4gpy7v3unmli7phytdat6xzdtu7aji7mgzieuwlatmosli.ipfs.nftstorage.link" // mainnet
  const logo = isNetworkTestnet ? true : false; // tesnet
  return logo;
};
