import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useEffect, useState, useLayoutEffect } from "react";
import Main from "./Components/Pages/Main";
import "./Components/Explore";
import Explore from "./Components/Explore";
import Exploreinsidepage from "./Components/Exploreinsidepage";
import Collectionsstate from "./Components/Collectionsstate";
import ResourcesHelpCenter from "./Components/ResourcesHelpCenter";
import ResourcesNewsletter from "./Components/ResourcesNewsletter";
import Privacy from "./Components/Privacy";
import Profile from "./Components/Profile/Profile";
import MyCollections from "./Components/Mycollections/MyCollections";
import ProfileSetting from "./Components/Profilesettingpage/ProfileSetting";
import Notificationspage from "./Components/Profilesettingpage/Notificationspage";
import Offers from "./Components/Profilesettingpage/Offers";
import Featureditems from "./Components/Profilesettingpage/Featureditems";
import AccountSupport from "./Components/Profilesettingpage/AccountSupport";
import Earnings from "./Components/Profilesettingpage/Earnings";
import Collectioninsidepage from "./Components/Explore/Collectioninsidepage";
import Navbarnft from "./Components/Navbars/Navbarnft";
import Nftform from "./Components/Nftform";
import Page404 from "./Components/Pages/Page404";
import Help from "./Components/Pages/Help";
import {
  getAddress,
  GetChainId,
  networkId,
  networkIdHex,
  SignMessages,
} from "./Web3/web3_api";
import CreateNft from "./Components/Mycollections/CreateNft";
import Create_Drop_Nft from "./Components/Mycollections/Create_Drop_Nft";
import Validator from "./Components/Mycollections/Validator";
import CreateMusicalNft from "./Components/Mycollections/CreateMusicalNft";
import axios from "axios";
import Art from "./Components/Art";
import Drop_nft_all from "./Components/AirDrop/Drop_nft_all";
import Mergecards from "./Components/Assestsmerge/Mergecards";
import Mergevv from "./Components/Assestsmerge/Mergevv";
import Awss from "./Awss";
import Terms_condition from "./Components/Terms_condition";
import Drop from "./Components/AirDrop/Drop";
import Buy from "./Components/Pages/Buy";
import Copy from "./Components/Pages/Copy";
import Fees from "./Components/Pages/Fees";
import Loss from "./Components/Pages/Loss";
import Nft from "./Components/Pages/Nft";
import Resell from "./Components/Pages/Resell";
import Royalty from "./Components/Pages/Royalty";
import Sale from "./Components/Pages/Sale";
import Verify from "./Components/Pages/Verify";
import Admin from "./Components/Admin";
import Admin_tick from "./Components/Admin_tick";
import Collection_Rank from "./Components/Collection_Rank";

// const apiULR = "http://localhost:8081"; // localhost
const apiULR = "https://medina-nft-api.gcex.lt"; //  mainnet
// const apiULR = "https://pansea-testnet-api.onrender.com"; //testnet

window.apiURL = apiULR;
const IPFS_image_baseURL = "https://docy.infura-ipfs.io/ipfs";

function App() {
  const [UserWallet, setUserWallet] = useState();
  const [catogeries, setCategories] = useState([]);

  useLayoutEffect(() => {
    const init = async () => {
      const staticId = networkId();
      const staticIdHex = networkIdHex();
      if (true) {
        const data = await SignMessages();
        if (data) {
          const wallet = await getAddress();
          setUserWallet(wallet);
        } else {
          setUserWallet(undefined);
        }
      }
      const id = await GetChainId();
      if (Number(id) != staticId) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: staticIdHex }],
        });
      }
    };
    init();

    /// API CALLs///
    const api = () => {
      //  fetching  all  categories
      axios
        .get(`${apiULR}/allcats`)
        .then((res) => {
          setCategories(res.data[0].categories);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    api();
  }, [UserWallet]);

  const [mode, setMode] = useState("dark");
  const toggleMode = () => {
    if (mode === "light") {
      setMode("dark");
      document.body.style.backgroundColor = "black";
    } else {
      setMode("light");
      document.body.style.backgroundColor = "white";
    }
  };

  const signIn = async (meg) => {
    await SignMessages();
    const wallet = await getAddress();
    setUserWallet(wallet);
  };

  const logout = async () => {
    window.localStorage.removeItem("signature");
    setUserWallet(undefined);
    window.location.replace("/");
  };

  function convertToLowercase(str) {
    let result = "";
    for (let i = 0; i < str.length; i++) {
      let charCode = str.charCodeAt(i);
      if (charCode >= 65 && charCode <= 90) {
        result += String.fromCharCode(charCode + 32);
      } else {
        result += str.charAt(i);
      }
    }
    return result;
  }

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", function (accounts) {
      try {
        const data = convertToLowercase(accounts[0]);
        setUserWallet(data);
      } catch (error) {
        console.log("error is:", error);
      }
    });
  } else {
    console.log("Ethereum provider not found.");
  }

  // window.ethereum.on("accountsChanged", function (accounts) {
  //   try {
  //     const data = convertToLowercase(accounts[0]);
  //     setUserWallet(data);
  //   } catch (error) {
  //     console.log("error is :", error);
  //   }
  // });

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Fetch the admin value from local storage on component mount
    const adminValue = localStorage.getItem("admin__tijarah");
    setIsAdmin(adminValue === "true");
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            exact
            path="/navbar"
            element={
              <Navbarnft
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/admin-659b6db21cbdfd72bdd23adb"
            element={<Admin />}
          />
          {isAdmin ? (
            <Route
              exact
              path="/admin-64d07beb1731b48d296a7fd0"
              element={
                <Admin_tick
                  signMessage={signIn}
                  UserWallet={UserWallet}
                  logout={logout}
                  URL={apiULR}
                />
              }
            />
          ) : (
            ""
          )}
          <Route exact path="/awss" element={<Awss />} />
          <Route
            exact
            path="/collection-rank-658cf11ebadfb3e8eae8ecae"
            element={
              <Collection_Rank
                URL={apiULR}
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help/buy"
            element={
              <Buy
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help/copyright"
            element={
              <Copy
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help/fees"
            element={
              <Fees
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help/loss"
            element={
              <Loss
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help/nft"
            element={
              <Nft
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help/resell"
            element={
              <Resell
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help/royalty"
            element={
              <Royalty
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help/sale"
            element={
              <Sale
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help/verify"
            element={
              <Verify
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/help"
            element={
              <Help
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
              />
            }
          />
          <Route
            exact
            path="/drop/:name/:id"
            element={
              <Drop
                signMessage={signIn}
                UserWallet={UserWallet}
                logout={logout}
                URL={apiULR}
              />
            }
          />
          <Route
            exact
            path="/"
            element={
              <Main
                UserWallet={UserWallet}
                URL={apiULR}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/explore"
            element={
              <Explore
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/exploreinsidepage"
            element={
              <Exploreinsidepage
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/collectionsstate"
            element={
              <Collectionsstate
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />

          <Route
            path="/resourceshelpcenter"
            element={
              <ResourcesHelpCenter
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/privacy"
            element={
              <Privacy
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/terms"
            element={
              <Terms_condition
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/resourcesnewsletter"
            element={
              <ResourcesNewsletter
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          {UserWallet ? (
            <Route
              path="/profile/:user/:wallet"
              element={
                <Profile
                  mode={mode}
                  toggleMode={toggleMode}
                  UserWallet={UserWallet}
                  URL={apiULR}
                  IPFS_image_baseURL={IPFS_image_baseURL}
                  signMessage={signIn}
                  logout={logout}
                />
              }
            />
          ) : (
            <Route
              path="/profile"
              element={
                <Navigate
                  replace
                  to="/"
                  UserWallet={UserWallet}
                  signMessage={signIn}
                  logout={logout}
                />
              }
            />
          )}
          <Route
            path="/createnft"
            element={
              <CreateNft
                mode={mode}
                toggleMode={toggleMode}
                URL={apiULR}
                UserWallet={UserWallet}
                IPFS_image_baseURL={IPFS_image_baseURL}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/createDropNft"
            element={
              <Create_Drop_Nft
                mode={mode}
                toggleMode={toggleMode}
                URL={apiULR}
                UserWallet={UserWallet}
                IPFS_image_baseURL={IPFS_image_baseURL}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/categories/:cat"
            element={
              <Art
                mode={mode}
                toggleMode={toggleMode}
                URL={apiULR}
                UserWallet={UserWallet}
                IPFS_image_baseURL={IPFS_image_baseURL}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/drop/all"
            element={
              <Drop_nft_all
                mode={mode}
                toggleMode={toggleMode}
                URL={apiULR}
                UserWallet={UserWallet}
                IPFS_image_baseURL={IPFS_image_baseURL}
                signMessage={signIn}
                logout={logout}
              />
            }
          />

          <Route
            path="/categories/:cat/collection/:name/:id"
            element={
              <Collectioninsidepage
                mode={mode}
                toggleMode={toggleMode}
                URL={apiULR}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
                IPFS_image_baseURL={IPFS_image_baseURL}
              />
            }
          />

          <Route
            path="/create_nft/:isvalidator"
            element={
              <CreateNft
                mode={mode}
                toggleMode={toggleMode}
                URL={apiULR}
                UserWallet={UserWallet}
                IPFS_image_baseURL={IPFS_image_baseURL}
                signMessage={signIn}
                logout={logout}
              />
            }
          />

          <Route
            path="/musicalnft"
            element={
              <CreateMusicalNft
                mode={mode}
                toggleMode={toggleMode}
                URL={apiULR}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
                IPFS_image_baseURL={IPFS_image_baseURL}
              />
            }
          />
          <Route
            path="/mycollections"
            element={
              <MyCollections
                mode={mode}
                toggleMode={toggleMode}
                URL={apiULR}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
                catogeries={catogeries}
                IPFS_image_baseURL={IPFS_image_baseURL}
              />
            }
          />
          <Route
            path="/profilesetting"
            element={
              <ProfileSetting
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
                URL={apiULR}
                IPFS_image_baseURL={IPFS_image_baseURL}
              />
            }
          />
          <Route
            path="/notifications"
            element={
              <Notificationspage
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route path="/offers" element={<Offers />} />
          <Route
            path="/featureditems"
            element={
              <Featureditems
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/accountsupport"
            element={
              <AccountSupport
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/earnings"
            element={
              <Earnings
                mode={mode}
                toggleMode={toggleMode}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
              />
            }
          />
          <Route
            path="/collection/:name/:id"
            element={
              <Collectioninsidepage
                mode={mode}
                toggleMode={toggleMode}
                URL={apiULR}
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
                IPFS_image_baseURL={IPFS_image_baseURL}
              />
            }
          />

          <Route
            path="*"
            element={<Page404 mode={mode} toggleMode={toggleMode} />}
          />

          <Route
            path="/assets/:id"
            element={
              <Mergevv
                UserWallet={UserWallet}
                signMessage={signIn}
                logout={logout}
                URL={apiULR}
                IPFS_image_baseURL={IPFS_image_baseURL}
              />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
