import React, { useEffect, useState } from "react";
import "../Mycollections/MyCollections";
import axios from "axios";
import Footer from "../Footer";
import Form from "react-bootstrap/Form";
import { RiTwitterXFill } from "react-icons/ri";
import Typography from "@mui/material/Typography";
import uploadFile from "../../Apkss";
import twitters from "../../Images/twitter.png";
import LanguageIcon from "@mui/icons-material/Language";
import TelegramIcon from "@mui/icons-material/Telegram";
import Micons from "../Mycollections/CollectionsImages/capiM.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { styled } from "@mui/material/styles";
import Card from "react-bootstrap/Card";
import Navbarnft_global from "../Navbars/Navbarnft_global";

import Storyimage1 from "../../Images/storytellingimg1.png";
import Storysmallimg1 from "../../Images/tabimage3.png";
import { TiPencil } from "react-icons/ti";
import { Link, useParams } from "react-router-dom";
import { get_Marketplace_contract } from "./../../Web3/Contract";

import toast, { Toaster } from "react-hot-toast";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { FallingLines } from "react-loader-spinner";
import { RxCross1 } from "react-icons/rx";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { useStoreState } from "easy-peasy";
import { Dropdown, Modal } from "react-bootstrap";
import { AiOutlineMedium } from "react-icons/ai";

const notify = (msg) => toast.success(msg);
const warning = (msg) => toast.error(msg);

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#6A6A6A",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function MyCollections({ UserWallet, URL, IPFS_image_baseURL }) {
  const [open, setOpen1] = useState(false);
  const authorization = useStoreState((state) => state.authorization);
  const ipfs_Store_url = useStoreState((state) => state.ipfs_Store_url);

  const [selectedFile5, setSelectedFile5] = useState();

  const changeHandler5 = (event) => {
    setSelectedFile5(event.target.files[0]);
  };

  const handleSubmission5 = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile5);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setLogoHash_nft(image);
      console.log("Upload response:", image);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmission5_aws = async () => {
    const result = await uploadFile(selectedFile5);
    setLogoHash(result);
    console.log("result", result);
  };

  const [selectedFile2, setSelectedFile2] = useState();
  const changeHandler2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleSubmission2 = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile2);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setFeatureHash_nft(image);
      console.log("Upload response:", image);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmission2_aws = async () => {
    const result = await uploadFile(selectedFile2);
    setFeatureHash(result);
    console.log("result", result);
  };

  const [selectedFile7, setSelectedFile7] = useState();
  const changeHandler7 = (event) => {
    setSelectedFile7(event.target.files[0]);
  };

  const handleSubmission7 = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile7);

      const response = await axios.post(
        "https://api.nft.storage/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${process.env.REACT_APP_NFT_STORAGE_KEY}`,
          },
        }
      );

      const image = `https://${response.data.value.cid}.ipfs.nftstorage.link/${response.data.value.files[0].name}`;
      setBannerHash_nft(image);
      console.log("Upload response:", image);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const handleSubmission7_aws = async () => {
    const result = await uploadFile(selectedFile7);
    setBannerHash(result);
    console.log("result", result);
  };

  const [collectionName, setCollectionName] = useState();
  const [url, setURL] = useState("");
  const [categry, setCategry] = useState("Art");
  const [token, setToken] = useState(
    "0x75498d0c86a449b4b5e877EE366E16C0779f7E66"
  );
  const { id } = useParams();
  const [description, setDescription] = useState(" ");
  const [telegramlink, setTelegramLink] = useState("");
  const [mediumlink, setMediumLink] = useState("");
  const [twitter, settwitter] = useState("");
  const [website, setWebsite] = useState("");
  const [bannerhash, setBannerHash] = useState();
  const [bannerhash_nft, setBannerHash_nft] = useState();
  const [featurehash, setFeatureHash] = useState();
  const [featurehash_nft, setFeatureHash_nft] = useState();
  const [logohash, setLogoHash] = useState();
  const [logohash_nft, setLogoHash_nft] = useState();
  const [fee, setFee] = useState(0);
  const [royaltywallet, setRoyaltyWallet] = useState();

  useEffect(() => {
    const init = async () => {
      axios
        .get(`${URL}/collectionbyid/${id}`)
        .then((res) => {
          setFee(res.data.fee);
          setDescription(res.data.description);
          setCollectionName(res.data.name);
          setWebsite(res.data.website);
          setTelegramLink(res.data.telegram);
          setMediumLink(res.data.medium);
          settwitter(res.data.twitter);
          setRoyaltyWallet(res.data.royalty_address);
        })
        .catch(console.error);
    };
    init();
  }, []);

  const sendCollection = async () => {
    setOpen1(true);
    try {
      const contract = await get_Marketplace_contract();
      if (!bannerhash) {
        warning("Banner image is not uploaded");
        setOpen1(false);
        return;
      }
      if (!featurehash) {
        warning("Feature image is not uploaded");
        setOpen1(false);
        return;
      }
      if (!logohash) {
        warning("Logo image is not uploaded");
        setOpen1(false);
        return;
      }
      if (!collectionName) {
        warning("Invalid collection name");
        setOpen1(false);
        return;
      }
      setOpen1(true);

      axios
        .post(`${URL}/updatecollection/${id}`, {
          fee: fee,
          bannerImage: bannerhash,
          logoImage: logohash,
          featureImage: featurehash,
          bannerImage_nft: bannerhash_nft,
          logoImage_nft: logohash_nft,
          featureImage_nft: featurehash_nft,
          name: collectionName,
          royalty_address: royaltywallet,
          description: description,
          website: website,
          telegram: telegramlink,
          medium: mediumlink,
          twitter: twitter,
        })
        .then((res) => {
          console.log(res);
          notify("Collection Created");
          setOpen1(false);
        })
        .catch((error) => {
          console.log(error);
          setOpen1(false);
        });
    } catch (error) {
      setOpen1(false);
      console.log(error);
    }
  };

  const [show, setShow] = useState(false);

  return (
    <>
      <TiPencil className="f-s-2 m-l-1 " onClick={() => setShow(true)} />
      {/* <Dropdown className="d-i-b ">
        <Dropdown.Toggle className="ma-0 pa-0">
          <MoreHorizIcon className="icons-setting-width11 m-r-1" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="ma-0 pa-0">
          <Dropdown.Item
            type="button"
            // class="btn btn-primary"
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModal"
            // id="create-collection-button"
            className="ma-0 pa-0_5"
            onClick={()=>setShow(true)}
          >
            <span className="pa-0_5 d-i-b m-t-1"> Edit</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
      <Modal show={show} onHide={() => setShow(false)}>
        <div>
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                <div className="">
                  <div>
                    <div className="row mycollections-sc">
                      <div className="col-lg-9 col-md-8 col-sm-12 col-12 p-x-2 ">
                        <div className="f-s-1_5">
                          Edit Collection <div></div>
                        </div>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          <strong style={{ color: "red" }}>*</strong>
                          Required Fields
                        </Typography>

                        <div className="section-collection">
                          <Typography className="highligh-section">
                            Logo image
                            <strong style={{ color: "red" }}>*</strong>
                            {/* {logohash.slice(0, 5)} */}
                          </Typography>
                          <Typography className="content-form f-s-0_8">
                            This image will also be used for navigation. 350 x
                            350 recommended.
                          </Typography>
                          <div className="row">
                            <div className="col-12">
                              <div>
                                <div className="m-q-d-f a-i-c">
                                  {" "}
                                  <div className="featured-img-box2 my-3 m-r-2">
                                    <div className="fe-bo">
                                      <Typography className="tag-image-name-drop">
                                        <input
                                          type="file"
                                          onChange={changeHandler5}
                                        />
                                      </Typography>
                                    </div>
                                  </div>{" "}
                                  {logohash ? (
                                    <div className="highligh-section">
                                      <img
                                        src={`${logohash}`}
                                        className="m-q-w-13 h-7 b-r-20  "
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="section-collection">
                          <div>
                            <Typography className="highligh-section">
                              Featured Image
                              {/* {featurehash} */}
                            </Typography>
                            <Typography className="content-form f-s-0_8">
                              This image will be used for featuring your
                              collection on the homepage, category pages, or
                              other promotional areas of origin. 600 x 400
                              recommended.
                            </Typography>
                          </div>
                          <div>
                            <div className="m-q-d-f a-i-c">
                              <div className="featured-img-box2 my-3 m-r-2">
                                <div className="fe-bo">
                                  {/*       <img src={Dragimg} alt="" /> */}
                                  <Typography className="tag-image-name-drop">
                                    {/*     Drop image here
                        <br />
                        or browse */}

                                    <input
                                      type="file"
                                      onChange={changeHandler2}
                                    />
                                  </Typography>
                                </div>
                              </div>
                              {featurehash ? (
                                <Typography className="highligh-section">
                                  <img
                                    src={`${featurehash}`}
                                    className="m-q-w-13 h-7 b-r-20  "
                                  />
                                </Typography>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="section-collection">
                          <div>
                            <Typography className="highligh-section">
                              Banner Image
                              {/* {bannerhash} */}
                            </Typography>
                            <Typography className="content-form f-s-0_8">
                              This image will appear at the top of your
                              collection page. Avoid including too much text in
                              this banner image, as the dimensions change on
                              different devices. 1400 x 350 recommended.
                            </Typography>
                          </div>
                          <div>
                            <div className="m-q-d-f a-i-c">
                              <div className="featured-img-box2 my-3 m-r-2">
                                <div className="fe-bo">
                                  {/*       <img src={Dragimg} alt="" /> */}
                                  <Typography className="tag-image-name-drop">
                                    {/*     Drop image here
                            <br />
                            or browse */}

                                    <input
                                      type="file"
                                      onChange={changeHandler7}
                                    />
                                  </Typography>
                                </div>
                              </div>
                              {bannerhash ? (
                                <Typography className="highligh-section">
                                  <img
                                    src={`${bannerhash}`}
                                    className="m-q-w-13 h-7 b-r-20  "
                                  />
                                </Typography>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          className="save-bu22 t-a-r m-l-0"
                          onClick={() => {
                            handleSubmission5();
                            handleSubmission2();
                            handleSubmission7();
                            handleSubmission5_aws();
                            handleSubmission2_aws();
                            handleSubmission7_aws();
                          }}
                        >
                          Submit
                        </button>
                        <div className="section-collection">
                          <div>
                            <Typography className="highligh-section">
                              Name <strong style={{ color: "red" }}>*</strong>
                            </Typography>
                            <input
                              placeholder="Example: Treasures of the Sea"
                              className="recordion-in"
                              value={collectionName}
                              onChange={(e) =>
                                setCollectionName(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="section-collection">
                      <div>
                        <Typography className="highligh-section">
                          URL
                        </Typography>
                        <Typography className="content-form f-s-0_8">
                          Customize your URL on origin. Must only
                          contain lowercase letters, numbers, and
                          hyphens.
                        </Typography>
                        <input
                          placeholder="therecordianmusic"
                          value={url}
                          className="recordion-in my-3"
                          onChange={(e) => setURL(e.target.value)}
                        />
                      </div>
                    </div> */}
                        <div className="section-collection">
                          <div>
                            <Typography className="highligh-section">
                              Royalty Fee
                            </Typography>

                            <input
                              placeholder="fee"
                              value={fee}
                              className="recordion-in my-3"
                              onChange={(e) => setFee(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="section-collection">
                          <div>
                            <Typography className="highligh-section">
                              Royalty Receiver Wallet
                            </Typography>

                            <input
                              placeholder="wallet address"
                              value={royaltywallet}
                              className="recordion-in my-3"
                              onChange={(e) => setRoyaltyWallet(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="section-collection">
                          <div>
                            <Typography className="highligh-section">
                              Description
                            </Typography>
                            <Typography className="content-form f-s-0_8">
                              <strong style={{ color: "#FEC200" }}>
                                Markdown
                              </strong>{" "}
                              syntax is supported. 0 of 1000 characters used.
                            </Typography>
                            <textarea
                              className="text-area-mycollections my-4"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                        {/* <div className="section-collection">
                        <div>
                          <Typography className="highligh-section">
                            Add Category
                          </Typography>
                          <Typography className="content-form f-s-0_8">
                            Adding a category will help make your item
                            discoverable on origin.
                          </Typography>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => setCategry(e.target.value)}
                            className="m-t-1"
                          >
                            {catogeries &&
                              catogeries.map((res) => {
                                return <option value={`${res}`}>{res}</option>;
                              })}
                          </Form.Select>
                         
                        </div>
                      </div> */}
                        <div className="section-collection">
                          <div>
                            <Typography className="highligh-section">
                              Links
                            </Typography>
                            <div className="position-relative">
                              <LanguageIcon className="position-absolute icons-input" />{" "}
                              <input
                                className="link-input1 p-l-3"
                                placeholder="yoursite.io"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                              />
                              <br />
                              <AiOutlineMedium className="position-absolute icons-input f-s-1_5 " />
                              <input
                                className="link-input p-l-3"
                                placeholder="https://www.medium.com/@mediumhandle"
                                value={mediumlink}
                                onChange={(e) => setMediumLink(e.target.value)}
                              />
                              <br />
                              <img
                                src={twitters}
                                className="position-absolute icons-input w-3 p-x-0_5 p-t-10px f-s-1_5 "
                              />
                              <input
                                className="link-input7 p-l-3"
                                placeholder="https://twitter.com/Maalchain"
                                value={twitter}
                                onChange={(e) => settwitter(e.target.value)}
                              />
                              <br />
                              <TelegramIcon className="position-absolute icons-input" />{" "}
                              <input
                                className="link-input3 p-l-3"
                                placeholder="https://t.me/"
                                value={telegramlink}
                                onChange={(e) =>
                                  setTelegramLink(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          {/* <div className="section-collection">
                        <Typography className="highligh-section">
                          Blockchain
                        </Typography>
                        <Typography className="content-form mb-3">
                          Select the blockchain where you'd like new
                          items from this collection to be added by
                          default. info
                        </Typography>
                        <div>
                          <Form.Select
                            aria-label="Default select example"
                            className="mt-3"
                            onChange={(e) => setChain(e.target.value)}
                            disabled={true}
                          >
                            <option value="5">Goerli</option>
                            <option value="56">BNB smart chain</option>
                          </Form.Select>
                        </div>
                      </div> */}
                          <div className="section-collection">
                            <div>
                              <Typography className="highligh-section">
                                Payment Tokens
                              </Typography>
                              <Typography className="content-form f-s-0_8">
                                These tokens can be used to buy and sell your
                                items.
                              </Typography>

                              <Form.Select
                                aria-label="Default select example"
                                className="mt-3"
                                onChange={(e) => setToken(e.target.value)}
                              >
                                <option value="0x75498d0c86a449b4b5e877EE366E16C0779f7E66">
                                  GCS
                                </option>
                                <option value="0xC801a04cf093Da9b6670feaCECCAC7235A3Ec8B9">
                                  MAAL
                                </option>
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                        {/* <div className="section-collection">
                        <div className="row">
                          <div className="col-8">
                            <Typography className="highligh-section">
                              Explicit & sensitive content
                            </Typography>
                            <Typography className="content-form f-s-0_8">
                              Set this collections as explicit & sensitive
                              content
                            </Typography>
                          </div>
                          <div className="col-4">
                            <span className="ios-check-button">
                              <FormControlLabel
                                control={
                                  <IOSSwitch sx={{ m: 1 }} defaultChecked />
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </div> */}
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 col-12"></div>
                      <div className="col-lg-7 col-md-8 col-sm-12 m-b-6">
                        <div className="button-section">
                          {/* <button className="save-bu">Save as draft</button> */}
                          <button
                            className="save-bu22"
                            onClick={() => sendCollection()}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster />
    </>
  );
}

export default MyCollections;
