import React from "react";
import { NavLink } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

function Help_detail() {
  return (
    <div>
      <div className="max-w-20">
        <NavLink className="" to="/help">
          {" "}
          <div className=" m-b-1 p-x-1  ">
            <IoArrowBack /> Back
          </div>
        </NavLink>
        <NavLink className="help" to="/help/nft">
          {" "}
          <div className=" m-b-1 p-x-1  ">What is an NFT?</div>
        </NavLink>
        <NavLink className="help" to="/help/buy">
          {" "}
          <div className=" m-b-1 p-x-1   ">How do I buy an NFT?</div>
        </NavLink>
        <NavLink className="help" to="/help/fees">
          {" "}
          <div className=" m-b-1 p-x-1   ">
            What are gas fees and how are they calculated?
          </div>
        </NavLink>
        <NavLink className="help" to="/help/sale">
          {" "}
          <div className=" m-b-1 p-x-1   ">
            How do I create and list an NFT for sale on PANSEA?{" "}
          </div>
        </NavLink>
        <NavLink className="help" to="/help/resell">
          {" "}
          <div className=" m-b-1 p-x-1   ">
            Can I resell an NFT I purchased?
          </div>
        </NavLink>
        <NavLink className="help" to="/help/verify">
          {" "}
          <div className=" m-b-1 p-x-1   ">
            Is there a verification process for creators{" "}
          </div>
        </NavLink>
        <NavLink className="help" to="/help/copyright">
          {" "}
          <div className=" m-b-1 p-x-1   ">
            How does the marketplace handle copyright and intellectual property
            rights?{" "}
          </div>
        </NavLink>
        <NavLink className="help" to="/help/loss">
          {" "}
          <div className=" m-b-1 p-x-1   ">
            What happens if I loss access to my digital wallet?{" "}
          </div>
        </NavLink>
        <NavLink className="help" to="/help/royalty">
          {" "}
          <div className=" m-b-1 p-x-1">
            How do royalties work on NFT sales?
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default Help_detail;
