import React from "react";
import Browse1 from "../Images/D_ART.png";
import Browse2 from "../Images/D_GAMING.png";
import Browse3 from "../Images/P_MEMBERSHIP.png";
import Browse4 from "../Images/P_METAVERSE.png";
import Browse5 from "../Images/D_UTILITY.png";
// import Browse5 from "../Images/originimg5 copy.png";
// import Browse6 from "../Images/originimg6 copy.png";

import Card from "react-bootstrap/Card";
import "./Browse.css";
import { Box } from "@mui/material";
import { Typography } from "antd";

export default function Browse(props) {
  return (
    <div>
      <div className="  browse32" id="browse23">
        <Box>
          <h3 className="f-w-600">Explore Categories</h3>
        </Box>
        <div className="row ">
          <div className=" col-lg col-md-3 col-sm-4 col-12 m-y-1 browse-card">
            <a href="/categories/Art" className="">
              <div className="">
                <div className="">
                  {" "}
                  <img src={Browse1} alt="" className="bt_br  " />
                </div>
                <div className="notable_card p-x-1 ">
                  <div className=" p-y-1_5 f-w-600">Art</div>
                </div>
              </div>
            </a>
          </div>
          <div className=" col-lg col-md-3 col-sm-4 col-12 m-y-1 browse-card">
            <a href="/categories/Gaming" className="">
              <div className="">
                <div className="">
                  {" "}
                  <img src={Browse2} alt="" className="bt_br  " />
                </div>
                <div className="notable_card p-x-1 ">
                  <div className=" p-y-1_5 f-w-600">Gaming</div>
                </div>
              </div>
            </a>
          </div>
          <div className=" col-lg col-md-3 col-sm-4 col-12 m-y-1 browse-card">
            <a href="/categories/Membership" className="">
              <div className="">
                <div className="">
                  {" "}
                  <img src={Browse3} alt="" className="bt_br  " />
                </div>
                <div className="notable_card p-x-1 ">
                  <div className=" p-y-1_5 f-w-600">Membership</div>
                </div>
              </div>
            </a>
          </div>

          <div className=" col-lg col-md-3 col-sm-4 col-12 m-y-1 browse-card">
            <a href="/categories/Metaverse" className="">
              <div className="">
                <div className="">
                  {" "}
                  <img src={Browse4} alt="" className="bt_br  " />
                </div>
                <div className="notable_card p-x-1 ">
                  <div className=" p-y-1_5 f-w-600">Metaverse</div>
                </div>
              </div>
            </a>
          </div>
          <div className=" col-lg col-md-3 col-sm-4 col-12 m-y-1 browse-card">
            <a href="/categories/Utility" className="">
              <div className="">
                <div className="">
                  {" "}
                  <img src={Browse5} alt="" className="bt_br  " />
                </div>
                <div className="notable_card p-x-1 ">
                  <div className=" p-y-1_5 f-w-600">Utility</div>
                </div>
              </div>
            </a>
          </div>
          {/* <div className=" col-lg col-md-3 col-sm-4 col-12 m-y-1 browse-card">
            <Card className="cards5">
              <Card.Img variant="top" src={Browse5} className="card-img-top1" />
              <Card.Body className="card-body1">
                <Card.Title
                  style={{ textAlign: "center" }}
                  className="card-title1 f-s-1"
                >
                  Recordion
                </Card.Title>
              </Card.Body>
            </Card>
          </div> */}
          {/* <div className=" col-lg col-md-3 col-sm-4 col-12 m-y-1 browse-card">
            <Card className="cards6">
              <Card.Img variant="top" src={Browse6} className="card-img-top1" />
              <Card.Body className="card-body1">
                <Card.Title
                  style={{ textAlign: "center" }}
                  className="card-title1 f-s-1"
                >
                  Videos
                </Card.Title>
              </Card.Body>
            </Card>
          </div> */}
        </div>
      </div>
    </div>
  );
}
