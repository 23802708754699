import React from "react";
import Help_sidebar from "./Help_sidebar";
import Navbarnft_global from "../Navbars/Navbarnft_global";

function Buy() {
  return (
    <div>
      <Navbarnft_global />
      <div className="">
        {" "}
        <div className="d-f j-c-s-b g-2 m-t-4">
          {" "}
          <div className="m-q-b-d-n b-r-1">
            <Help_sidebar />
          </div>
          <div className="max-w-50 p-x-2 scrll max-h-80vh o-a">
            <div className="f-s-1_5 f-w-600 m-b-1">
              How does the marketplace handle copyright and intellectual
              property rights?
            </div>
            <div className="m-b-2">
              PANSEA, along with most NFT marketplaces, takes intellectual
              property rights seriously and has specific policies in place to
              handle copyright issues:{" "}
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                1. Respect for Copyright Laws:{" "}
              </span>{" "}
              PANSEA expects all users to adhere to copyright laws. This means
              that creators must own the rights to the artwork or have
              permission from the copyright owner to create and sell NFTs of
              that work.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                2. Verification of Ownership:{" "}
              </span>{" "}
              When a user creates an NFT, they are typically required to assert
              that they have the legal right to sell the digital asset. The
              marketplace might have mechanisms to check the authenticity of
              this claim to a certain extent.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                3. No Tolerance for Infringement:
              </span>{" "}
              If a user is found to be selling NFTs that infringe on someone
              else's copyright, PANSEA may remove these listings and take
              further action against the user's account, which can include
              suspension or banning.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">4. Reporting Mechanisms: </span>{" "}
              PANSEA provides a way for copyright holders to report suspected
              infringements. If someone believes that their work has been copied
              or used without permission, they can file a report with the
              marketplace.
            </div>

            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                5. Legal Responsibility of the Seller:
              </span>{" "}
              It's important to note that the liability for copyright
              infringement ultimately falls on the individual who created and
              listed the NFT, not on the marketplace.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">6. Purchase Rights: </span>{" "}
              Purchasing an NFT typically grants the buyer ownership of the
              specific token on the blockchain, but it does not necessarily
              grant copyright or reproduction rights of the underlying artwork
              unless explicitly stated.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">7. Artist Royalties: </span> Many
              NFT platforms support artist royalties, meaning that the original
              creator can receive a percentage of sales whenever their work is
              resold.
            </div>
            <div className="m-b-2">
              Both creators and buyers on NFT marketplaces must understand the
              implications of copyright and intellectual property rights. While
              marketplaces like PANSEA facilitate the trading of NFTs, they also
              emphasize the importance of respecting the legal rights of
              creators and copyright holders.
            </div>
          </div>
          <div className="">.</div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
