import React from "react";
import Help_sidebar from "./Help_sidebar";
import Navbarnft_global from "../Navbars/Navbarnft_global";

function Buy() {
  return (
    <div>
      <Navbarnft_global />
      <div className="">
        {" "}
        <div className="d-f j-c-s-b g-2 m-t-4">
          {" "}
          <div className="m-q-b-d-n b-r-1">
            <Help_sidebar />
          </div>
          <div className="max-w-50 p-x-2 scrll max-h-80vh o-a">
            <div className="f-s-1_5 f-w-600 m-b-1">
              How do royalties work on NFT sales?
            </div>
            <div className="m-b-2">
              Royalties in the context of NFTs are a way for the original
              creators of the NFT to receive a percentage of the sale price each
              time their NFT is resold on the secondary market. Here’s how they
              typically work:
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">1. Setting Up Royalties:</span>{" "}
              When an artist or creator mints (creates) an NFT, they can specify
              a royalty percentage in the smart contract associated with the
              NFT. This percentage represents how much of future resale prices
              they will receive.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                2. Percentage of Secondary Sales:{" "}
              </span>{" "}
              Royalties are a percentage of the sale price and are automatically
              paid to the creator each time the NFT is resold. Common royalty
              percentages range from 5% to 10%, but this can vary.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">3. Automatic Payment: </span> The
              smart contract of the NFT automates the process of paying out
              royalties. When the NFT is resold, the specified percentage of the
              sale is automatically sent to the creator’s digital wallet.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">4. Marketplace Support: </span>
              For royalties to work, the NFT marketplace where the NFT is being
              resold must support and honor the royalty smart contract. Most
              established marketplaces like PANSEA do support NFT royalties.{" "}
            </div>

            <div className="m-b-2">
              <span className="f-w-600  m-r-1">5. Incentive for Creators:</span>{" "}
              Royalties provide a way for creators to benefit from the
              increasing value of their work over time. It creates an ongoing
              revenue stream, incentivizing artists to continue producing
              high-quality digital art.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                6. No Impact on First Sale:{" "}
              </span>{" "}
              Royalties only apply to secondary sales. The initial sale of the
              NFT from the creator to the first buyer does not include royalty
              payments.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                7. Transparency and Trust:{" "}
              </span>{" "}
              The use of smart contracts for royalty payments adds a level of
              transparency and trust to the process, ensuring creators are
              fairly compensated.{" "}
            </div>

            <div className="m-b-2">
              Royalties are a significant aspect of the NFT ecosystem as they
              ensure that creators can continue to receive compensation for
              their work, even as it gains popularity and increases in value
              over time. This feature is particularly appealing to digital
              artists and other content creators who are looking to monetize
              their creations in the digital realm.
            </div>
          </div>
          <div className="v-h">.</div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
