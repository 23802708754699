import React from "react";
import Help_sidebar from "./Help_sidebar";
import Navbarnft_global from "../Navbars/Navbarnft_global";

function Buy() {
  return (
    <div>
      <Navbarnft_global />
      <div className="">
        {" "}
        <div className="d-f j-c-s-b g-2 m-t-4">
          {" "}
          <div className="m-q-b-d-n b-r-1">
            <Help_sidebar />
          </div>
          <div className="max-w-50 p-x-2 scrll max-h-80vh o-a">
            <div className="f-s-1_5 f-w-600 m-b-1">What is an NFT?</div>
            <div className="m-b-2">
              An NFT, or Non-Fungible Token, is a type of digital asset that
              represents ownership or proof of authenticity of a unique item,
              often a piece of digital art, music, video, or other collectibles.
              Here are some key points about NFTs:
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                1. Uniqueness and Non-Interchangeability:{" "}
              </span>{" "}
              Unlike traditional digital assets or cryptocurrencies like
              Bitcoin, each NFT is unique and cannot be exchanged on a
              one-to-one basis with another NFT. Each NFT contains specific
              identification information that records its unique details.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">2. Blockchain-Based: NFTs</span>{" "}
              are created and stored on a blockchain, which is a decentralized
              digital ledger. This ensures the security and immutability of the
              NFT's ownership records.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">3. Proof of Ownership:</span>{" "}
              Owning an NFT means having the digital rights to the unique item
              it represents. It's similar to owning an original piece of art in
              the physical world.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">4. Digital Rights: </span> While
              the NFT grants ownership of the digital version of an item, it
              doesn't always mean ownership of copyright or intellectual
              property of the item itself, unless explicitly stated.
            </div>

            <div className="m-b-2">
              <span className="f-w-600  m-r-1">
                5. Marketplace and Trading:{" "}
              </span>{" "}
              NFTs can be bought, sold, and traded on digital platforms and
              marketplaces, often using cryptocurrencies.
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">6. Smart Contracts: </span> Many
              NFTs are tied to smart contracts, which are self-executing
              contracts with the terms of the agreement directly written into
              code{" "}
            </div>
            <div className="m-b-2">
              <span className="f-w-600  m-r-1">7. Diverse Applications: </span>{" "}
              Beyond digital art, NFTs are used for a wide range of
              applications, including in-game items, digital collectibles,
              online content, property rights, and more.
            </div>

            <div className="m-b-2">
              NFTs have become popular, enabling artists and creators to
              monetize digital works and collectors to own and trade unique
              digital assets.
            </div>
          </div>
          <div className="v-h">.</div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
