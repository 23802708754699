import { getProvider } from "./web3_api";
import { factory_ABI, factory_Address, nft_Minting_ABI_1156, market_Place_Address, market_Place_Abi, nft_Minting_ABI_721, BUSD_Address, BUSD_Abi, NFT_Validator_Address, NFT_Validator_ABI, AirDropNFT_Address, AirDropNFT_ABI, AirDropNFT_ABIv2 } from "./ABI"
import Web3 from "web3";



export const get_Factory_contract = async () => {
  const provider = await getProvider();
  const contract = new provider.eth.Contract(factory_ABI, factory_Address);
  // console.log(contract)
  return contract;
}

export const get_all_nft_contract_ = async (nft_address) => {
 try {
  const provider = await getProvider();
  const contract = new provider.eth.Contract(nft_Minting_ABI_721, nft_address);
  return contract;
 } catch (error) {
  
 }
}


// for 1156 type nft
export const get_Minting_contract_1155 = async (nft_address) => {
  const provider = await getProvider();
  const contract = new provider.eth.Contract(nft_Minting_ABI_1156, nft_address);
  // console.log(contract)
  return contract;
}


// for 721 type nft
export const get_Minting_contract_721 = async (nft_address) => {
  const provider = await getProvider();
  const contract = new provider.eth.Contract(nft_Minting_ABI_721, nft_address);
  // console.log(contract)
  return contract;
}


export const get_Marketplace_contract = async (nft_address) => {
  try {
    const provider = await getProvider();
    const contract = new provider.eth.Contract(market_Place_Abi, market_Place_Address);
    // console.log(contract)
    return contract;
  } catch (error) {
    console.log(error)
  }
}

export const get_Validator_NFT_contract = async (nft_address) => {
  try {
    const provider = await getProvider();
    const contract = new provider.eth.Contract(NFT_Validator_ABI, NFT_Validator_Address);
    // console.log(contract)
    return contract;
  } catch (error) {
    console.log(error)
  }
}

export const get_Airdrop_NFT_contract = async () => {
  try {
    const provider = await getProvider();
    const contract = new provider.eth.Contract(AirDropNFT_ABI, AirDropNFT_Address);
    return contract;
  } catch (error) {
    console.log(error)
  }
}

export const get_Airdrop_NFT_contract_by_address = async (address) => {
  try {
    const provider = await getProvider();
    const contract = new provider.eth.Contract(AirDropNFT_ABIv2, address);
    return contract;
  } catch (error) {
    console.log(error)
  }
}

export const get_ERC20_contract = async (tokenAdd) => {
  try {
    const provider = await getProvider();
    const contract = new provider.eth.Contract(BUSD_Abi, tokenAdd);
    return contract;
  } catch (error) {
    console.log(error)
  }
}


export const Approve = async (user, tokenAddress) => {
  try {
    const web3 = await getProvider();
    const contract = new web3.eth.Contract(BUSD_Abi, tokenAddress);
    const data = await contract.methods
      .approve(
        market_Place_Address,
        115792089237316195423570985008687907853269984665640564039457584007913129639935n
      )
      .send({ from: user });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const Allow = async (user, tokenAddress) => {
  try {
    const web3 = new Web3(window.ethereum);
    console.log(tokenAddress)
    const contract = new web3.eth.Contract(BUSD_Abi, tokenAddress);
    const data = await contract.methods.allowance(user, market_Place_Address).call();
    console.log("Token allownce",data)
    return data;
  } catch (error) {
    console.log(error);
  }
};