import React, { useLayoutEffect, useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";

import faucet from "../Images/faucet.png";

import { AiFillSetting, AiOutlineSearch } from "react-icons/ai";

import {
  BsCart3,
  BsCollection,
  BsDiscord,
  BsPencil,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { FaWallet } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";

import gold from "../Images/tick_gold.png";
import blue from "../Images/tick_blue.png";
import Navbarnft_global from "./Navbars/Navbarnft_global";
import Admin_Sidebar from "./Admin_Sidebar";

import { Link } from "react-router-dom";

import { MdLogout, MdOutlineDarkMode, MdOutlineExplore } from "react-icons/md";
import { CgProfile } from "react-icons/cg";

import { BiPowerOff, BiWalletAlt } from "react-icons/bi";

import axios from "axios";
import { logo_light, logo_dark, faucets } from "../Web3/web3_api";
import { Dropdown, Form } from "react-bootstrap";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Tooltip } from "antd";

function Admin_tick({ signMessage, UserWallet, logout, URL }) {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const [show2, setShow2] = useState(false);
  const [searchs, setsearchs] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  var navbar = document.querySelector("nav");

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  }, []);

  const [theme, setTheme] = useState("light-theme");
  const toggleTheme = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "dark-theme"
    );
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 5) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  const [logolink, setLogoLink] = useState();
  const [name, setName] = useState();
  const [queryresult, setQueryresult] = useState();

  useEffect(() => {
    const init = async () => {
      axios
        .post(`${URL}/getuser`, {
          wallet: UserWallet,
        })
        .then((res) => {
          setLogoLink(res.data[0].logoImage);

          setName(res.data[0].name);
        });
    };
    init();
  }, [UserWallet]);

  const searchCollecion = async (query) => {
    try {
      axios.get(`${URL}/allcollections/search/?name=${query}`).then((res) => {
        setQueryresult(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (eventId, rank, check) => {
    try {
      console.log("rank", eventId, rank);
      const response = await axios.put(`${URL}/updateRank/${eventId}/${rank}`);
      alert(response.data.message);
      window.location.reload();
    } catch (error) {
      alert("Error updating document Please check the console for details.");
      console.error("Error updating document:", error);
    }
  };
  const [selectrank, setselectrank] = useState("");
  console.log("sel", selectrank);
  // Event handler to update the state when the select value changes
  const handleSelectChange = (event) => {
    const selectedRank = event.target.value;

    // Check if the selected rank already exists in the events array
    const rankExists = events.some((event) => event.rank === selectedRank);

    if (rankExists) {
      alert(
        "Error: Rank already exists in the table. Please choose a different rank."
      );
    } else {
      setselectrank(selectedRank);
    }
  };

  // Event handler to update the state when the select value changes

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Make a GET request to your Node.js server
        const response = await axios.get(`${URL}/rank`);

        // Set the fetched events in the state
        const filteredCollection = response.data.filter(
          (res) => res.rank !== "null" || res.rank == ""
        );

        // Set the filtered events in the state
        setEvents(filteredCollection);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    // Call the fetchEvents function
    fetchEvents();
  }, []);

  console.log("query", queryresult, typeof queryresult);
  return (
    <div>
      <div>
        <Navbarnft_global />
        <div className="">
          {" "}
          <div className="d-g g-t-c-200px   m-t-4">
            {" "}
            <div className="b-r-1">
              <Admin_Sidebar />
            </div>
            <div className=" container-kws scrll max-h-80vh o-a">
              <div className="">
                <div className="m-b-2 w-100">
                  <div className="m-b-1 f-s-1_25 f-w-600"> Select Rank </div>
                  <div className="m-b-2">
                    {" "}
                    <Form.Select
                      aria-label="Default select example"
                      value={selectrank} // Set the value attribute to the state variable
                      onChange={handleSelectChange} // Attach the event handler
                    >
                      <option defaultChecked>Open this select menu</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </Form.Select>
                  </div>
                  <div className="m-b-1 f-s-1_25 f-w-600">
                    {" "}
                    Search Collection Name{" "}
                  </div>
                  <div
                    className="b-1 b-r-10  m-q-b-d-n w-100  pa-0_5"
                    onClick={() => setsearchs(!searchs)}
                  >
                    <div className=" d-f a-i-c  w-100 ">
                      <div className=" ">
                        <AiOutlineSearch className="b-c-t " />
                      </div>
                      <div className="w-100  ">
                        <input
                          type="text"
                          className=" w-100  b-n p-l-1 b-c-t"
                          placeholder={t("search collection name")}
                          onChange={(e) => searchCollecion(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>{" "}
                  {typeof queryresult == "object" && queryresult.length > 0 ? (
                    <div>
                      {queryresult.map((res) => {
                        return (
                          <div className="   w-100 ps-a ">
                            <div className="  p-t-1 c-p">
                              <div
                                className="d-f a-i-c "
                                onClick={() =>
                                  handleUpdate(res._id, selectrank, true)
                                }
                              >
                                <div className="m-r-1 w-3 h-3 ">
                                  <img
                                    src={res.logoImage}
                                    className="d-f a-i-c j-c-c o-f-c w-100 h-100"
                                  />
                                </div>
                                <div className="">
                                  {" "}
                                  <div className="">{res.name}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className=" m-y-5">
                <Paper
                  sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}
                  className="b-c-t b-n"
                >
                  <TableContainer className="b-c-t b-n">
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className="b-c-t b-n"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="f-w-600">Rank</TableCell>
                          <TableCell className="f-w-600">Name</TableCell>

                          <TableCell
                            className="f-w-600"
                            style={{ minWidth: "250" }}
                          >
                            Feature Image
                          </TableCell>

                          <TableCell className="f-w-600">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {events
                          .sort((a, b) => a.rank - b.rank)
                          .map((res, index) => {
                            return (
                              <>
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                  className="a-i-c"
                                >
                                  <TableCell>{res.rank}</TableCell>
                                  <TableCell>
                                    <span className="m-r-1"> {res.name}</span>
                                    {res.tick ? (
                                      res.tick == "gold" ? (
                                        <img src={gold} className="w-1_5" />
                                      ) : res.tick == "blue" ? (
                                        <img src={blue} className="w-1_5" />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>

                                  <TableCell>
                                    <Tooltip
                                      title={
                                        <div className="w-15 b-c-t  b-r-5 o-h">
                                          <img
                                            src={res.featureImage}
                                            className="d-f a-i-c j-c-c o-f-c"
                                            alt="Banner"
                                          />
                                        </div>
                                      }
                                    >
                                      <div>
                                        <img
                                          src={res.featureImage}
                                          className="w-7 h-5  b-r-5 "
                                          alt="Banner"
                                        />
                                      </div>
                                    </Tooltip>
                                  </TableCell>

                                  <TableCell>
                                    <button
                                      className="bg_yellow_clr b-n p-x-2 p-y-0_5 b-r-5 f-w-600"
                                      onClick={() =>
                                        handleUpdate(res._id, "null", false)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </TableBody>
                    </Table>
                    {events.length > 0 ? (
                      ""
                    ) : (
                      <>
                        <div className="p-y-5 t-a-c">No data to show...</div>
                      </>
                    )}
                  </TableContainer>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin_tick;
